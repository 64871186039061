<template>
	<div class="tests">
		<div
			class="image"
			v-for="image in images"
			:key="image.src"
			:class="{ animated: image.visible }"
			ref="images"
		>
			<img :src="require(`@/assets/scanner/content/${image.src}`)" />
		</div>
	</div>
</template>

<style scoped lang="less">
	/* autoprefixer grid: no-autoplace */
	@import "scanner/breakpoints";

	.tests {
		margin-top: 7.5%;
		margin-left: auto;
		margin-right: auto;

		@media @breakpoint-large {
			width: 470px;
		}

		@media @breakpoint-medium {
			width: 375px;
		}

		@media @breakpoint-small {
			width: 155px;
		}


		display: grid;
		grid-template-columns: 8.19fr 1fr 8.19fr;
		grid-template-rows: 11.5fr 1fr 11.5fr;

		.image {
			display: block;
			width: 100%;
			height: 100%;

			transform: scale(0.4);
			opacity: 0;
			transition: transform .2s, opacity .2s;

			&.animated {
				transform: scale(1);
				opacity: 1;
			}

			img {
				display: block;
			}
		}

		.image:nth-child(1) {
			grid-row: ~"1 / 2";
			grid-column: ~"1 / 2";
		}

		.image:nth-child(2) {
			grid-row: ~"1 / 2";
			grid-column: ~"3 / 4";
		}

		.image:nth-child(3) {
			grid-row: ~"3 / 4";
			grid-column: ~"1 / 2";
		}

		.image:nth-child(4) {
			grid-row: ~"3 / 4";
			grid-column: ~"3 / 4";
		}
	}
</style>

<script>
	import sync from "./sync";

	export default {
		props: {
			stage: Object,
			gameResult: String
		},
		mixins: [sync],
		data(){
			return {
				images: this.stage.images
					.filter(img => img.results.includes(this.gameResult))
					.map(img => ({ ...img, visible: false }))
			}
		},
		computed: {
			
		},
		methods: {
			getTriggeredMessages(beamYPosition){
				const images = this.$refs.images;
				if(!images){
					return [];
				}
				const bbxs = images.map(img => img.getBoundingClientRect());
				if(bbxs.every(bbx => bbx.top === 0)){
					return [];
				}

				const self = this;
				return bbxs.reduce(function(acc, bbx, i){
					if(bbx.top - 0.3 * bbx.height <= beamYPosition){
						self.images[i].visible = true;
						const messages = self.messages
							.filter(msg => msg.trigger && msg.trigger.row === Math.floor(i / 2) && i % 2 === 0);
						acc = [...acc, ...messages];
					}
					return acc;
				}, []);
			}
		}
	};
</script>