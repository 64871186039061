<template>
	<div class="stage-result">
		<div class="score">
			<img :src="starImage" />
			<h1 class="title">Foo</h1>
		</div>
		<assistant message="Bar" />
		<div class="buttons">

			<button class="button" v-if="resetVisible" @click="resetGame">Baz</button>
			<button class="button" @click="$emit('next')">Quux</button>
		</div>
		<orientation-plug />
		<navigation-controller />

		<div class="media-detector">
			If I were a lil' Element, my size would be
			<span class="small">small (<span class="xs">xs</span><span class="s">s</span>)</span>
			<span class="medium">medium (<span class="m">m</span>)</span>
			<span class="large">large (<span class="l">l</span><span class="xl">xl</span><span class="xxl">xxl</span>)</span>
		</div>
	</div>
</template>

<style scoped lang="less">
@import (reference) "buttons";

	@breakpoint-large: (min-width: 1440px);
	@breakpoint-xxl: (min-width: 1920px);
	@breakpoint-xl: (max-width: 1919px) and (min-width: 1640px);
	@breakpoint-l: (max-width: 1639px) and (min-width: 1440px);

	@breakpoint-medium: (max-width: 1439px) and (min-width: 1024px);
	@breakpoint-m: @breakpoint-medium;

	@breakpoint-small: (max-width: 1023px);
	@breakpoint-s: (max-width: 1023px) and (min-width: 721px);
	@breakpoint-xs: (max-width: 720px);

.media-detector {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	font-size: 200%;
	color: #fff;

	span {
		display: none;
	}

	@media @breakpoint-small {
		.small {
			display: inline;
		}
	}
	@media @breakpoint-medium {
		.medium {
			display: inline;
		}
	}
	@media @breakpoint-large {
		.large {
			display: inline;
		}
	}
	@media @breakpoint-xs {
		.xs {
			display: inline;
		}
	}
	@media @breakpoint-s {
		.s {
			display: inline;
		}
	}
	@media @breakpoint-m {
		.m {
			display: inline;
		}
	}
	@media @breakpoint-l {
		.l {
			display: inline;
		}
	}
	@media @breakpoint-xl {
		.xl {
			display: inline;
		}
	}
	@media @breakpoint-xxl {
		.xxl {
			display: inline;
		}
	}
}

@breakpoint-lrg: (min-width: 1000px);
@breakpoint-sml: (max-width: 999px);

.stage-result {
	height: 100%;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-position: top;

	padding-bottom: 10px;

	@media @breakpoint-lrg {
		background-size: 2563px 100%;
		background-image: url("~@/assets/stage-result/bg-large.svg");
	}

	@media @breakpoint-sml {
		background-size: 999px 100%;
		background-image: url("~@/assets/stage-result/bg-small.svg");
	}
}

.score {
	position: relative;

	flex-grow: 1;
	min-height: 0;

	@media @breakpoint-lrg {
		max-height: 430px;
	}

	@media @breakpoint-sml {
		max-height: 133px;
	}

	img {
		display: block;
		height: 100%;
		margin: 0 auto;
	}
}

.title {
	color: #fff;
	text-align: center;
	font-weight: 900;
	margin: 0;
	position: absolute;
	width: 100%;
	letter-spacing: -0.015em;

	@media @breakpoint-lrg {
		font-size: 62px;
		line-height: 77px;
		bottom: 20px;
	}

	@media @breakpoint-sml {
		font-size: 34px;
		line-height: 100%;
		bottom: 5px;
	}
}

.assistant {
	width: 535px;
	margin-top: 10px;
	margin-bottom: 10px;

	/*@media @breakpoint-l {
			margin-top: 48px;
		}

		@media @breakpoint-s {
			margin-top: 30px;
		}*/

	/deep/ .assistant-name {
		display: none;
	}

	/deep/ .assistant-avatar {
		display: block;
		margin-top: -14px;

		@media @breakpoint-lrg {
			width: 88px;
			height: 88px;
		}

		@media @breakpoint-sml {
			width: 80px;
			height: 80px;
		}
	}

	/deep/ .assistant-message {
		@media @breakpoint-lrg {
			font-size: 20px;
			padding: 20px 30px;
		}

		@media @breakpoint-sml {
			font-size: 16px;
			padding: 15px 20px;
		}
	}
}

.buttons {
	width: 535px;
	display: flex;

	/*@media @breakpoint-l {
			margin-top: 61px;
		}

		@media @breakpoint-s {
			margin-top: 4px;
		}*/
}

.button {
	position: relative;
	flex-grow: 1;

	@media @breakpoint-lrg {
		.button-large();
	}

	@media @breakpoint-sml {
		.button-small();
	}

	&:first-child:last-child:before {
		content: "";
		position: absolute;

		background-image: url("~@/assets/stage-result/button-glow.png");
		background-size: 100% 100%;
		width: 708px;
		height: 58px;

		@media @breakpoint-lrg {
			right: -32px;
			bottom: -33px;
		}

		@media @breakpoint-sml {
			right: -22px;
			bottom: -22px;
		}
	}

	& + & {
		margin-left: 20px;
	}
}
</style>

<script>
	import Assistant from "@/components/partials/assistant";
	import OrientationPlug from "@/components/orientation-plug.vue";
	import NavigationController from "@/components/partials/navigation-controller";

	export default {
		components: {
			Assistant,
			OrientationPlug,
			NavigationController
		},
		computed: {
			stars(){
				return this.$store.state.recentStars || 3;
			},
			resultText(){
				return this.text[this.stars - 1];
			},
			starImage(){
				switch(this.stars){
					case 3: return require('@/assets/stage-result/three-stars.svg');
					case 2: return require('@/assets/stage-result/two-stars.svg');
					case 1: return require('@/assets/stage-result/one-star.svg');
					default: return require('@/assets/stage-result/three-stars.svg');
				}
			},
			resetVisible(){
				return this.stars < 3;
			}
		},
		methods: {
			resetGame(){
				this.$store.dispatch('gameReset');
				this.$emit('reset');
			}
		}
	}
</script>
