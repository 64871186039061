<template>
	<div class="content">
		<div class="column-left">
			<div class="window">
				<transition-group tag="div" name="messages" class="log">
					<p
						class="line"
						v-for="msg in messages"
						:key="msg.id"
						:class="[msg.style]"
					>
						{{ msg.text }}
					</p>
				</transition-group>
			</div>
			<div class="chain">
				<div v-for="(n, i) in stageNumbers" :key="n">
					<div class="chain-item">
						<div
							class="node"
							:class="{
								active: n <= stage.number,
							}"
						>
							<span class="node-text">{{ n }}</span>
						</div>
						<div v-if="i < stageNumbers.length - 1" class="arrow-pin"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="column-right">
			<transition name="component" mode="out-in">
				<component
					:is="currentComponent"
					:stage="stage"
					:gameResult="gameResult"
					:sync="{ time, beamYPosition }"
					:key="currentStage"
					@message="onMessage"
				/>
			</transition>
			<div class="beam-container">
				<img
					class="beam"
					ref="beam"
					src="@/assets/scanner/beam.png"
					:style="animationRunning ? currentBeamStyle : {}"
					@transitionend="beamSequenceStage += 1"
				/>
			</div>
		</div>
		<orientation-plug />
		<navigation-controller />
	</div>
</template>


<style scoped lang="less">
@import "scanner/breakpoints";

.content {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	overflow: hidden;
	width: 100%;
	height: 100%;
	font-family: Gilroy;
	cursor: url("~@/assets/cursors/cursor-normal.png") 14 18, pointer;
	background-repeat: no-repeat;
	background-position: top;
	background-size: 100% 100%;

	@media @breakpoint-xxl {
		background-image: url("~@/assets/scanner/bg-large.png");
	}
	@media @breakpoint-xl {
		background-image: url("~@/assets/scanner/bg-large.png");
	}
	@media @breakpoint-l {
		background-image: url("~@/assets/scanner/bg-large.png");
	}
	@media @breakpoint-m {
		background-image: url("~@/assets/scanner/bg-medium.png");
	}
	@media @breakpoint-s, @breakpoint-xs {
		background-image: url("~@/assets/scanner/bg-small.png");
	}
	@media @breakpoint-mob {
		background-image: url("~@/assets/scanner/bg-small.png");
		height: 100vh;
	}

	.column-left {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.window {
			flex-shrink: 0;

			background-image: url("~@/assets/scanner/frame-bg.png");
			background-size: 100% 100%;

			padding: 10.6% 11.5% 10.2% 9.7%;

			@media @breakpoint-xxl, @breakpoint-xl {
				width: 884px;
				height: 847px;
			}

			@media @breakpoint-l {
				width: 745px;
				height: 714px;
			}

			@media @breakpoint-m {
				width: 517px;
				height: 496px;
			}

			@media @breakpoint-s, @breakpoint-xs {
				width: 276px;
				height: 264px;
			}

			@media (max-height: 277px) {
				margin-top: -12px;
			}

			.log {
				height: 100%;

				display: flex;
				flex-direction: column-reverse;
				//justify-content: flex-end;

				overflow-y: auto;
				direction: rtl;

				// required to prevent shadows from being clipped
				margin-right: -20px;
				padding-right: 20px;
				padding-left: 1.7%;
				padding-bottom: 1%;

				scrollbar-width: thin;
				scrollbar-color: rgba(255, 255, 255, 0.3) transparent;

				&::-webkit-scrollbar {
					width: 1.7%;
				}

				&::-webkit-scrollbar-track {
					background: transparent;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 5px;
					background: rgba(255, 255, 255, 0.3);
					box-shadow: 0px 7px 51px rgba(59, 222, 154, 0.27);
					backdrop-filter: blur(15px);
				}

				&::-webkit-scrollbar-button {
					display: none;
				}

				.line {
					flex-shrink: 0;
					
					padding: 3% 13.8% 3% 4.5%;
					margin: 0;
					border-width: 1px;
					border-radius: 5px;
					border-style: solid;

					color: #fff;
					direction: ltr;
					line-height: 170%;

					background-repeat: no-repeat;
					background-size: 100% 100%, 4.8% auto;
					background-position: top left, center right 4.5%;

					@media @breakpoint-xxl, @breakpoint-xl {
						box-shadow: 0px 7px 51px rgba(59, 222, 154, 0.27);
						backdrop-filter: blur(15px);
						font-size: 20px;
					}

					@media @breakpoint-l {
						box-shadow: 0px 5.90083px 42.9917px rgba(59, 222, 154, 0.27);
						backdrop-filter: blur(12.6446px);
						font-size: 20px;
					}

					@media @breakpoint-m {
						box-shadow: 0px 4.0954px 29.8379px rgba(59, 222, 154, 0.27);
						backdrop-filter: blur(8.77585px);
						font-size: 16px;
					}

					@media @breakpoint-s, @breakpoint-xs {
						box-shadow: 0px 2.18552px 15.9231px rgba(59, 222, 154, 0.27);
						backdrop-filter: blur(4.68326px);
						font-size: 12px;
					}

					&.success {
						background-color: rgba(59, 222, 154, 0.33);
						background-image: linear-gradient(
								31.92deg,
								rgba(255, 255, 255, 0.0126) 25.95%,
								rgba(255, 255, 255, 0) 25.95%,
								rgba(255, 255, 255, 0) 50.15%
							),
							url("~@/assets/scanner/success.svg");
					}

					&.warning {
						background-color: rgba(255, 231, 19, 0.2);
						background-image: linear-gradient(
								31.92deg,
								rgba(255, 255, 255, 0.0126) 25.95%,
								rgba(255, 255, 255, 0) 25.95%,
								rgba(255, 255, 255, 0) 50.15%
							),
							url("~@/assets/scanner/warning.svg");
					}

					&.error {
						background-color: rgba(208, 55, 81, 0.52);
						background-image: linear-gradient(
								31.92deg,
								rgba(255, 255, 255, 0.0126) 25.95%,
								rgba(255, 255, 255, 0) 25.95%,
								rgba(255, 255, 255, 0) 50.15%
							),
							url("~@/assets/scanner/error.svg");
					}

					&.messages-enter-active,
					&.messages-leave-active {
						transition: transform 0.2s, opacity 0.2s;
					}

					&.messages-enter,
					&.messages-leave-to {
						opacity: 0;
						transform: scale(0.4);
					}
				}

				.line + .line {
					margin-bottom: 1.5%;
				}
			}
		}

		.chain {
			display: flex;
			justify-content: center;
			width: 100%;
			margin-top: -1.5%;

			.chain-item {
				display: flex;
				align-items: center;
			}
		}

		.node {
			z-index: 1;
			flex-shrink: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			border-style: solid;
			border: 1px solid #3bde9a;

			mix-blend-mode: normal;
			box-sizing: border-box;
			box-shadow: 0px 2px 8px rgba(59, 222, 154, 0.4);
			backdrop-filter: blur(10px);

			background: linear-gradient(
					0deg,
					rgba(59, 222, 154, 0.22),
					rgba(59, 222, 154, 0.22)
				),
				rgba(255, 255, 255, 0.02);

			@media @breakpoint-xxl {
				height: 47px;
				width: 72px;
				border-radius: 1000px;
				padding: 10px, 30px, 10px, 30px;
			}
			@media @breakpoint-xl {
				height: 47px;
				width: 70px;
				border-radius: 1000px;
				padding: 10px, 30px, 10px, 30px;
			}
			@media @breakpoint-l {
				height: 47px;
				width: 67px;
				border-radius: 1000px;
				padding: 10px, 30px, 10px, 30px;
			}
			@media @breakpoint-m {
				width: 51px;
				height: 36px;
				border-radius: 748.047px;
				padding: 7px 15px;
			}
			@media @breakpoint-s, @breakpoint-xs {
				width: 28.5px;
				height: 21px;
				border-radius: 417.969px;
				padding: 3px 6px;
			}

			&.active {
				background: linear-gradient(0deg, #3bde9a, #3bde9a),
					rgba(255, 255, 255, 0.02);
			}

			.node-text {
				color: #ffffff;
				letter-spacing: 0.04em;
				line-height: 170%;
				@media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
					font-size: 16px;
				}
				@media @breakpoint-m {
					font-size: 13px;
				}
				@media @breakpoint-s, @breakpoint-xs {
					font-size: 9px;
				}
			}
		}

		.arrow-pin {
			z-index: 2;
			@media @breakpoint-xxl, @breakpoint-xl {
				width: 184px;
				height: 24px;
				margin: 0 -8px;
				background: url("~@/assets/scanner/arrow-pin-large.png");
			}
			@media @breakpoint-l {
				width: 144px;
				height: 23px;
				margin: 0 -8px;
				background: url("~@/assets/scanner/arrow-pin-medium.png");
			}
			@media @breakpoint-m {
				width: 119px;
				height: 17px;
				margin: 0 -12px;
				background: url("~@/assets/scanner/arrow-pin-small.png");
			}
			@media @breakpoint-s, @breakpoint-xs {
				width: 61px;
				height: 11px;
				margin: 0 -4px;
				background: url("~@/assets/scanner/arrow-pin-xsmall.png");
			}
		}
	}

	.column-right {
		flex-shrink: 0;

		/*display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;*/
		position: relative;

		@media @breakpoint-xxl, @breakpoint-xl {
			width: 769px;
		}
		@media @breakpoint-l {
			width: 680px;
		}
		@media @breakpoint-m {
			width: 517px;
		}
		@media @breakpoint-s, @breakpoint-xs {
			width: 255px;
		}

		.beam-container {
			position: absolute;
			top: 0;
			left: 52.5%;
			width: 120%;
			transform: translateX(-50%);

			.beam {
				pointer-events: none;
				width: 100%;
				transition-property: transform;
				transition-timing-function: linear;
			}
		}

		.component-enter-active,
		.component-leave-active {
			transition: transform 0.2s, opacity 0.2s;
		}

		.component-enter,
		.components-leave-to {
			transform: scale(0.4);
			opacity: 0;
		}
	}
}

@keyframes up-and-down {
	0% {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(385%);
	}
	100% {
		transform: translateY(0%);
	}
}
</style>


<script>
import { uniq } from "lodash";

import OrientationPlug from "@/components/orientation-plug.vue";
import NavigationController from "@/components/partials/navigation-controller";

import StageBlocks from "./scanner/stage-blocks";
import StagePage from "./scanner/stage-page";
import StageTests from "./scanner/stage-tests";

export default {
	props: {
		stages: Array,
	},
	data() {
		return {
			//currentStage: 0,
			beamSequenceStage: 0,
			animationRunning: false,
			messages: [],
			time: -0.1,
			beamYPosition: 0,
		};
	},
	components: { OrientationPlug, NavigationController },
	computed: {
		gameResult() {
			return this.$store.getters.gameResult;
		},
		currentStage() {
			return Math.floor(this.beamSequenceStage / 2);
		},
		stage() {
			return this.stages[this.currentStage] || {};
		},
		stageNumbers() {
			return uniq(this.stages.map((stage) => stage.number));
		},
		currentComponent() {
			switch (this.stage.type) {
				case "page":
					return StagePage;
				case "blocks":
					return StageBlocks;
				case "tests":
					return StageTests;
				default:
					return null;
			}
		},
		beamSequence() {
			return this.stages.reduce(function (acc, stage) {
				acc.push({
					"transition-duration": `${stage.duration}s`,
					transform: "translateY(385%)",
				});
				acc.push({
					"transition-duration": "2s",
					transform: "translateY(0%)",
				});
				return acc;
			}, []);
		},
		currentBeamStyle() {
			return this.beamSequence[this.beamSequenceStage];
		},
	},
	methods: {
		getObjectStyle(obj) {
			const baseWidth = 910;
			const baseHeight = 1025;
			const padLeft = 118;
			const padTop = 114;

			const timeFraction =
				(this.time - this.animationTime) / this.animationDuration;
			const pathFraction = (obj.y + padTop) / (baseHeight * 2);
			const shouldShow =
				obj.gameResults.includes(this.gameResult) &&
				obj.stages.includes(this.stageIndex) &&
				(obj.stages.includes(this.stageIndex - 1)
					? true
					: timeFraction >= pathFraction);
			return {
				width: `${(obj.width / baseWidth) * 100}%`,
				top: `${((obj.y + padTop) / baseHeight) * 100}%`,
				left: `${((obj.x + padLeft) / baseWidth) * 100}%`,
				opacity: shouldShow ? 1 : 0,
				transform: `scale(${shouldShow ? 100 : 0}%)`,
			};
		},
		onMessage(msg) {
			msg.id = this.messages.length;
			this.messages.unshift(msg);
		},
	},
	mounted() {
		setTimeout(() => (this.animationRunning = true), 100);
		this._timer = setInterval(() => (this.time += 0.1), 100);
	},
	beforeDestroy() {
		clearInterval(this._timer);
	},
	watch: {
		currentStage(newVal) {
			if (!this.stages[newVal]) {
				clearInterval(this._timer);
				this.$emit("next");
			}
		},
		time() {
			const el = this.$refs.beam;
			if (!el) {
				return;
			}
			const bbx = el.getBoundingClientRect();
			this.beamYPosition = bbx.top + bbx.height * 0.086;
		},
	},
};
</script>