<template>
	<div class="tablet" :style="tabletStyle">
		<img :src="require('@/assets/cutscene/content/' + src)" />
		<button class="button" @click="$parent.$emit('next')">{{ text }}</button>
	</div>
</template>

<style scoped lang="less">
	@breakpoint-l: (min-width: 1400px);
	@breakpoint-m: (max-width: 1399px) and (min-width: 1000px);
	@breakpoint-s: (max-width: 999px) and (min-width: 721px);
	@breakpoint-xs: (max-width: 720px);

	.tablet {
		flex-shrink: 0;
		
		position: relative;

		transition: transform .1s linear, opacity .1s linear;

		@media @breakpoint-l {
			width: 411px;
		}

		@media @breakpoint-m {
			width: 32.1%;
		}

		@media @breakpoint-s {
			width: 316px;
		}

		@media @breakpoint-xs {
			width: 43.9%;
		}

		img {
			width: 100%;
		}

		.button {
			position: absolute;

			width: 78%;
			left: 50%;
			bottom: 35%;
			transform: translateX(-50%);
			padding: 6.7% 9%;
			font-weight: 600;

			@media @breakpoint-l {
				font-size: 24px;
			}

			@media @breakpoint-m {
				font-size: 1.7vw;
			}

			@media @breakpoint-s {
				font-size: 18px;
			}

			@media @breakpoint-xs {
				font-size: 2.5vw;
			}
		}
	}
</style>

<script>
	export default {
		props: {
			src: String,
			text: String,
			offset: { type: Object, default: () => ({}) },
			isMobile: Boolean,
			scrollPosition: Number,
			duration: { type: Number, default: 0 },
			distance: { type: Number, default: 0 },
			delay: { type: Number, default: 0 }
		},
		data: () => ({
			fraction: 0
		}),
		computed: {
			baseWidth(){
				return this.isMobile ? 680 : 1280;
			},
			tabletStyle(){ 
				return {
					marginLeft: this.offset.x && `${-this.offset.x / this.baseWidth * 100}%`,
					marginTop: this.offset.y && `${-this.offset.y / this.baseWidth * 100}%`,
					marginRight: this.offset.r && `${-this.offset.r / this.baseWidth * 100}%`,
					marginBottom: this.offset.b && `${-this.offset.b / this.baseWidth * 100}%`,
					zIndex: this.offset.z,
					animationDuration: this.duration,
					opacity: this.fraction,
					transform: `scale(${this.fraction})`
				};
			}
		},
		watch: {
			scrollPosition(){
				const parentBB = this.$el.parentElement.getBoundingClientRect();

				const scale = parentBB.width / this.baseWidth;

				let fraction = (window.innerHeight - this.delay * scale - parentBB.top) / (this.distance * scale);

				if(fraction < 0){
					this.fraction = 0;
				}
				if(fraction >=0 && fraction <= 1){
					this.fraction = fraction;
				}
				if(fraction > 1){
					this.fraction = 1;
				}
			}
		}
	}
</script>