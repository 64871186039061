<template>
	<component
		:is="currentComponent"
		v-bind="currentStep"
		@next="next"
		@prev="previous"
		@reset="reset"
	/>
</template>

<script>
	import navigation from "@/mixins/navigation";

	import StageResult from "@/components/stage-result";
	import StageIntro from "@/components/stage-intro";
	import StageLoader from "@/components/stage-loader";
	import Game from "./game";

	export default {
		mixins: [navigation],
		props: {
			source: String
		},
		computed: {
			currentComponent(){
				switch(this.currentStep.type){
					case 'result': return StageResult;
					case 'intro': return StageIntro;
					case 'loader': return StageLoader;
					case 'game': return Game;
					default: throw new Error('Screen type not found');
				}
			}
		},
		methods: {
			reset(){
				this.currentIndex = 0;
			}
		}
	}
</script>