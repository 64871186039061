<template>
  <div class="stage-intro">
    <h3 class="subtitle">{{ subtitle }}</h3>
    <h1 class="title">{{ title }}</h1>
    <assistant :message="text" />
    <div class="buttons">
      <button class="button" @click="$emit('next')">Ага, понятно!</button>
    </div>
    <orientation-plug />
    <navigation-controller />
  </div>
</template>

<style scoped lang="less">
@import (reference) "buttons";

@breakpoint-l: (min-width: 1000px);
@breakpoint-s: (max-width: 999px);
@breakpoint-xs: (max-height: 431px) and (min-height: 270px);
@breakpoint-mob: (max-height: 370px) and (min-height: 270px);

.stage-intro {
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-position: top;

  @media @breakpoint-l {
    background-size: 2563px 100%;
    background-image: url("~@/assets/stage-intro/bg-large.svg");
  }

  @media @breakpoint-s {
    background-size: 999px 100%;
    background-image: url("~@/assets/stage-intro/bg-small.svg");
  }

  @media @breakpoint-mob {
    height: 100vh;
  }
}

.subtitle {
  color: #ffe713;
  line-height: 120%;
  margin-bottom: 8px;

  @media @breakpoint-l {
    font-weight: 900;
    font-size: 36px;
    //margin-top: 262px;
  }

  @media @breakpoint-s {
    font-weight: 800;
    font-size: 22px;
    //margin-top: 24px;
  }
}

.title {
  color: #fff;
  text-align: center;
  font-weight: 900;
  margin: 0;
  width: 100%;
  letter-spacing: -0.015em;

  @media @breakpoint-l {
    font-size: 62px;
    line-height: 77px;
  }

  @media @breakpoint-s {
    font-size: 34px;
    line-height: 100%;
  }
}

.assistant {
  width: 535px;

  @media @breakpoint-l {
    margin-top: 40px;
  }

  @media @breakpoint-s {
    margin-top: 24px;
  }

  /deep/ .assistant-name {
    display: none;
  }

  /deep/ .assistant-avatar {
    display: block;
    margin-top: -14px;

    @media @breakpoint-l {
      width: 88px;
      height: 88px;
    }

    @media @breakpoint-s {
      width: 80px;
      height: 80px;
    }

    @media @breakpoint-xs {
      display: none;
    }
  }

  /deep/ .assistant-message {
    @media @breakpoint-l {
      font-size: 20px;
      padding: 20px 30px;
    }

    @media @breakpoint-s {
      font-size: 16px;
      padding: 15px 20px;
    }
    @media @breakpoint-xs {
      font-size: 16px;
      line-height: 21px;
      padding: 10px 20px;
    }
  }
}

.buttons {
  width: 535px;
  display: flex;

  @media @breakpoint-l {
    margin-top: 61px;
  }

  @media @breakpoint-s {
    margin-top: 4px;
  }

  @media @breakpoint-xs {
    margin-top: 12.5px;
  }
}

.button {
  position: relative;
  flex-grow: 1;

  @media @breakpoint-l {
    .button-large();
  }

  @media @breakpoint-s {
    .button-small();
  }

  &:first-child:last-child:before {
    content: "";
    position: absolute;

    background-image: url("~@/assets/stage-intro/button-glow.png");
    background-size: 100% 100%;
    width: 708px;
    height: 58px;

    @media @breakpoint-l {
      right: -32px;
      bottom: -33px;
    }

    @media @breakpoint-s {
      right: -22px;
      bottom: -22px;
    }
  }

  & + & {
    margin-left: 20px;
  }
}
</style>

<script>
import Assistant from "@/components/partials/assistant";
import OrientationPlug from "@/components/orientation-plug.vue";
import NavigationController from "@/components/partials/navigation-controller";

export default {
  props: {
    text: String,
    title: String,
    subtitle: String,
  },
  components: {
    Assistant,
    OrientationPlug,
    NavigationController,
  },
};
</script>
