<template>
  <div class="stage-result">
    <div class="score">
      <img :src="starImage" />
      <h1 class="title">{{ title }}</h1>
    </div>
    <assistant :message="resultText" />
    <div class="buttons">
      <button class="button" v-if="resetVisible" @click="resetGame">
        {{ resetBtn.text }}
      </button>
      <button class="button" @click="$emit('next')">{{ nextBtn.text }}</button>
    </div>
    <orientation-plug />
    <navigation-controller />
  </div>
</template>

<style scoped lang="less">
@import (reference) "buttons";

@breakpoint-l: (min-width: 1000px);
@breakpoint-s: (max-width: 999px);
@breakpoint-xs: (max-height: 431px) and (min-height: 270px);
@breakpoint-mob: (max-height: 370px) and (min-height: 270px);

.stage-result {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-position: top;

  padding-bottom: 10px;

  @media @breakpoint-l {
    background-size: 2563px 100%;
    background-image: url("~@/assets/stage-result/bg-large.svg");
  }

  @media @breakpoint-s {
    background-size: 999px 100%;
    background-image: url("~@/assets/stage-result/bg-small.svg");
  }

  @media @breakpoint-mob {
    height: 100vh;
  }
}

.score {
  position: relative;
  width: 100%;

  flex-grow: 1;
  min-height: 0;

  @media @breakpoint-l {
    max-height: 430px;
  }

  @media @breakpoint-s {
    max-height: 133px;
  }

  img {
    display: block;
    height: 100%;
    @media @breakpoint-l {
      max-height: 430px;
    }

    @media @breakpoint-s {
      max-height: 133px;
    }
    margin: 0 auto;
  }
}

.title {
  color: #fff;
  text-align: center;
  font-weight: 900;
  margin: 0;
  position: absolute;
  width: 100%;
  letter-spacing: -0.015em;

  @media @breakpoint-l {
    font-size: 62px;
    line-height: 77px;
    bottom: 20px;
  }

  @media @breakpoint-s {
    font-size: 34px;
    line-height: 100%;
    bottom: 5px;
  }
}

.assistant {
  width: 535px;
  margin-top: 10px;
  margin-bottom: 10px;

  flex-shrink: 0;

  /*@media @breakpoint-l {
			margin-top: 48px;
		}

		@media @breakpoint-s {
			margin-top: 30px;
		}*/

  /deep/ .assistant-name {
    display: none;
  }

  /deep/ .assistant-avatar {
    display: block;
    margin-top: -14px;

    @media @breakpoint-l {
      width: 88px;
      height: 88px;
    }

    @media @breakpoint-s {
      width: 80px;
      height: 80px;
    }

    @media @breakpoint-xs {
      display: none;
    }
  }

  /deep/ .assistant-message {
    @media @breakpoint-l {
      font-size: 20px;
      padding: 20px 30px;
    }

    @media @breakpoint-s {
      font-size: 16px;
      padding: 15px 20px;
    }

    @media @breakpoint-xs {
      font-size: 16px;
      line-height: 21px;
      padding: 10px 20px;
    }
  }
}

.buttons {
  width: 535px;
  display: flex;

  flex-shrink: 0;

  /*@media @breakpoint-l {
			margin-top: 61px;
		}

		@media @breakpoint-s {
			margin-top: 4px;
		}*/
}

.button {
  position: relative;
  flex-grow: 1;

  @media @breakpoint-l {
    .button-large();
  }

  @media @breakpoint-s {
    .button-small();
  }

  &:first-child:last-child:before {
    content: "";
    position: absolute;

    background-image: url("~@/assets/stage-result/button-glow.png");
    background-size: 100% 100%;
    width: 708px;
    height: 58px;

    @media @breakpoint-l {
      right: -32px;
      bottom: -33px;
    }

    @media @breakpoint-s {
      right: -22px;
      bottom: -22px;
    }
  }

  & + & {
    margin-left: 20px;
  }
}
</style>

<script>
import Assistant from "@/components/partials/assistant";
import OrientationPlug from "@/components/orientation-plug.vue";
import NavigationController from "@/components/partials/navigation-controller";

export default {
  props: {
    text: Array,
    title: String,
    nextBtn: Object,
    resetBtn: Object,
  },
  components: {
    Assistant,
    OrientationPlug,
    NavigationController,
  },
  computed: {
    stars() {
      return this.$store.state.recentStars || 3;
    },
    resultText() {
      return this.text[this.stars - 1];
    },
    starImage() {
      switch (this.stars) {
        case 3:
          return require("@/assets/stage-result/three-stars.svg");
        case 2:
          return require("@/assets/stage-result/two-stars.svg");
        case 1:
          return require("@/assets/stage-result/one-star.svg");
        default:
          return require("@/assets/stage-result/three-stars.svg");
      }
    },
    resetVisible() {
      return this.stars < 3;
    },
  },
  methods: {
    resetGame() {
      this.$store.dispatch("gameReset");
      this.$emit("reset");
    },
  },
};
</script>
