<template>
	<div>
		<p>Level result</p>
		<p>{{ text }}</p>
		<button @click="$emit('next')">Next screen</button>
	</div>
</template>

<script>
	export default {
		props: {
			text: String
		}
	}
</script>