<template>
  <div
    class="container"
    :class="{
      startup: isStartState,
      tutorial: isTutorialState,
      'is-chosen': isDragged,
    }"
    @mousemove="onDomMouseMove($event)"
    @mouseup="onDomMouseUp($event)"
  >
    <orientation-plug />
    <navigation-controller />
    <div class="content">
      <div class="sources">
        <div v-show="!isGameFinished" class="source-wrapper">
          <span class="source-header"
            >ИИ РЕШИЛ, ЧТО ЭТО {{ this.config.headerTrue.toUpperCase() }}</span
          >
          <transition-group class="source-container" tag="div" name="draggable">
            <draggable-item
              v-for="item in greenItems"
              :key="item.id"
              class="source-item"
              v-bind="item"
              :style="getDraggedStyle(item)"
              :current-tutorial-step="tutorialStep"
              :dragged="dragId === item.id"
              @mousedown.prevent="onDomDragStart($event, item.id)"
              @touchstart.prevent="onDomTouchStart($event, item.id)"
              @touchmove.prevent="onDomTouchMove($event)"
              @touchend.prevent="onDomTouchEnd($event)"
            />
          </transition-group>
        </div>

        <div v-show="!isGameFinished" class="source-wrapper">
          <span class="source-header"
            >ИИ РЕШИЛ, ЧТО ЭТО {{ this.config.headerFalse.toUpperCase() }}</span
          >
          <transition-group class="source-container" tag="div" name="draggable">
            <draggable-item
              v-for="item in redItems"
              :key="item.id"
              class="source-item"
              v-bind="item"
              :style="getDraggedStyle(item)"
              :current-tutorial-step="tutorialStep"
              :dragged="dragId === item.id"
              @mousedown.prevent="onDomDragStart($event, item.id)"
              @touchstart.prevent="onDomTouchStart($event, item.id)"
              @touchmove.prevent="onDomTouchMove($event)"
              @touchend.prevent="onDomTouchEnd($event)"
            />
          </transition-group>
        </div>
        <div class="assistant-and-button">
          <assistant :message="assistantMessage" />
          <button
            class="button responsive"
            v-show="buttonVisible"
            @click="onButtonClick"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>

      <div class="destination-group-wrapper">
        <div class="destination-group-container">
          <p class="title top">Верные ответы ИИ</p>

          <div class="destination-bg top">
            <div class="image above"></div>
            <div class="image below"></div>
          </div>

          <div
            class="destination-wrapper numbers-correct"
            :class="{
              'tutorial-shown': tutorialItem.destination === 'numbers_correct',
              enabled: isEnabled('numbers_correct'),
            }"
             @mouseenter="$event.target.classList.add('hover')" @mouseleave="$event.target.classList.remove('hover')"
          >
            <span class="header-container">{{ this.config.headerTrue }}</span>
            <transition-group
              class="destination-container"
              tag="div"
              name="draggable"
            >
              <draggable-item
                v-for="item in numbersCorrect"
                :key="item.id"
                class="destination-item"
                v-bind="item"
                :style="getDraggedStyle(item)"
                :dragged="dragId === item.id"
                @mousedown.prevent="onDomDragStart($event, item.id)"
                @touchstart.prevent="onDomTouchStart($event, item.id)"
                @touchmove.prevent="onDomTouchMove($event)"
                @touchend.prevent="onDomTouchEnd($event)"
              />
            </transition-group>
          </div>

          <div
            class="destination-wrapper not-numbers-correct"
            :class="{
              'tutorial-shown':
                tutorialItem.destination === 'not_numbers_correct',
              enabled: isEnabled('not_numbers_correct'),
            }"
             @mouseenter="$event.target.classList.add('hover')" @mouseleave="$event.target.classList.remove('hover')"
          >
            <span class="header-container">{{ this.config.headerFalse }}</span>
            <transition-group
              class="destination-container"
              tag="div"
              name="draggable"
            >
              <draggable-item
                v-for="item in notNumbersCorrect"
                :key="item.id"
                class="destination-item"
                v-bind="item"
                :style="getDraggedStyle(item)"
                :dragged="dragId === item.id"
                @mousedown.prevent="onDomDragStart($event, item.id)"
                @touchstart.prevent="onDomTouchStart($event, item.id)"
                @touchmove.prevent="onDomTouchMove($event)"
                @touchend.prevent="onDomTouchEnd($event)"
              />
            </transition-group>
          </div>

          <p class="title bottom">Ошибки ИИ</p>

          <div class="destination-bg bottom">
            <div class="image above"></div>
            <div class="image below"></div>
          </div>

          <div
            class="destination-wrapper not-numbers-incorrect"
            :class="{
              'tutorial-shown':
                tutorialItem.destination === 'not_numbers_incorrect',
              enabled: isEnabled('not_numbers_incorrect'),
            }"
             @mouseenter="$event.target.classList.add('hover')" @mouseleave="$event.target.classList.remove('hover')"
          >
            <span class="header-container">{{ this.config.headerFalse }}</span>
            <transition-group
              class="destination-container"
              tag="div"
              name="draggable"
            >
              <draggable-item
                v-for="item in notNumbersIncorrect"
                :key="item.id"
                class="destination-item"
                v-bind="item"
                :style="getDraggedStyle(item)"
                :dragged="dragId === item.id"
                @mousedown.prevent="onDomDragStart($event, item.id)"
                @touchstart.prevent="onDomTouchStart($event, item.id)"
                @touchmove.prevent="onDomTouchMove($event)"
                @touchend.prevent="onDomTouchEnd($event)"
              />
            </transition-group>
          </div>

          <div
            class="destination-wrapper numbers-incorrect"
            :class="{
              'tutorial-shown':
                tutorialItem.destination === 'numbers_incorrect',
              enabled: isEnabled('numbers_incorrect'),
            }"
             @mouseenter="$event.target.classList.add('hover')" @mouseleave="$event.target.classList.remove('hover')"
          >
            <span class="header-container">{{ this.config.headerTrue }}</span>
            <transition-group
              class="destination-container"
              tag="div"
              name="draggable"
            >
              <draggable-item
                v-for="item in numbersIncorrect"
                :key="item.id"
                class="destination-item"
                v-bind="item"
                :style="getDraggedStyle(item)"
                :dragged="dragId === item.id"
                @mousedown.prevent="onDomDragStart($event, item.id)"
                @touchstart.prevent="onDomTouchStart($event, item.id)"
                @touchmove.prevent="onDomTouchMove($event)"
                @touchend.prevent="onDomTouchEnd($event)"
              />
            </transition-group>
          </div>
        </div>
      </div>
    </div>

    <div class="shadow" v-show="isTutorialState || isStartState"></div>
  </div>
</template>

<style scoped lang="less">
@breakpoint-mob: (max-height: 370px) and (min-height: 270px);
/* autoprefixer grid: no-autoplace */
.container {
  background-size: cover;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  //padding-top: 12px;
  padding-right: 20px;
  padding-left: 20px;
  overflow: hidden;
  line-height: 170%;
  user-select: none;

  @media @breakpoint-mob {
    height: 100vh;
  }
}

.container.is-chosen {
  cursor: url("~@/assets/minigame-2/cursor_hand_closed.png"), grabbing;
}

.content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  height: 100%;
}

.sources {
  display: grid;
  @media (min-width: 1376px) {
    grid-template-columns: 468px;
    grid-template-rows: 1fr 10px 1fr 16px auto;
  }

  @media (max-width: 1375px) and (min-width: 1024px) {
    grid-template-columns: 382px;
    grid-template-rows: 1fr 16px 1fr 9px auto;
  }

  @media (max-width: 1023px) {
    grid-template-columns: 217px;
    grid-template-rows: 1fr 10px 1fr 0px auto;
  }
}

.tutorial,
.startup {
  .sources {
    align-self: flex-start;
  }
}

.source-wrapper:nth-child(2) {
  grid-row: ~"3 / 4";
}

.source-header {
  line-height: 170%;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: left;
  color: #ffffff;

  @media (min-width: 1376px) {
    font-size: 20px;
  }

  @media (max-width: 1375px) and (min-width: 1024px) {
    font-size: 16px;
  }

  @media (max-width: 1023px) {
    font-size: 8px;
  }
}

.source-container {
  /* autoprefixer grid: autoplace */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;

  .source-item {
    @media (min-width: 1376px) {
      margin-right: 12px;
      margin-bottom: 12px;
    }

    @media (max-width: 1375px) and (min-width: 1024px) {
      margin-right: 10px;
      margin-bottom: 10px;
    }

    @media (max-width: 1023px) {
      margin-right: 4px;
      margin-bottom: 4px;
    }

    &:nth-child(n + 4) {
      margin-bottom: 0;
    }
    &:nth-child(4n + 4) {
      margin-right: 0;
    }
  }
}

.source-item {
  cursor: url("~@/assets/minigame-2/cursor_hand_opened.png"), grab;
  background-size: cover;
  position: relative;

  &.sortable-drag {
    pointer-events: none;
  }
}

.startup .source-item {
  cursor: inherit;
  pointer-events: none;
}

.sortable-chosen {
  transform: translateY(-4px);
  cursor: url("~@/assets/minigame-2/cursor_hand_closed.png"), grabbing;
}

.sortable-ghost {
  visibility: hidden;
}

.destination-item {
  cursor: url("~@/assets/minigame-2/cursor_hand_opened.png"), grab;
  background-size: cover;
  position: relative;
}

.startup .destination-item {
  cursor: inherit;
  pointer-events: none;
}

.destination-group-wrapper {
  margin-left: auto;
  height: 100%;
  //margin-top: 2.5%;
}

.destination-group-container {
  height: 100%;

  display: grid;
  position: relative;

  @media (min-width: 1376px) {
    grid-template-columns: 380px 26px 380px;
    grid-template-rows: 1fr 353px 1fr 353px 1fr;
  }

  @media (max-width: 1375px) and (min-width: 1024px) {
    grid-template-columns: 259px 16px 259px;
    grid-template-rows: 1fr 241px 1fr 241px 1fr;
  }

  @media (max-width: 1023px) {
    grid-template-columns: 132px 8px 132px;
    grid-template-rows: 1fr 122px 1fr 122px 1fr;
  }

  & > .title {
    grid-column: ~"1 / 4";
    align-self: end;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    line-height: 170%;
    letter-spacing: 0.04em;
    margin: 0;
    margin-top: 5px;

    @media (min-width: 1376px) {
      font-size: 18px;
    }

    @media (max-width: 1375px) and (min-width: 1024px) {
      font-size: 16px;
    }

    @media (max-width: 1023px) {
      font-size: 6px;
    }

    &.top {
      grid-row: ~"1 / 2";
      color: #3dffad;
    }

    &.bottom {
      grid-row: ~"3 / 4";
      color: #ffabbf;
    }
  }
}

.destination-bg {
  grid-column-start: 1;
  grid-column-end: span 3;

  position: relative;

  & > .image {
    position: absolute;
    left: -6.3%;
    width: 112.6%;
    height: 96.7%;
    background-image: url("~@/assets/minigame-2/destination-glow.svg");

    &.above {
      top: -20.7%;
    }

    &.below {
      transform: rotate(180deg);
      bottom: -8.8%;
    }
  }

  &.top {
    grid-row: ~"2 / 3";
  }

  &.bottom {
    grid-row: ~"4 / 5";
  }
}

.destination-wrapper {
  position: relative;
  z-index: 1;
  background-size: cover;

  &.numbers-correct {
    grid-row: ~"2 / 3";
    grid-column: ~"1 / 2";
  }

  &.not-numbers-correct {
    grid-row: ~"2 / 3";
    grid-column: ~"3 / 4";
  }

  &.not-numbers-incorrect {
    grid-row: ~"4 / 5";
    grid-column: ~"1 / 2";
  }

  &.numbers-incorrect {
    grid-row: ~"4 / 5";
    grid-column: ~"3 / 4";
  }

  .header-container {
    position: absolute;
    font-weight: 900;
    letter-spacing: -0.01em;
    top: 25px;
    left: 45px;
    font-size: 36px;
    line-height: 43px;
    color: #ffffff;

    @media (max-width: 1375px) and (min-width: 1024px) {
      top: 16px;
      left: 25px;
      font-size: 25px;
      line-height: 29px;
    }
    @media (max-width: 1023px) {
      top: 7px;
      left: 12px;
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.container.is-chosen .destination-wrapper.enabled:hover:before,
.container.is-chosen .destination-wrapper.enabled.hover:before {
  opacity: 1;
}

.numbers-incorrect {
  background-image: url("~@/assets/minigame-2/numbers_incorrect.png");
}

.not-numbers-correct {
  background-image: url("~@/assets/minigame-2/not_numbers_correct.png");
}

.not-numbers-incorrect {
  background-image: url("~@/assets/minigame-2/not_numbers_incorrect.png");
}

.numbers-correct {
  background-image: url("~@/assets/minigame-2/numbers_correct.png");
}

.destination-wrapper {
  &:before {
    position: absolute;
    content: "";
    background-image: url("~@/assets/minigame-2/hovered.svg");
    background-position: center;
    background-size: 100% 100%;
    transition: opacity 0.25s ease-out;
    opacity: 0;

    @media (min-width: 1376px) {
      top: -80px;
      left: -80px;
      width: 530px;
      height: 530px;
    }
    @media (max-width: 1375px) and (min-width: 1024px) {
      top: -50px;
      left: -50px;
      width: 350px;
      height: 350px;
    }
    @media (max-width: 1023px) {
      top: -30px;
      left: -30px;
      width: 190px;
      height: 190px;
    }
  }
}

.destination-header {
  margin-bottom: auto;
  font-size: 36px;
  color: white;
  margin-bottom: 60px;
}

.destination-container {
  display: grid;
}

.shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 10;
}

.tutorial-shown {
  z-index: 20;
}

@media (min-width: 1640px) {
  .container {
    background-image: url("~@/assets/minigame-2/background_1920.png");
    padding-left: 176px;
    padding-right: 176px;
  }
  .content {
    max-width: 1568px;
  }
  .source-item {
    width: 108px;
    height: 108px;
  }
  .destination-container {
    /* autoprefixer grid: autoplace */
    grid-template-columns: repeat(3, 96px);
    grid-template-rows: repeat(2, 96px);
    gap: 12px;
    padding-top: 128px;
    padding-left: 36px;
    padding-bottom: 20px;
    padding-right: 32px;
  }
  .destination-item {
    width: 96px;
    height: 96px;
  }
}

@media (min-width: 1440px) and (max-width: 1639px) {
  .container {
    background-image: url("~@/assets/minigame-2/background_1920.png");
    padding-left: 40px;
    padding-right: 40px;
  }
  .content {
    max-width: 1568px;
  }
  .source-item {
    width: 108px;
    height: 108px;
  }
  /*.destination-group-container::before {
			top: -44px;
			left: -14px;
			bottom: -26px;
			right: -14px;
		}*/
  .destination-container {
    /* autoprefixer grid: autoplace */
    grid-template-columns: repeat(3, 96px);
    grid-template-rows: repeat(2, 96px);
    gap: 12px;
    padding-top: 128px;
    padding-left: 36px;
    padding-bottom: 20px;
    padding-right: 32px;
  }
  .destination-item {
    width: 96px;
    height: 96px;
  }
}

@media (min-width: 1377px) and (max-width: 1439px) {
  .container {
    background-image: url("~@/assets/minigame-2/background_1920.png");
    padding-left: 40px;
    padding-right: 40px;
  }
  .content {
    max-width: 1568px;
  }
  .source-item {
    width: 108px;
    height: 108px;
  }
  /*.destination-group-container::before {
			top: -44px;
			left: -14px;
			bottom: -26px;
			right: -14px;
		}*/
  .destination-container {
    /* autoprefixer grid: autoplace */
    grid-template-columns: repeat(3, 96px);
    grid-template-rows: repeat(2, 96px);
    gap: 12px;
    padding-top: 128px;
    padding-left: 36px;
    padding-bottom: 20px;
    padding-right: 32px;
  }
  .destination-item {
    width: 96px;
    height: 96px;
  }
}

@media (min-width: 1118px) and (max-width: 1376px) {
  .container {
    background-image: url("~@/assets/minigame-2/background_1118.png");
  }
  .content {
    max-width: 1038px;
    justify-content: space-between;
  }
  .source-item {
    width: 89px;
    height: 89px;
  }
  /*.destination-group-container::before {
			top: -73px;
			left: -117px;
			bottom: -133px;
			right: -68px;
		}*/
  .destination-header {
    font-size: 24px;
  }
  .destination-container {
    /* autoprefixer grid: autoplace */
    grid-template-columns: repeat(3, 64px);
    grid-template-rows: repeat(2, 64px);
    gap: 12px;
    padding-top: 84px;
    padding-left: 26px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .destination-item {
    width: 64px;
    height: 64px;
  }
}

@media (min-width: 1024px) and (max-width: 1117px) {
  .container {
    background-image: url("~@/assets/minigame-2/background_1118.png");
    padding-left: 40px;
    padding-right: 40px;
  }
  .content {
    max-width: 1568px;
  }
  .source-item {
    width: 89px;
    height: 89px;
  }
  /*.destination-group-container::before {
			top: -73px;
			left: -117px;
			bottom: -133px;
			right: -68px;
		}*/
  .destination-header {
    font-size: 24px;
  }
  .destination-container {
    /* autoprefixer grid: autoplace */
    grid-template-columns: repeat(3, 64px);
    grid-template-rows: repeat(2, 64px);
    gap: 12px;
    padding-top: 84px;
    padding-left: 26px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
  .destination-item {
    width: 64px;
    height: 64px;
  }
}

@media (min-width: 568px) and (max-width: 1023px) {
  .container {
    background-image: url("~@/assets/minigame-2/background_568.png");
    line-height: 100%;
  }
  .content {
    max-width: 528px;
    justify-content: space-between;
  }
  .source-item {
    width: 40px;
    height: 40px;
  }
  .destination-header {
    font-size: 12px;
  }
  .destination-container {
    /* autoprefixer grid: autoplace */
    grid-template-columns: repeat(3, 32px);
    grid-template-rows: repeat(2, 32px);
    gap: 4px;
    padding-top: 44px;
    padding-left: 16px;
    padding-bottom: 12px;
    padding-right: 12px;
  }
  .destination-item {
    width: 32px;
    height: 32px;
  }
}

.assistant-and-button {
  z-index: 19;
  grid-row: ~"5 / 6";
}

.startup,
.tutorial {
  .assistant-and-button {
    position: fixed;
    bottom: 10px;
  }
}

.assistant {
  pointer-events: none;

  @media (min-width: 1376px) {
    width: 468px;
  }

  @media (max-width: 1375px) and (min-width: 1024px) {
    width: 382px;
  }

  @media (max-width: 1023px) {
    width: 217px;
    margin-top: -3.3px;

    /deep/ .assistant-name {
      margin-left: auto;
      margin-right: 0;
    }
  }
}

.button {
  @media (min-width: 1376px) {
    width: 468px;
    margin-top: 12px;
  }

  @media (max-width: 1375px) and (min-width: 1024px) {
    width: 382px;
    margin-top: 8px;
  }

  @media (max-width: 1023px) {
    width: 217px;
    margin-top: 6px;
  }
}
</style>

<script>
import OrientationPlug from "@/components/orientation-plug.vue";
import NavigationController from "@/components/partials/navigation-controller";
import Assistant from "@/components/partials/assistant";

import DraggableItem from "./minigame-2/draggable-item";

export default {
  props: {
    config: Object,
  },
  components: {
    OrientationPlug,
    NavigationController,
    Assistant,
    DraggableItem,
  },
  data: function () {
    return {
      items: this.config.items.map((item, index) => ({
        ...item,
        id: `${item.type}-${index}`,
        position: null,
        order: index,
      })),
      isChosen: false,
      assistantMessage: "",

      startupStep: 0,
      tutorialStep: -1,
      halfPassed: false,

      mouseDown: false,
      isDragged: false,
      dragId: null,
      baseX: 0,
      dragX: 0,
      dragOffsetX: 0,
      baseY: 0,
      dragY: 0,
      dragOffsetY: 0,
    };
  },
  computed: {
    draggedItem() {
      return this.items.find((item) => item.id === this.dragId);
    },
    greenItems() {
      return this.items.filter(
        (item) => item.type === "green" && !item.position
      );
    },
    redItems() {
      return this.items.filter((item) => item.type === "red" && !item.position);
    },
    numbersCorrect() {
      return this.items
        .filter((item) => item.position === "numbers_correct")
        .sort((a, b) => a.order - b.order);
    },
    numbersIncorrect() {
      return this.items
        .filter((item) => item.position === "numbers_incorrect")
        .sort((a, b) => a.order - b.order);
    },
    notNumbersCorrect() {
      return this.items
        .filter((item) => item.position === "not_numbers_correct")
        .sort((a, b) => a.order - b.order);
    },
    notNumbersIncorrect() {
      return this.items
        .filter((item) => item.position === "not_numbers_incorrect")
        .sort((a, b) => a.order - b.order);
    },
    answersNumber() {
      return this.items.filter((item) => !!item.position).length;
    },
    startupLength() {
      return this.config.messagesOnStart.length;
    },
    tutorialItem() {
      return (
        this.items.find((item) => item.tutorialStep === this.tutorialStep) || {}
      );
    },
    tutorialLength() {
      return this.config.items.filter((item) => item.tutorialStep >= 0).length;
    },
    isGameFinished() {
      return this.answersNumber === this.items.length;
    },
    isTutorialState() {
      return this.tutorialStep < this.tutorialLength && this.tutorialStep >= 0;
    },
    isStartState() {
      return this.startupStep < this.startupLength && this.startupStep >= 0;
    },
    buttonVisible() {
      return this.isStartState || this.isGameFinished;
    },
    buttonText() {
      if (this.startupStep < this.startupLength) {
        return "Далее";
      }
      if (this.isGameFinished) {
        return "Обновить ИИ";
      }
      return "";
    },
  },
  methods: {
    canPut(item, position) {
      if (this.config.instantCheck) {
        return this.isEnabled(position) && item.destination === position;
      } else {
        return this.isEnabled(position) && this.getCount(position) < 6;
      }
    },
    getCount(position) {
      return this.items.filter((item) => item.position === position).length;
    },
    isEnabled(destination) {
      if (this.isStartState || this.isGameFinished) {
        return false;
      }
      if (this.isTutorialState) {
        return this.tutorialItem.destination === destination;
      } else {
        return true;
      }
    },
    showSuccessMessage() {
      if (this.isGameFinished) {
        return (this.assistantMessage = this.config.messageOnGameFinished);
      }
      if (this.config.instantCheck) {
        this.assistantMessage = this.draggedItem.messageOnOk;
      } else {
        if (this.answersNumber >= this.items.length / 2 && !this.halfPassed) {
          this.halfPassed = true;
          this.assistantMessage = this.config.messageOnProceed;
        }
      }
    },
    showErrorMessage(item, position) {
      if (this.getCount(position) === 6) {
        return (this.assistantMessage = this.config.messageOnOverflow);
      }
      if (this.config.instantCheck && item.destination !== position) {
        return (this.assistantMessage = item.messageOnError);
      }
    },
    onButtonClick() {
      if (this.startupStep < this.startupLength) {
        this.startupStep += 1;
      }
      if (this.isGameFinished) {
        const errorNum = this.items.filter(
          (item) => item.position !== item.destination
        ).length;
        let stars = 3;
        if (errorNum > 3) {
          stars = 2;
        }
        if (errorNum > 6) {
          stars = 1;
        }
        this.$store.dispatch("gameFinished", { stars });
        this.$emit("done");
      }
    },
    getDraggedStyle(item) {
      if (!this.isDragged || item.id !== this.dragId) {
        return;
      }
      const x = this.dragX + this.dragOffsetX - this.baseX;
      const y = this.dragY + this.dragOffsetY - this.baseY;
      return {
        transform: `translate(${x}px, ${y}px)`,
      };
    },
    onDomDragStart(ev, id) {
      //this.isDragged = true;
      this.mouseDown = true;
      this.dragId = id;
      this.dragX = ev.pageX;
      this.dragY = ev.pageY;
      const el = document.getElementById(`item-${id}`);
      const bb = el.getBoundingClientRect();
      this.baseX = bb.left;
      this.baseY = bb.top;
      this.dragOffsetX = -ev.pageX + bb.left;
      this.dragOffsetY = -ev.pageY + bb.top;
    },
    onDomMouseMove(ev) {
      if (this.mouseDown) {
        this.isDragged = true;
      }
      if (this.isDragged) {
        this.dragX = ev.pageX;
        this.dragY = ev.pageY;
      }
    },
    onDomMouseUp(ev) {
      this.mouseDown = false;
      if (!this.isDragged) {
        this.dragId = null;
        return;
      }
      let target = document.elementFromPoint(ev.pageX, ev.pageY);
      while (
        !target.classList.contains("destination-wrapper") &&
        target.parentElement
      ) {
        target = target.parentElement;
      }
      if (target.classList.contains("destination-wrapper")) {
        let position;
        if (target.classList.contains("numbers-correct")) {
          position = "numbers_correct";
        }
        if (target.classList.contains("numbers-incorrect")) {
          position = "numbers_incorrect";
        }
        if (target.classList.contains("not-numbers-correct")) {
          position = "not_numbers_correct";
        }
        if (target.classList.contains("not-numbers-incorrect")) {
          position = "not_numbers_incorrect";
        }
        if (this.canPut(this.draggedItem, position)) {
          this.draggedItem.order = Date.now();
          this.draggedItem.position = position;
          this.showSuccessMessage();
          if (this.isTutorialState) {
            this.tutorialStep += 1;
          }
        } else {
          this.showErrorMessage(this.draggedItem, position);
        }
      }
      this.isDragged = false;
      this.dragId = null;
    },
    onDomTouchStart(ev, id) {
      this.onDomDragStart(ev.changedTouches[0], id);
    },
    onDomTouchMove(ev) {
      this.onDomMouseMove(ev.changedTouches[0]);
    },
    onDomTouchEnd(ev) {
      this.onDomMouseUp(ev.changedTouches[0]);
    },
  },
  watch: {
    startupStep: {
      immediate: true,
      handler(val) {
        if (this.isStartState) {
          this.assistantMessage = this.config.messagesOnStart[val];
        } else {
          this.tutorialStep = 0;
        }
      },
    },
    tutorialStep() {
      if (this.isTutorialState) {
        this.assistantMessage = this.tutorialItem.tutorialMessage;
      } else {
        this.assistantMessage = this.config.messageOnPostTutorial;
      }
    },
  },
};
</script>
