<template>
  <component
    :is="currentComponent"
    v-bind="currentStep"
    @next="next"
    @prev="previous"
  />
</template>

<script>
import navigation from "@/mixins/navigation";

import DialogScreen from "@/components/dialog-screen";
import LevelResult from "@/components/level-result";
import LevelTutor from "@/components/level-tutor";
import LoaderScreen from "@/components/loader-screen";
import ChoiceAi from "@/components/choice-ai";
import Stage from "./stage";

export default {
  mixins: [navigation],
  props: {
    source: String,
  },
  computed: {
    currentComponent() {
      switch (this.currentStep.type) {
        case "dialog":
          return DialogScreen;
        case "result":
          return LevelResult;
        case "tutor":
          return LevelTutor;
        case "loader":
          return LoaderScreen;
        case "stage":
          return Stage;
        case "ai":
          return ChoiceAi;
        default:
          throw new Error("Screen type not found");
      }
    },
  },
};
</script>