<template>
	<div class="page">
		<img :src="require(`@/assets/scanner/content/${stage.image}`)" />
		<svg
			class="errors"
			viewBox="0 0 675 774"
			width="86.5%"
			height="86.5%"
			preserveAspectRatio="none"
			xmlns="http://www.w3.org/2000/svg"
			ref="svg"
			@mousedown="onMouseDown($event)"
			@mouseup="onMouseUp($event)"
		>
			<rect v-for="rect in rects" :key="JSON.stringify(rect)" :x="rect.x" :y="rect.y" :width="rect.width" :height="rect.height" stroke="red" fill="none" />
			<g
				v-for="(error, i) in errors"
				:key="`error-${i}`"
				:transform="`translate(${error.position.x + error.position.width / 2}, ${error.position.y + error.position.height / 2})`"
			>
				<g 
					class="error-marker"
					:class="{ animated: error.visible }"
				>
					<polygon :key="`polygon-${i}`" :points="getPolygon(error.position)" />
					<image 
						:key="`corner-t-r-${i}`"
						x="-9"
						y="-10"
						width="18"
						height="20"
						:href="require(`@/assets/scanner/error-corner.svg`)"
						:transform="`translate(${error.position.width / 2 - 9}, ${-error.position.height / 2 + 10}) rotate(0)`"
					/>
					<image 
						:key="`corner-t-l-${i}`"
						x="-9"
						y="-10"
						width="18"
						height="20"
						:href="require(`@/assets/scanner/error-corner.svg`)"
						:transform="`translate(${-error.position.width / 2 + 9}, ${-error.position.height / 2 + 10}) rotate(-90)`"
					/>
					<image 
						:key="`corner-b-l-${i}`"
						x="-9"
						y="-10"
						width="18"
						height="20"
						:href="require(`@/assets/scanner/error-corner.svg`)"
						:transform="`translate(${-error.position.width / 2 + 9}, ${error.position.height / 2 - 10}) rotate(180)`"
					/>
					<image 
						:key="`corner-b-r-${i}`"
						x="-9"
						y="-10"
						width="18"
						height="20"
						:href="require(`@/assets/scanner/error-corner.svg`)"
						:transform="`translate(${error.position.width / 2 - 9}, ${error.position.height / 2 - 10}) rotate(90)`"
					/>
				</g>
			</g>
		</svg>
	</div>
</template>

<style scoped lang="less">
	@import "scanner/breakpoints";

	.page {
		position: relative;
		margin-top: 7.5%;
		margin-left: auto;
		margin-right: auto;

		flex-shrink: 0;

		@media @breakpoint-xxl, @breakpoint-xl {
			width: 675px;
			height: 774px;
		}

		@media @breakpoint-l {
			width: 560px;
			height: 642px;
		}

		@media @breakpoint-m {
			width: 419px;
			height: 480px;
		}

		@media @breakpoint-s, @breakpoint-xs {
			width: 211px;
			height: 242px;
		}

		img {
			display: block;
			position: absolute;
			width: 118.4%;
			top: -11.1%;
			left: -9.2%;
			right: -9.2%;
		}

		.errors {
			/* remove next line to activate development selection mode */
			pointer-events: none;

			position: absolute;
			top: 2.5%;
			left: 6.9%;
			right: 6.6%;

			.error-marker {
				opacity: 0;
				transform: scale(0.4);
				transition: transform .2s, opacity .2s;

				&.animated {
					opacity: 1;
					transform: scale(1);
				}
			}

			polygon {
				stroke: none;
				fill: rgba(246, 78, 119, 0.6);
			}
		}
	}
</style>

<script>
	import sync from "./sync";

	export default {
		props: {
			stage: Object,
			gameResult: String,
		},
		mixins: [sync],
		data(){
			return {
				firstPointX: 0,
				firstPointY: 0,
				lastPointX: 0,
				lastPointY: 0,
				rects: [],
				errors: this.stage.errors.map(err => ({ ...err, visible: false }))
			};
		},
		computed: {
			
		},
		methods: {
			screenToSvg(x, y){
				if(!this.pt){
					this.pt = this.$refs.svg.createSVGPoint();
				}
				this.pt.x = x;
				this.pt.y = y;
				const cpt = this.pt.matrixTransform(this.$refs.svg.getScreenCTM().inverse());
				return [Math.round(cpt.x), Math.round(cpt.y)];
			},
			onMouseDown(ev){
				[this.firstPointX, this.firstPointY] = this.screenToSvg(ev.clientX, ev.clientY);
			},
			onMouseUp(ev){
				[this.lastPointX, this.lastPointY] = this.screenToSvg(ev.clientX, ev.clientY);
				let x = this.firstPointX > this.lastPointX ? this.lastPointX : this.firstPointX;
				let y = this.firstPointY > this.lastPointY ? this.lastPointY : this.firstPointY;
				let width = Math.abs(this.firstPointX - this.lastPointX);
				let height = Math.abs(this.firstPointY - this.lastPointY);
				if(width < 40){
					x -= Math.round((40 - width) / 2);
					width = 40;
				}
				if(height < 40){
					y -= Math.round((40 - height) / 2);
					height = 40;
				}
				const rect = { x, y, width, height };
				this.rects.push(rect);
				console.log(JSON.stringify(rect));
			},
			getPolygon({ width, height }){
				const bevel = 15;
				const points = [
					`${-width / 2 + bevel},${-height / 2}`,
					`${width / 2 - bevel},${-height / 2}`,
					`${width / 2},${-height / 2 + bevel}`,
					`${width / 2},${height / 2 - bevel}`,
					`${width / 2 - bevel},${height / 2}`,
					`${-width / 2 + bevel},${height / 2}`,
					`${-width / 2},${height / 2 - bevel}`,
					`${-width / 2},${-height / 2 + bevel}`
				];
				return points.join(' ');
			},
			getTriggeredMessages(beamYPosition){
				if(!this.$refs.svg){
					return [];
				}
				const [, beamY] = this.screenToSvg(0, beamYPosition);
				const errors = this.errors.filter(err => err.position.y <= beamY);
				const self = this;
				errors.forEach(function(err){
					err.visible = err.gameResults.includes(self.gameResult);
				});
				return errors.reduce(function(acc, err){
					if(err.trigger){
						const message = self.messages.find(msg => msg.trigger && msg.trigger.error === err.trigger);
						message && acc.push(message);
					}
					return acc;
				}, []);
			}
		}
	}
</script>