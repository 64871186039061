<template>
  <div
    class="game"
    :class="{ 'is-dragged': isDragged }"
    @mousemove="onDomMouseMove($event)"
    @mouseup="onDomMouseUp($event)"
  >
    <div class="column left" @mouseenter="$event.target.classList.add('hover')" @mouseleave="$event.target.classList.remove('hover')">
      <div class="backdrop"></div>
      <h2 class="title">{{ config.labels.left }}</h2>
      <transition-group class="dropzone" tag="div" name="draggable">
        <div
          class="item"
          v-for="item in leftItems"
          :key="item.id"
          :class="{
            dragged: dragId === item.id,
          }"
          :style="getDraggedStyle(item)"
          :id="`item-${item.id}`"
          @mousedown.prevent="onDomDragStart($event, item.id)"
          @touchstart.prevent="onDomTouchStart($event, item.id)"
          @touchmove.prevent="onDomTouchMove($event)"
          @touchend.prevent="onDomTouchEnd($event)"
        >
          <div class="inner">
            <img :src="require('@/' + item.image.replace('%1', 'left'))" />
          </div>
        </div>
      </transition-group>
    </div>

    <div class="center">
      <div class="progress">
        <div class="progress-label">
          {{ leftItems.length + rightItems.length }} /
          {{ itemCount }} прогресс...
        </div>
        <div class="progress-bars">
          <div
            class="progress-item"
            v-for="n in leftItems.length + rightItems.length"
            :key="`full-${n}`"
          >
            <img src="~@/assets/minigame-1/progress-full.png" />
          </div>
          <div
            class="progress-item"
            v-for="n in centerItems.length"
            :key="`empty-${n}`"
          >
            <img src="~@/assets/minigame-1/progress-empty.png" />
          </div>
        </div>
      </div>
      <transition-group class="item-source" tag="div" name="draggable">
        <div
          class="item"
          v-for="item in centerItems"
          :key="item.id"
          :class="{
            dragged: dragId === item.id,
            raised: mouseDown && draggedItem.position === 'center',
          }"
          :style="getDraggedStyle(item)"
          :id="`item-${item.id}`"
          @mousedown.prevent="onDomDragStart($event, item.id)"
          @touchstart.prevent="onDomTouchStart($event, item.id)"
          @touchmove.prevent="onDomTouchMove($event)"
          @touchend.prevent="onDomTouchEnd($event)"
        >
          <div class="inner">
            <img :src="require('@/' + item.image.replace('%1', 'center'))" />
          </div>
        </div>
      </transition-group>
      <assistant :message="assistantMessage" />
      <button class="button" v-show="gameFinished" @click="finishGame">
        Загрузить данные
      </button>
    </div>

    <div class="column right" @mouseenter="$event.target.classList.add('hover')" @mouseleave="$event.target.classList.remove('hover')">
      <div class="backdrop"></div>
      <h2 class="title">{{ config.labels.right }}</h2>
      <transition-group class="dropzone" tag="div" name="draggable">
        <div
          class="item"
          v-for="item in rightItems"
          :key="item.id"
          :class="{
            dragged: dragId === item.id,
          }"
          :style="getDraggedStyle(item)"
          :id="`item-${item.id}`"
          @mousedown.prevent="onDomDragStart($event, item.id)"
          @touchstart.prevent="onDomTouchStart($event, item.id)"
          @touchmove.prevent="onDomTouchMove($event)"
          @touchend.prevent="onDomTouchEnd($event)"
        >
          <div class="inner">
            <img :src="require('@/' + item.image.replace('%1', 'right'))" />
          </div>
        </div>
      </transition-group>
    </div>
    <orientation-plug />
    <navigation-controller />
  </div>
</template>

<style scoped lang="less">
/* autoprefixer grid: autoplace */
@import (reference) "buttons";

@breakpoint-large: (min-width: 1440px);
@breakpoint-xl: (min-width: 1640px);
@breakpoint-l: (max-width: 1639px) and (min-width: 1440px);

@breakpoint-medium: (max-width: 1439px) and (min-width: 1000px);
@breakpoint-m: @breakpoint-medium;

@breakpoint-small: (max-width: 999px);
@breakpoint-s: (max-width: 999px) and (min-width: 641px);
@breakpoint-xs: (max-width: 640px);
@breakpoint-mob: (max-height: 370px) and (min-height: 270px);

.game {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 8px;

  cursor: url("~@/assets/cursors/cursor-normal.png"), pointer;
  &.is-dragged {
    cursor: url("~@/assets/minigame-1/cursor-hand-closed.png"), grab;
  }

  display: flex;
  justify-content: space-between;

  @media @breakpoint-xl {
    padding-left: calc((100% - 1560px) / 2);
    padding-right: calc((100% - 1560px) / 2);
  }
  @media @breakpoint-l {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media @breakpoint-m {
    padding-left: calc((100% - 919px) / 2);
    padding-right: calc((100% - 919px) / 2);
  }
  @media @breakpoint-s {
    padding-left: calc((100% - 607px) / 2);
    padding-right: calc((100% - 607px) / 2);
  }
  @media @breakpoint-xs {
    padding-left: 18px;
    padding-right: 18px;
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media @breakpoint-xl {
      width: 512px;
    }

    @media @breakpoint-l {
      width: 577px;
    }

    @media @breakpoint-m {
      width: 358px;
    }

    @media @breakpoint-s, @breakpoint-xs {
      width: 256px;
    }
  }

  .progress,
  .item-source,
  .assistant {
    @media @breakpoint-l, @breakpoint-m {
      margin-left: 12px;
      margin-right: 12px;
    }
    @media @breakpoint-s, @breakpoint-xs {
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .item-source {
    margin: auto 0;
  }

  .assistant {
    @media @breakpoint-large {
      flex-basis: 235px;
    }

    @media @breakpoint-medium {
      flex-basis: 216px;
    }

    @media @breakpoint-small {
      flex-basis: 97px;
    }
  }

  .column {
    @media @breakpoint-xl {
      width: 380px;
    }

    @media @breakpoint-l {
      flex-basis: 0;
      min-width: 377px;
      flex-grow: 1;
    }

    @media @breakpoint-m {
      width: 253px;
    }

    @media @breakpoint-s {
      width: 131px;
    }

    @media @breakpoint-xs {
      flex-basis: 0;
      max-width: 133px;
      min-width: 114px;
      flex-grow: 1;
    }
  }

  .left {
    @media @breakpoint-l, @breakpoint-m {
      margin-right: 12px;
    }
    @media @breakpoint-s, @breakpoint-xs {
      margin-right: 12px;
    }
  }

  .right {
    @media @breakpoint-l, @breakpoint-m {
      margin-left: 12px;
    }
    @media @breakpoint-s, @breakpoint-xs {
      margin-left: 12px;
    }
  }
}

.game {
  font-family: Gilroy;
  line-height: 170%;
  background-repeat: no-repeat;
  background-position: top;
  @media @breakpoint-xl {
    background-size: 100% 100%;
    background-image: url("~@/assets/minigame-1/bg-huge.svg");
  }
  @media @breakpoint-l {
    background-size: 100% 100%;
    background-image: url("~@/assets/minigame-1/bg-large.svg");
  }
  @media @breakpoint-m {
    background-size: 1439px 100%;
    background-image: url("~@/assets/minigame-1/bg-medium.svg");
  }
  @media @breakpoint-s, @breakpoint-xs {
    background-size: 999px 100%;
    background-image: url("~@/assets/minigame-1/bg-small.svg");
  }
  @media @breakpoint-mob {
    background-size: 999px 100%;
    background-image: url("~@/assets/minigame-1/bg-small.svg");
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
  }
}

.progress {
  width: 100%;
  background-repeat: no-repeat;
  background-position-y: -2px;
  background-size: 100% 100%;

  @media @breakpoint-large {
    padding: 20px 55px 32px 55px;
    background-image: url("~@/assets/minigame-1/progress-frame-large.png");
  }
  @media @breakpoint-medium {
    padding: 10px 32px 32px 32px;
    background-image: url("~@/assets/minigame-1/progress-frame-medium.png");
  }
  @media @breakpoint-small {
    padding: 6px 48px 16px 48px;
    background-image: url("~@/assets/minigame-1/progress-frame-small.png");
  }

  .progress-label {
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    @media @breakpoint-large {
      font-size: 16px;
      margin-bottom: 12px;
    }
    @media @breakpoint-medium {
      font-size: 14px;
      margin-bottom: 8px;
    }
    @media @breakpoint-small {
      font-size: 10px;
      line-height: 160%;
      margin-bottom: 3px;
    }
  }

  .progress-bars {
    display: flex;
    justify-content: space-between;

    .progress-item {
      position: relative;

      @media @breakpoint-large {
        width: 32px;
        height: 36px;
      }
      @media @breakpoint-medium {
        width: 24px;
        height: 28px;
      }
      @media @breakpoint-small {
        width: 13px;
        height: 16px;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;

        @media @breakpoint-large {
          transform: translate(-23.5px, -23.5px) scale(1);
        }
        @media @breakpoint-medium {
          transform: translate(-23.5px, -23.5px) scale(24/32);
        }
        @media @breakpoint-small {
          transform: translate(-23.5px, -23.5px) scale(13/32);
        }
      }
    }
  }
}

.assistant {
  width: 100%;
  @media @breakpoint-medium {
    /deep/ .assistant-name {
      height: 32px;
      width: 98px;
      font-size: 14px;
    }

    /deep/ .assistant-message {
      font-size: 14px;
      padding: 12px 16px 20px;
    }

    /deep/ .assistant-avatar {
      display: block;
      top: -16px;
      width: 72px;
      height: 72px;
    }
  }

  @media @breakpoint-small {
    /deep/ .assistant-name {
      height: 18px;
      width: 53px;
      font-size: 6.5px;
    }

    /deep/ .assistant-message {
      font-size: 10px;
      padding: 8px;
    }

    /deep/ .assistant-avatar {
      display: none;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;

  position: relative;

  background-size: 100% 100%;
  background-repeat: no-repeat;

  margin-top: auto;
  margin-bottom: auto;

  @media @breakpoint-large {
    height: 780px;
    padding: 52px 32px 32px 45px;
  }
  @media @breakpoint-medium {
    height: 496px;
    padding: 35px 23px 32px 38px;
  }
  @media @breakpoint-small {
    height: 225px;
    padding: 17px 10px 10px 20px;
  }

  .title {
    color: #fff;
    margin-top: 0;

    @media @breakpoint-large {
      font-size: 42px;
      font-weight: 900;
      line-height: 110%;
      margin-bottom: 24px;
    }

    @media @breakpoint-medium {
      font-size: 26px;
      font-weight: 800;
      line-height: 120%;
      margin-bottom: 22px;
    }

    @media @breakpoint-small {
      font-size: 12px;
      font-weight: 800;
      line-height: 110%;
      margin-bottom: 8px;
    }
  }

  .dropzone {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
  }

  .item {
    @media @breakpoint-large {
      width: 140px;
      height: 140px;
      margin-bottom: 20px;
    }
    @media @breakpoint-medium {
      width: 84px;
      height: 84px;
      margin-bottom: 12px;
    }
    @media @breakpoint-small {
      width: 40px;
      height: 40px;
      margin-bottom: 4px;
    }
  }

  &:before {
    content: "";
    position: absolute;

    background-size: 100%;
    background-repeat: no-repeat;

    @media @breakpoint-large {
      width: 483px;
      height: 575px;
      top: -56px;
    }

    @media @breakpoint-medium {
      width: 215px;
      height: 256px;
      top: -17px;
    }

    @media @breakpoint-small {
      width: 150px;
      height: 179px;
      top: -18px;
    }
  }

  .backdrop {
    position: relative;
    z-index: 0;

    &:before {
      position: absolute;
      content: "";
      background-position: center;
      background-size: 100% 100%;
      background: url("~@/assets/minigame-1/hovered-green.svg");
      transition: opacity 0.25s ease-out;
      opacity: 0;

      @media @breakpoint-large {
        top: -80px;
        left: -70px;
        width: 440px;
        height: 830px;
      }
      @media @breakpoint-medium {
        top: -60px;
        left: -80px;
        width: 290px;
        height: 520px;
      }
      @media @breakpoint-small {
        top: -50px;
        left: -55px;
        width: 165px;
        height: 260px;
      }
    }
  }
}

.game.is-dragged .column:hover .backdrop:before,
.game.is-dragged .column.hover .backdrop:before {
  opacity: 1;
}

.column.left {
  background-image: url("~@/assets/minigame-1/dropzone-left.png");

  .item .inner {
    background-image: url("~@/assets/minigame-1/drop-item-left-inactive.png");
  }

  &:before {
    background-image: url("~@/assets/minigame-1/glow-red.svg");

    @media @breakpoint-large {
      left: -70px;
    }

    @media @breakpoint-medium {
      left: -26px;
    }

    @media @breakpoint-small {
      left: -11px;
    }
  }

  .backdrop {
    &:before {
      background: url("~@/assets/minigame-1/hovered-red.svg");
    }
  }
}

.column.right {
  background-image: url("~@/assets/minigame-1/dropzone-right.png");

  .item .inner {
    background-image: url("~@/assets/minigame-1/drop-item-right-inactive.png");
  }

  &:before {
    background-image: url("~@/assets/minigame-1/glow-green.svg");

    @media @breakpoint-large {
      right: -70px;
    }

    @media @breakpoint-medium {
      right: -26px;
    }

    @media @breakpoint-small {
      right: -11px;
    }
  }

  .backdrop {
    &:before {
      background: url("~@/assets/minigame-1/hovered-green.svg");
    }
  }
}

.item-source {
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 100%;
  @media @breakpoint-large {
    height: 224px;
  }
  @media @breakpoint-medium {
    height: 144px;
  }
  @media @breakpoint-small {
    height: 72px;
  }

  .item {
    @media @breakpoint-large {
      width: 224px;
      height: 224px;
    }
    @media @breakpoint-medium {
      width: 144px;
      height: 144px;
    }
    @media @breakpoint-small {
      width: 72px;
      height: 72px;
    }

    &:hover {
      transition: transform 0.25s;
    }

    &.raised {
      transform: translateY(-4px);
    }

    .inner {
      background-image: url("~@/assets/minigame-1/drop-item-center-inactive.png");
      filter: drop-shadow(0px 3px 47px rgba(255, 231, 19, 0.31));

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        background: rgba(255, 231, 19, 0.31);
        filter: blur(44px);
        opacity: 0;
        transition: opacity 0.3s;
      }
    }
  }

  .item:hover .inner:before {
    opacity: 1;
    //background-image: url("~@/assets/minigame-1/drop-item-center-active.png");
  }

  .item + .item {
    display: none;
  }

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    background-image: url("~@/assets/minigame-1/holo.svg");
    background-size: 100%;

    @media @breakpoint-large {
      top: 183px;
      width: 245px;
      height: 181px;
    }

    @media @breakpoint-medium {
      top: 128px;
      width: 168px;
      height: 124px;
    }

    @media @breakpoint-small {
      top: 62px;
      width: 78px;
      height: 58px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    background-image: url("~@/assets/minigame-1/drop-item-target.png"),
      url("~@/assets/minigame-1/arrows-left.png"),
      url("~@/assets/minigame-1/arrows-right.png");
    background-repeat: no-repeat;
    background-position: center, center left, center right;

    @media @breakpoint-large {
      background-size: 224px, 88px, 88px;
    }
    @media @breakpoint-medium {
      background-size: 144px, 70px, 70px;
    }
    @media @breakpoint-small {
      background-size: 72px, 44px, 44px;
    }
  }
}

.item {
  cursor: url("~@/assets/minigame-1/cursor-hand-open.png"), grab;
  transition: opacity 0.45s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

  &.dragged {
    pointer-events: none;
    z-index: 99;

    * {
      pointer-events: none;
    }
  }

  .inner {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    background-repeat: no-repeat;
    background-size: 100% 100%;

    transition: transform 0.45s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  img {
    position: relative;
    width: 85%;
  }
}

.draggable-enter,
.draggable-leave-to {
  opacity: 0;

  .inner {
    transform: scale(0);
  }
}

.draggable-enter,
.draggable-enter .inner {
  transition-delay: 0.45s;
}

.button {
  width: 100%;
  @media @breakpoint-large {
    .button-large();
    margin-top: 16px;
  }

  @media @breakpoint-medium {
    .button-medium();
    margin-top: 12px;
  }

  @media @breakpoint-small {
    .button-small();
    margin-top: 9px;
  }
}
</style>

<script>
import { markFirstTaskComplete } from "@/api";

import Assistant from "@/components/partials/assistant";
import OrientationPlug from "@/components/orientation-plug.vue";
import NavigationController from "@/components/partials/navigation-controller";

export default {
  props: {
    config: Object,
  },
  components: {
    Assistant,
    OrientationPlug,
    NavigationController,
  },
  data: function () {
    return {
      items: this.config.items.map((item, index) => ({
        ...item,
        id: index,
        position: "center",
      })),
      isDragged: false,
      dragId: null,
      baseX: 0,
      dragX: 0,
      dragOffsetX: 0,
      baseY: 0,
      dragY: 0,
      dragOffsetY: 0,
      assistantMessage:
        this.config.items[0].messageOnShow || this.config.messageOnShow[0],
      showMessageIndex: 0,
      rightErrorMessageIndex: 0,
      leftErrorMessageIndex: 0,
      mouseDown: false
    };
  },
  computed: {
    centerItems() {
      return this.items.filter((item) => item.position === "center");
    },
    leftItems() {
      return this.items.filter((item) => item.position === "left");
    },
    rightItems() {
      return this.items.filter((item) => item.position === "right");
    },
    draggedItem() {
      return this.items.find((item) => item.id === this.dragId);
    },
    itemCount() {
      return this.config.items.length;
    },
    gameFinished() {
      return this.centerItems.length === 0;
    },
  },
  methods: {
    getDraggedStyle(item) {
      if (!this.isDragged || item.id !== this.dragId) {
        return;
      }
      const x = this.dragX + this.dragOffsetX - this.baseX;
      const y = this.dragY + this.dragOffsetY - this.baseY;
      return {
        transform: `translate(${x}px, ${y}px)`,
      };
    },
    nextMessageIndex(messages, current) {
      return current + 1 >= messages.length ? 0 : current + 1;
    },
    canPut(item, position) {
      if (!this.config.instantCheck) {
        return (
          this.items.filter((item) => item.position === position).length < 8
        );
      } else {
        return item.destination === position;
      }
    },
    showSuccessMessage() {
      if (this.gameFinished) {
        this.assistantMessage = this.config.messageOnComplete;
        return;
      }
      if (
        this.messageOnHalfway &&
        this.itemCount - this.centerItems.length === this.config.halfwayMark
      ) {
        this.assistantMessage = this.config.messageOnHalfway;
        return;
      }
      if (this.centerItems[0].messageOnShow) {
        this.assistantMessage = this.centerItems[0].messageOnShow;
        return;
      }
      const index = this.nextMessageIndex(
        this.config.messageOnShow,
        this.showMessageIndex
      );
      this.assistantMessage = this.config.messageOnShow[index];
    },
    showErrorMessage(item, position) {
      if (!this.config.instantCheck) {
        if (
          this.items.filter((item) => item.position === position).length >= 8
        ) {
          this.assistantMessage = this.config.messageOnOverflow;
        }
        return;
      }
      if (position === "right") {
        const index = this.nextMessageIndex(
          this.config.messageOnLeftError,
          this.leftErrorMessageIndex
        );
        this.assistantMessage = this.config.messageOnLeftError[index];
        this.leftErrorMessageIndex = index;
      }
      if (position === "left") {
        const index = this.nextMessageIndex(
          this.config.messageOnRightError,
          this.rightErrorMessageIndex
        );
        this.assistantMessage = this.config.messageOnRightError[index];
        this.rightErrorMessageIndex = index;
      }
    },
    finishGame() {
      const leftErrors = this.leftItems.filter(
        (item) => item.destination !== "left"
      );
      const rightErrors = this.rightItems.filter(
        (item) => item.destination !== "right"
      );
      const errorCount = leftErrors.length + rightErrors.length;
      let stars = 3;
      if (errorCount > 1 && errorCount <= 3) {
        stars = 2;
      }
      if (errorCount > 3) {
        stars = 1;
      }
      this.$store.dispatch("gameFinished", { stars });
      markFirstTaskComplete()
        .catch((err) => console.error("Request error", err))
        .finally(() => this.$emit("done"));
    },
    onDomDragStart(ev, id) {
      //this.isDragged = true;
      this.mouseDown = true;
      this.dragId = id;
      this.dragX = ev.pageX;
      this.dragY = ev.pageY;
      const el = document.getElementById(`item-${id}`);
      const bb = el.getBoundingClientRect();
      this.baseX = bb.left;
      this.baseY = bb.top;
      this.dragOffsetX = -ev.pageX + bb.left;
      this.dragOffsetY = -ev.pageY + bb.top;
    },
    onDomMouseMove(ev) {
      if (this.mouseDown) {
        this.isDragged = true;
      }
      if (this.isDragged) {
        this.dragX = ev.pageX;
        this.dragY = ev.pageY;
      }
    },
    onDomMouseUp(ev) {
      this.mouseDown = false;
      if (!this.isDragged) {
        this.dragId = null;
        return;
      }
      let target = document.elementFromPoint(ev.pageX, ev.pageY);
      while (!target.classList.contains("column") && target.parentElement) {
        target = target.parentElement;
      }
      if (target.classList.contains("column")) {
        let position;
        if (target.classList.contains("left")) {
          position = "left";
        }
        if (target.classList.contains("right")) {
          position = "right";
        }
        if (this.canPut(this.draggedItem, position)) {
          this.draggedItem.position = position;
          this.showSuccessMessage();
        } else {
          this.showErrorMessage(this.draggedItem, position);
        }
      }
      this.isDragged = false;
      this.dragId = null;
    },
    onDomTouchStart(ev, id) {
      this.onDomDragStart(ev.changedTouches[0], id);
    },
    onDomTouchMove(ev) {
      this.onDomMouseMove(ev.changedTouches[0]);
    },
    onDomTouchEnd(ev) {
      this.onDomMouseUp(ev.changedTouches[0]);
    },
  },
};
</script>
