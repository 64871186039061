import Vue from 'vue';
import App from './App.vue';
/* Promise polyfill for IE11, required for Vuex */
import 'es6-promise/auto';

import store from './store';

Vue.config.productionTip = false;

new Vue({
	store,
	render: h => h(App)
}).$mount('#app');