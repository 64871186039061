export default {
	props: {
		fromEnd: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		currentIndex: 0,
		navigationReversed: false
	}),
	computed: {
		steps(){
			if(!this.source){
				throw new Error("No configuration file defined.")
			}
			let config = require(`@/configs/${this.source}.json`);
			while(config.alias){
				config = require(`@/configs/${config.alias}.json`);
			}
			return config;
		},
		currentStep(){
			let step = this.steps[this.currentIndex];
			if(step.src){
				step = require(`@/configs/${step.src}.json`);
			}
			step.fromEnd = this.navigationReversed;
			return step;
		}
	},
	methods: {
		next(){
			console.log(this.source, this.currentIndex, this.steps.length);
			this.navigationReversed = false;
			if(this.currentIndex === this.steps.length - 1){
				this.$emit('next');
			} else {
				this.currentIndex += 1;
			}
		},
		previous(){
			this.navigationReversed = true;
			if(this.currentIndex === 0){
				this.$emit('prev');
			} else {
				this.currentIndex -= 1;
			}
		}
	},
	watch: {
		source(){
			this.currentIndex = 0;
		}
	},
	created(){
		if(this.fromEnd){
			this.currentIndex = this.steps.length - 1;
		}
	}
};