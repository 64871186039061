<template>
  <component
    :is="currentComponent"
    v-bind="currentStep"
    @next="next"
    @prev="previous"
  />
</template>

<script>
import navigation from "@/mixins/navigation";
import StartScreen from "@/components/start-screen";
import Scanner from "@/components/scanner";
import EndScreen from "@/components/end-screen";
import Cutscene from "@/components/cut-scene";
import GameResult from "@/components/game-result";
import Questionnaire from "@/components/questionnaire";
import Level from "./level";

import TestMedia from "@/components/test-media";

export default {
  mixins: [navigation],
  computed: {
    difficulty() {
      const qs = window.location.search;
      const match = qs.match(/diff=(.+?)(?:&|$)/);
      if (!match) {
        return "normal";
      } else {
        return match[1];
      }
    },
    source() {
      return this.difficulty;
    },
    currentComponent() {
      switch (this.currentStep.type) {
        case "questionnaire":
          return Questionnaire;
        case "start":
          return StartScreen;
        case "scanner":
          return Scanner;
        case "end":
          return EndScreen;
        case "cutscene":
          return Cutscene;
        case "level":
          return Level;
        case "test-media":
          return TestMedia;
        case "game-result":
          return GameResult;
        default:
          throw new Error("Screen type not found");
      }
    },
  },
};
</script>
