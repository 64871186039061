import { render, staticRenderFns } from "./stage-page.vue?vue&type=template&id=7f3bfb63&scoped=true&"
import script from "./stage-page.vue?vue&type=script&lang=js&"
export * from "./stage-page.vue?vue&type=script&lang=js&"
import style0 from "./stage-page.vue?vue&type=style&index=0&id=7f3bfb63&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f3bfb63",
  null
  
)

export default component.exports