<template>
	<h2 class="heading">{{ text }}</h2>
</template>

<style scoped lang="less">
	@breakpoint-l: (min-width: 1400px);
	@breakpoint-m: (max-width: 1399px) and (min-width: 1000px);
	@breakpoint-s: (max-width: 999px) and (min-width: 721px);
	@breakpoint-xs: (max-width: 720px);

	.heading {
		width: 100%;
		color: #fff;
		line-height: 100%;

		margin: 0;

		@media @breakpoint-l {
			font-size: 58px;
			font-weight: 900px;
			margin-bottom: 5%;
			margin-top: 7.8%;
		}

		@media @breakpoint-m {
			font-size: 4vw;
			font-weight: 900px;
			margin-bottom: 5%;
			margin-top: 7.8%;
		}

		@media @breakpoint-s {
			font-size: 40px;
			font-weight: 800px;
			text-align: center;
			margin-bottom: 5.9%;
			margin-top: 10.3%;
		}

		@media @breakpoint-xs {
			font-size: 5.6vw;
			font-weight: 800px;
			text-align: center;
			margin-bottom: 5.9%;
			margin-top: 10.3%;
		}
	}
</style>

<script>
	export default {
		props: {
			text: String
		}
	}
</script>