<template>
  <div
    class="game"
    :class="{ 'is-drawing': isDrawing }"
    @mouseup="cancelLine"
    @mousemove="updateLine($event)"
    @touchmove.prevent="touchUpdateLine($event)"
  >
    <svg
      class="lines"
      :viewBox="`0 0 ${viewportWidth} ${viewportHeight}`"
      preserveAspectRatio="none"
    >
      <line
        v-for="line in lines"
        :key="line.id"
        :x1="line.x1"
        :y1="line.y1"
        :x2="line.x2"
        :y2="line.y2"
        stroke="#fff"
        stroke-dasharray="10,10"
      />
      <polygon
        class="hover-overlay"
        v-for="line in lines"
        :key="`overlay-${line.id}`"
        :class="{ unbreakable: line.isUnbreakable }"
        :points="getOverlayPoints(line)"
        @click="removeLine(line.id)"
      />
    </svg>
    <div class="left">
      <div
        v-for="item in leftItems"
        :key="item.id"
        class="item"
        :class="{ connected: item.connected }"
        @mousedown="addLine($event, item)"
        @touchstart="addTouchLine($event, item)"
        @touchend="touchLockOrCancelLine($event)"
      >
        <div class="img-container">
          <img
            :class="{ 'img-hidden': !item.connected }"
            :src="require('@/' + item.image.replace('%1', 'active'))"
            @dragstart.prevent
          />
          <img
            :class="{ 'img-hidden': item.connected }"
            :src="require('@/' + item.image.replace('%1', 'inactive'))"
            @dragstart.prevent
          />
        </div>
        <div class="text">
          <span>{{ item.text }}</span>
          <div class="anchor"></div>
        </div>
      </div>
    </div>
    <div class="center">
      <div class="target" ref="target" @mouseup.stop="lockLine">
        <p v-show="!finished">База данных ИИ</p>
        <button class="button responsive" v-show="finished" @click="endGame">
          Обновить ИИ
        </button>
      </div>
      <assistant ref="assistant" :message="assistantMessage" />
    </div>
    <div class="right">
      <div
        v-for="item in rightItems"
        :key="item.id"
        class="item"
        :class="{ connected: item.connected }"
        @mousedown="addLine($event, item)"
        @touchstart="addTouchLine($event, item)"
        @touchend="touchLockOrCancelLine($event)"
      >
        <div class="img-container">
          <img
            :class="{ 'img-hidden': !item.connected }"
            :src="require('@/' + item.image.replace('%1', 'active'))"
            @dragstart.prevent
          />
          <img
            :class="{ 'img-hidden': item.connected }"
            :src="require('@/' + item.image.replace('%1', 'inactive'))"
            @dragstart.prevent
          />
        </div>
        <div class="text">
          <span>{{ item.text }}</span>
          <div class="anchor"></div>
        </div>
      </div>
    </div>
    <orientation-plug />
    <navigation-controller />
  </div>
</template>

<style scoped lang="less">
/* autoprefixer grid: autoplace */
@breakpoint-large: (min-width: 1440px);
@breakpoint-xxl: (min-width: 1920px);
@breakpoint-xl: (max-width: 1919px) and (min-width: 1640px);
@breakpoint-l: (max-width: 1639px) and (min-width: 1440px);

@breakpoint-medium: (max-width: 1439px) and (min-width: 1024px);
@breakpoint-m: @breakpoint-medium;

@breakpoint-small: (max-width: 1023px);
@breakpoint-s: (max-width: 1023px) and (min-width: 721px);
@breakpoint-xs: (max-width: 720px);
@breakpoint-mob: (max-height: 370px) and (min-height: 270px);

.game {
  height: 100%;

  display: flex;
  justify-content: space-between;

  cursor: url("~@/assets/cursors/cursor-normal.png"), pointer;

  position: relative;
  user-select: none;

  @media @breakpoint-xxl {
    padding: 0 calc((100% - 1698px) / 2);
  }

  @media @breakpoint-xl {
    padding: 0 111px;
  }

  @media @breakpoint-l {
    padding: 0 40px;
  }

  @media @breakpoint-m {
    padding: 0 24px;
  }

  @media @breakpoint-s {
    padding: 0 calc((100% - 704px) / 2);
  }

  @media @breakpoint-xs {
    padding: 0 8px;
  }
  @media @breakpoint-mob {
    height: 100vh;
  }
}

.game {
  background-repeat: no-repeat;
  background-position: top;

  overflow: hidden;

  font-family: Gilroy;

  @media @breakpoint-large {
    background-size: 2560px 100%;
    background-image: url("~@/assets/minigame-3/bg-large.svg");
  }

  @media @breakpoint-medium {
    background-size: 1439px 100%;
    background-image: url("~@/assets/minigame-3/bg-medium.svg");
  }

  @media @breakpoint-small {
    background-size: 1023px 100%;
    background-image: url("~@/assets/minigame-3/bg-small.svg");
  }
}

.game.is-drawing {
  cursor: url("~@/assets/minigame-3/cursor-drag-node.png") 25 25, pointer;
}

.lines {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;

  .hover-overlay {
    fill: transparent;
    stroke: transparent;

    &:not(.unbreakable):hover {
      cursor: url("~@/assets/cursors/cursor-scissors.png") 49 42, pointer;
    }
  }
}

.game.is-drawing .lines {
  pointer-events: none;
}

.target {
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-image: url("~@/assets/minigame-3/target-inactive.png");

  background-size: 100% 100%;
  color: #fff;

  position: relative;

  @media @breakpoint-large {
    margin-top: 229px;
    height: 252px;
  }

  @media @breakpoint-medium {
    margin-top: 116px;
    height: 178px;
  }

  @media @breakpoint-small {
    margin-top: 8px;
    height: 100px;
  }

  &:before {
    position: absolute;
    content: "";
    background-image: url("~@/assets/minigame-3/hovered.svg");
    background-position: center;
    background-size: 100% 100%;
    transition: opacity 0.5s ease-out;
    opacity: 0;
    @media @breakpoint-large {
      top: -35px;
      left: -35px;
      width: 650px;
      height: 350px;
    }
    @media @breakpoint-medium {
      top: -25px;
      left: -30px;
      width: 450px;
      height: 250px;
    }
    @media @breakpoint-small {
      top: -20px;
      left: 0px;
      width: 240px;
      height: 150px;
    }
  }

  p {
    display: block;
    font-weight: 900;
    line-height: 120%;

    @media @breakpoint-large {
      font-size: 36px;
    }

    @media @breakpoint-medium {
      font-size: 24px;
    }

    @media @breakpoint-small {
      font-size: 14.5px;
    }
  }

  button {
    transform: translateY(10%);
  }
}

.game.is-drawing .target:hover {
  &:before {
    opacity: 1;
  }
}

.assistant {
  margin-top: auto;
  @media @breakpoint-large {
    margin-bottom: 24px;
  }
  @media @breakpoint-medium {
    margin-bottom: 24px;
  }
  @media @breakpoint-small {
    margin-bottom: 8px;
  }
}

.center {
  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 1;

  @media @breakpoint-large {
    width: 553px;
  }

  @media @breakpoint-medium {
    width: 396px;
  }

  @media @breakpoint-small {
    width: 224px;
  }
}

.left,
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  z-index: 1;

  @media @breakpoint-large {
    padding-bottom: 15px;
  }

  @media @breakpoint-medium {
    padding-bottom: 10px;
  }

  @media @breakpoint-small {
    padding-bottom: 4px;
  }
}

.item {
  margin-top: -10%;

  .img-container {
    position: relative;

    @media @breakpoint-large {
      width: 325px;
      height: 171px;
    }

    @media @breakpoint-medium {
      width: 220px;
      height: 115px;
    }

    @media @breakpoint-small {
      width: 130px;
      height: 68.4px;
    }
  }

  .img-hidden {
    opacity: 0;
  }

  img {
    position: absolute;
    display: block;
    opacity: 1;
    transition: opacity 0.25s ease-out;

    @media @breakpoint-large {
      width: 328px;
    }

    @media @breakpoint-medium {
      width: 220px;
    }

    @media @breakpoint-small {
      width: 130px;
    }
  }
  .text {
    color: #fff;
    background: linear-gradient(
        57.16deg,
        rgba(255, 231, 19, 0.029) 44.8%,
        rgba(255, 255, 255, 0) 44.81%,
        rgba(255, 231, 19, 0.086) 84.57%
      ),
      #d8c731;
    border-style: solid;
    border-color: #ffe713;

    text-align: center;
    position: relative;

    user-select: none;
    transition: box-shadow 0.25s ease-out;

    @media @breakpoint-large {
      font-size: 24px;
      line-height: 132.5%;
      width: 328px;
      padding: 20px 16px;
      border-radius: 8px;
      border-width: 2px;
    }

    @media @breakpoint-medium {
      font-size: 16px;
      line-height: 132.5%;
      width: 220px;
      padding: 13px 13px 12px 10px;
      border-radius: 3px;
      border-width: 1.5px;
    }

    @media @breakpoint-small {
      font-size: 9px;
      line-height: 120%;
      width: 130px;
      padding: 4px;
      border-radius: 1px;
      border-width: 1px;
    }

    .anchor {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 50%;
      transition: transform 0.25s ease-out;

      @media @breakpoint-large {
        width: 20px;
        height: 20px;
      }

      @media @breakpoint-medium {
        width: 13.3px;
        height: 13.3px;
      }

      @media @breakpoint-small {
        width: 7.1px;
        height: 7.1px;
      }

      border-radius: 50%;
      border: 1px solid #fff;

      &:after {
        content: "";
        display: block;
        width: 70%;
        height: 70%;
        border-radius: 50%;
        background-color: #fff;
        transition: transform 0.25s ease-out;
      }
    }
  }
  .text:hover {
    box-shadow: 0px 7px 51px rgba(255, 231, 19, 0.4),
      inset 12px 12px 29px #aea025;
  }

  .text:hover .anchor {
    transform: scale(1.2) translate(50%, -50%);
  }
  .text:hover .anchor:after {
    transform: scale(1.2);
  }
}

.left .anchor {
  right: 0;
  transform: translate(50%, -50%);
}

.left .item.connected .text:hover .anchor {
  transform: translate(50%, -50%);
}

.right .anchor {
  left: 0;
  transform: translate(-50%, -50%);
}

.right .item .text:hover .anchor {
  transform: scale(1.2) translate(-50%, -50%);
}

.right .item.connected .text:hover .anchor {
  transform: translate(-50%, -50%);
}

.item.connected .text:hover .anchor:after {
  transform: none;
}

.item.connected .text {
  border-color: #3bde9a;
  background: linear-gradient(
      57.16deg,
      rgba(59, 222, 154, 0.025) 44.8%,
      rgba(255, 255, 255, 0) 44.81%,
      rgba(59, 222, 154, 0.087) 84.57%
    ),
    #37be85;
  transition: border-color 0.5s ease-out, background 0.5s ease-out;
}

.item.connected .text:hover {
  box-shadow: none;
}
</style>

<script>
import Assistant from "@/components/partials/assistant";
import OrientationPlug from "@/components/orientation-plug";
import NavigationController from "@/components/partials/navigation-controller";

export default {
  props: {
    config: Object,
  },
  components: {
    Assistant,
    OrientationPlug,
    NavigationController,
  },
  data: function () {
    return {
      lines: [],
      isDrawing: false,
      viewportWidth: 0,
      viewportHeight: 0,
      items: this.config.items.map((item, i) => ({
        ...item,
        connected: false,
        id: i,
      })),
      showError: false,
      assistantMessage: this.config.messageOnShow,
      successMsgIndex: 0,
      errorMsgIndex: 0,
    };
  },
  computed: {
    leftItems() {
      return this.items.filter((item, i) => i % 2 === 0);
    },
    rightItems() {
      return this.items.filter((item, i) => i % 2 === 1);
    },
    finished() {
      return (
        this.items.filter((item) => item.connected).length >=
        this.items.length / 2
      );
    },
  },
  methods: {
    setViewportDimensions() {
      const bbx = this.$el.getBoundingClientRect();
      this.viewportWidth = bbx.width;
      this.viewportHeight = bbx.height;
    },
    addLine(event, item) {
      if (this.finished) {
        return;
      }
      if (item.connected) {
        return;
      }
      let el = event.target;
      while (el && !el.classList.contains("item")) {
        el = el.parentElement;
      }
      if (!el) {
        return;
      }
      const anchor = el.querySelector(".anchor");
      this.setViewportDimensions();
      this.isDrawing = true;
      const bb = anchor.getBoundingClientRect();
      const id = this.lines.length
        ? this.lines[this.lines.length - 1].id + 1
        : 1;
      this.lines.push({
        id,
        x1: bb.left + bb.width / 2,
        y1: bb.top + bb.height / 2,
        x2: event.pageX,
        y2: event.pageY,
        source: item,
      });
    },
    addTouchLine(event, item) {
      if (event.touches.length > 1) {
        return;
      }
      this.addLine(event.touches[0], item);
    },
    touchLockOrCancelLine(event) {
      if (event.changedTouches.length > 1) {
        return;
      }
      let currentTarget = document.elementFromPoint(
        event.changedTouches[0].pageX,
        event.changedTouches[0].pageY
      );
      const intendedTarget = this.$refs.target;
      let isTarget = currentTarget === intendedTarget;
      while (!isTarget && currentTarget.parentElement) {
        currentTarget = currentTarget.parentElement;
        isTarget = currentTarget === intendedTarget;
      }
      if (isTarget) {
        this.lockLine();
      } else {
        this.cancelLine();
      }
    },
    cancelLine() {
      if (this.isDrawing) {
        this.isDrawing = false;
        this.lines.pop();
      }
    },
    lockLine() {
      if (this.isDrawing) {
        this.isDrawing = false;
        const line = this.lines[this.lines.length - 1];
        const bbx = this.$refs.target.getBoundingClientRect();
        if (line.x1 > this.viewportWidth / 2) {
          line.x2 = bbx.left + bbx.width * 0.964;
          line.y2 = bbx.top + bbx.height * 0.643;
        } else {
          line.x2 = bbx.left + bbx.width * 0.036;
          line.y2 = bbx.top + bbx.height * 0.393;
        }
        line.source.connected = this.validateLine(line);
      }
    },
    touchUpdateLine(event) {
      if (event.touches.length > 1) {
        return;
      }
      this.updateLine(event.touches[0]);
    },
    updateLine(event) {
      if (!this.isDrawing) {
        return;
      }
      const line = this.lines[this.lines.length - 1];
      if (!line) {
        return;
      }
      line.x2 = event.pageX;
      line.y2 = event.pageY;
    },
    nextMessageIndex(messages, current) {
      return current + 1 >= messages.length ? 0 : current + 1;
    },
    validateLine(line) {
      const item = line.source;
      const notConnectedItems = this.items.filter(
        (i) => !i.connected && i.mustBeConnected
      );

      if (notConnectedItems.length === 1 && notConnectedItems[0] === item) {
        this.$refs.assistant.cancelFlash();
        this.assistantMessage = this.config.messageOnComplete;
        return line.id;
      }
      if (this.config.instantCheck) {
        if (item.mustBeConnected) {
          if (item.messageOnSuccess) {
            this.assistantMessage = item.messageOnSuccess;
          } else {
            const i = this.nextMessageIndex(
              this.config.messageOnSuccess,
              this.successMsgIndex
            );
            this.assistantMessage = this.config.messageOnSuccess[i];
            this.successMsgIndex = i;
          }
          this.$refs.assistant.cancelFlash();
          return line.id;
        } else {
          if (item.messageOnError) {
            this.assistantMessage = item.messageOnError;
          } else {
            const i = this.nextMessageIndex(
              this.config.messageOnError,
              this.errorMsgIndex
            );
            this.assistantMessage = this.config.messageOnError[i];
            this.errorMsgIndex = i;
          }
          this.lines = this.lines.filter((l) => l !== line);
          this.$refs.assistant.flashRed();
          return false;
        }
      } else {
        return line.id;
      }
    },
    endGame() {
      const errorCount = this.items.filter(
        (item) =>
          (item.mustBeConnected && !item.connected) ||
          (!item.mustBeConnected && item.connected)
      ).length;
      let stars = 3;
      if (errorCount > 0) {
        stars = 2;
      }
      if (errorCount > 2) {
        stars = 1;
      }
      this.$store.dispatch("gameFinished", { stars });
      this.$emit("done");
    },
    getOverlayPoints(line) {
      const a = (line.y1 - line.y2) / (line.x1 - line.x2);
      const h = 10;
      const L = 20;

      const d = Math.sqrt(a * a + 1);

      const x0 = (line.x1 + line.x2) / 2;
      const y0 = (line.y1 + line.y2) / 2;

      const l =
        Math.sqrt((line.x1 - line.x2) ** 2 + (line.y1 - line.y2) ** 2) / 2 + L;

      const m1 = l / d;
      const m2 = (l * a) / d;
      const m3 = (h * a) / d;
      const m4 = h / d;

      const x1 = -m1 - m3 + x0;
      const y1 = -m2 + m4 + y0;

      const x2 = m1 - m3 + x0;
      const y2 = m2 + m4 + y0;

      const x3 = m1 + m3 + x0;
      const y3 = m2 - m4 + y0;

      const x4 = -m1 + m3 + x0;
      const y4 = -m2 - m4 + y0;

      return `${x1},${y1} ${x2},${y2} ${x3},${y3} ${x4},${y4}`;
    },
    removeLine(id) {
      const item = this.items.find((l) => l.connected === id);
      item.connected = false;
      this.lines = this.lines.filter((l) => l.id !== id);
    },
  },
  mounted() {
    this.setViewportDimensions();
  },
};
</script>
