<template>
  <div class="cutscene" :style="backgroundStyle">
    <div v-if="showHeader" class="header-image">
      <img src="~@/assets/cutscene/header-image.png" />
      <div class="down-arrow">
        <img src="~@/assets/cutscene/arrow.svg" />
        <span>Прокрути&nbsp;вниз</span>
      </div>
    </div>

    <div class="panels">
      <div class="line" v-for="(line, n) in lines" :key="n">
        <template v-for="(panel, i) in line">
          <component
            :is="selectComponent(panel.type)"
            :key="`${n}-${i}`"
            :text="panel.text"
            :src="panel.src"
            :offset="panel.offset"
            :duration="panel.duration"
            :distance="panel.distance"
            :delay="panel.delay"
            :is-mobile="isMobile"
            :scroll-position="scrollPosition"
          />
        </template>
      </div>
    </div>
    <orientation-plug />
    <navigation-controller />
  </div>
</template>

<style scoped lang="less">
	@breakpoint-l: (min-width: 1400px);
	@breakpoint-m: (max-width: 1399px) and (min-width: 1000px);
	@breakpoint-s: (max-width: 999px) and (min-width: 721px);
	@breakpoint-xs: (max-width: 720px);

	.cutscene {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		overflow-x: hidden;

		background-size: auto 100%;
		background-position: top;
	}

	.header-image {
		@media @breakpoint-l {
			width: 1951px;
		}

		@media @breakpoint-m {
			width: 139%;
		}

		@media @breakpoint-s {
			width: 1132px;
		}

		@media @breakpoint-xs {
			width: 157%;
		}

		position: relative;

		& > img {
			width: 100%;
		}

		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 18%;
			width: 35.7%;
			height: 56.5%;
			background-size: 100% 100%;

			//z-index: -1;
		}

		&:before {
			left: 0;
			background-image: url("~@/assets/cutscene/left.png");
		}

		&:after {
			right: 0;
			background-image: url("~@/assets/cutscene/right.png");
		}

		.down-arrow {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 71%;
			height: 7.3%;

			display: flex;
			align-items: center;

			& > img {
				height: 100%;
			}

			span {
				margin-left: 9.8%;

				color: #fff;
				text-transform: uppercase;
				font-weight: 700;
				line-height: 170%;

				@media @breakpoint-l {
					font-size: 14px;
				}

				@media @breakpoint-m {
					font-size: 1vw;
				}

				@media @breakpoint-s {
					font-size: 10px;
				}

				@media @breakpoint-xs {
					font-size: 1.4vw;
				}
			}
		}
	}

	.panels {
		@media @breakpoint-l {
			width: 1280px;
			margin-bottom: 81px;
		}

		@media @breakpoint-m {
			width: 89%;
			margin-bottom: 13.5%
		}

		@media @breakpoint-s {
			width: 680px;
			margin-bottom: 238px;
		}

		@media @breakpoint-xs {
			width: 94%;
			margin-bottom: 33%;
		}

		.line {
			display: flex;
			align-items: flex-start;
			justify-content: center;
		}

		.line + .line {
			@media @breakpoint-l {
				margin-top: 40px;
			}

			@media @breakpoint-m {
				margin-top: 2.8%;
			}

			@media @breakpoint-s {
				margin-top: 23px;
			}

			@media @breakpoint-xs {
				margin-top: 3.2%;
			}
		}
	}
</style>

<script>
import { groupBy, toPairs, throttle } from "lodash";

import PanelHeading from "./cut-scene/panel-heading";
import PanelImage from "./cut-scene/panel-image";
import PanelTablet from "./cut-scene/panel-tablet";

import OrientationPlug from "@/components/orientation-plug.vue";
import NavigationController from "@/components/partials/navigation-controller";

export default {
  props: {
    showHeader: Boolean,
    panelsDesktop: Array,
    panelsMobile: Array,
    background: Object,
  },
  components: {
    OrientationPlug,
    NavigationController,
  },
  data: () => ({
    isMobile: false,
    scrollPosition: 0,
  }),
  computed: {
    panels() {
      return this.isMobile ? this.panelsMobile : this.panelsDesktop;
    },
    lines() {
      const grouped = groupBy(this.panels, "line");
      const paired = toPairs(grouped);
      return paired.sort((a, b) => a[0] - b[0]).map((p) => p[1]);
    },
    images() {
      return this.panels.filter((p) => p.type === "image");
    },
    backgroundStyle() {
      const image = this.isMobile
        ? this.background.imageMobile
        : this.background.imageDesktop;
      return {
        backgroundColor: this.background.color,
        backgroundImage: image
          ? `url("${require("@/assets/cutscene/content/" + image)}")`
          : void 0,
      };
    },
  },
  methods: {
    _setScrollPosition() {
      this.scrollPosition =
        (window.scrollY || window.pageYOffset) + window.innerHeight;
    },
    selectComponent(type) {
      switch (type) {
        case "heading":
          return PanelHeading;
        case "image":
          return PanelImage;
        case "tablet":
          return PanelTablet;
      }
    },
  },
  created() {
    this.setScrollPosition = throttle(this._setScrollPosition, 100);
  },
  mounted() {
    const mql = window.matchMedia("(max-width: 999px)");
    this.isMobile = mql.matches;
    mql.addEventListener &&
      mql.addEventListener("change", (e) => (this.isMobile = e.matches));

    window.addEventListener("scroll", this.setScrollPosition);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setScrollPosition);
  },
};
</script>
