var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"diagram-element",class:{ 'tutorial-visible': _vm.tutorialStep === _vm.item.tutorialStep },style:(_vm.getItemPosition(_vm.item, _vm.source))},[_c('div',{staticClass:"background",style:({
      transform: ("rotate(" + (_vm.source ? _vm.item.source.r : _vm.item.target.r) + "deg)"),
    })},[_c('div',{staticClass:"item placeholder",class:[_vm.item.shape, _vm.item.size]})]),_c('draggable',{staticClass:"dropzone",attrs:{"forceFallback":"true","group":{
      name: _vm.source ? _vm.item.id : 'target',
      pull: _vm.items.length ? [_vm.items[0].id, 'target'] : [],
      put: function (to) { return to.el.children.length === 0; },
    }},on:{"choose":function($event){return _vm.$emit('choose')},"unchoose":function($event){return _vm.$emit('unchoose')}},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},_vm._l((_vm.items),function(i){return _c('div',{key:i.id,staticClass:"item-wrapper"},[_c('div',{style:({
          transform: ("rotate(" + (_vm.source ? i.source.r : i.target.r) + "deg)"),
        })},[_c('div',{staticClass:"item",class:[i.shape, i.color, i.size],on:{"mousedown":function($event){_vm.raise = true},"mouseup":function($event){_vm.raise = false}}},[_c('span',{staticClass:"item-text"},[_vm._v(_vm._s(i.text))])])])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }