export default {
	props: {
		sync: Object
	},
	data(){
		return {
			lastTimeTriggered: this.sync.time,
			lastRefTriggered: null,
			messages: this.stage.messages.filter(msg => msg.gameResults.includes(this.gameResult)),
		}
	},
	methods: {
		emitMessage(message){
			this.lastTimeTriggered = this.sync.time;
			this.lastRefTriggered = message.trigger && message.trigger.ref;
			this.$emit('message', message);
			this.messages = this.messages.filter(msg => msg !== message);
		}
	},
	watch: {
		sync({ time, beamYPosition }){
			if(this.lastRefTriggered){
				const message = this.messages.find((msg) => msg.trigger.trg === this.lastRefTriggered);
				if(!message){
					return;
				}
				const delta = time - this.lastTimeTriggered;
				if(delta >= message.trigger.time){
					this.emitMessage(message);
				}
			} else {
				const messages = this.getTriggeredMessages(beamYPosition);
				messages.forEach(msg => this.emitMessage(msg));
			}
		}
	},
	mounted(){
		const instant = this.messages.filter(msg => !msg.trigger);
		instant.forEach(msg => this.emitMessage(msg));
	}
}