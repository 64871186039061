<template>
  <div class="content">
    <div class="column-left">
      <span class="left-header-1">Искусственный интеллект</span>
      <span class="left-header-2">в образовании</span>
      <div class="certificate-img"></div>
      <button class="button responsive certificate-btn" @click="getCert">
        Получить сертификат
      </button>
      <span class="left-text"
        >Оставь свое имя и электронную почту и мы вышлем тебе сертификат от
        нашей команды</span
      >
    </div>
    <div class="column-right">
      <span class="right-header">Новая игра</span>
      <span v-show="difficulty !== difficulties[2]" class="right-subheader"
        >Было легко? Попробуй другие уровни:</span
      >
      <div
        :class="{
          'buttons-grid': difficulty === difficulties[0],
          'buttons-flex': difficulty === difficulties[1],
          'button-one': difficulty === difficulties[2],
        }"
      >
        <button
          class="new-level-btn"
          v-for="(diff, index) in harderDifficulties"
          :key="index"
          @click="restartGame(diff)"
        >
          {{ classRange[diff] }} класс
        </button>

        <button class="same-level-btn" @click="restartGame(difficulty)">
          Перепройти этот уровень
        </button>
      </div>
    </div>
    <orientation-plug />
    <navigation-controller />
  </div>
</template>


<style scoped lang="less">
/* autoprefixer grid: autoplace */
@breakpoint-xl: (min-width: 1920px);
@breakpoint-l: (max-width: 1919px) and (min-width: 1660px);
@breakpoint-m: (max-width: 1659px) and (min-width: 1440px);
@breakpoint-s: (max-width: 1439px) and (min-width: 1024px);
@breakpoint-xs: (max-width: 1023px) and (min-width: 568px);
@breakpoint-mob: (max-height: 370px) and (min-height: 270px);

.content {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-family: Gilroy;
  cursor: url("~@/assets/cursors/cursor-normal.png") 14 18, pointer;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;

  @media @breakpoint-xl, @breakpoint-l {
    padding: 129px 0px 148px 0px;
    background-image: url("~@/assets/end-screen/bg-large.png");
  }
  @media @breakpoint-m {
    padding: 67px 44px 125px 40px;
    background-image: url("~@/assets/end-screen/bg-large.png");
  }
  @media @breakpoint-s {
    padding: 39px 0px 78px 0px;
    background-image: url("~@/assets/end-screen/bg-medium.png");
  }
  @media @breakpoint-xs {
    padding: 12px 0px 48px 0px;
    background-image: url("~@/assets/end-screen/bg-small.png");
  }
  @media @breakpoint-mob {
    height: 100vh;
  }

  .column-left {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media @breakpoint-xl, @breakpoint-l {
      width: 580px;
      margin-right: 243px;
    }

    @media @breakpoint-m {
      width: 580px;
      margin-right: 103px;
    }
    @media @breakpoint-s {
      width: 460px;
      margin-right: 55px;
    }
    @media @breakpoint-xs {
      width: 261px;
      margin-right: 28px;
    }

    &:before {
      pointer-events: none;
      position: absolute;
      content: "";
      width: 994px;
      height: 566px;
      top: -130px;
      right: -200px;
      background: url("~@/assets/end-screen/glow-green-large.png");
    }

    &:after {
      pointer-events: none;
      position: absolute;
      content: "";
      width: 373px;
      height: 397px;
      top: -125px;
      right: -75px;
      background: url("~@/assets/end-screen/sparks-yellow-large.png");
    }

    .left-header-1,
    .left-header-2 {
      font-size: 56px;
      font-weight: 900;
      line-height: 77px;
      letter-spacing: -0.015em;
      text-align: center;
      color: #ffffff;

      @media @breakpoint-s {
        font-size: 40px;
        line-height: 50px;
      }
      @media @breakpoint-xs {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .left-header-1 {
      color: rgba(59, 222, 154, 0.87);
    }
    .left-header-2 {
      margin-bottom: auto;
    }

    .certificate-img {
      flex-shrink: 0;
      justify-self: flex-end;
      width: 590px;
      height: 300px;
      background-repeat: no-repeat;
      background-position: top;

      @media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
        width: 580px;
        height: 300px;
        background-repeat: no-repeat;
        background-position: top;
        background: url("~@/assets/end-screen/certificate-large.png");
      }
      @media @breakpoint-s {
        width: 460px;
        height: 235px;
        background-repeat: no-repeat;
        background-position: top;
        background: url("~@/assets/end-screen/certificate-medium.png");
      }
      @media @breakpoint-xs {
        width: 261px;
        height: 138px;
        background-repeat: no-repeat;
        background-position: top;
        background: url("~@/assets/end-screen/certificate-small.png");
      }
    }

    .certificate-btn {
      position: relative;
      width: 100%;

      &:before {
        pointer-events: none;
        position: absolute;
        content: "";

        @media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
          top: 46px;
          right: -38px;
          width: 731px;
          height: 80px;
          background: url("~@/assets/end-screen/glow-vilolet-large.png");
        }
        @media @breakpoint-s {
          top: 15px;
          right: -40px;
          width: 534px;
          height: 80px;
          background: url("~@/assets/end-screen/glow-vilolet-medium.png");
        }
        @media @breakpoint-xs {
          top: 15px;
          right: -22px;
          width: 428px;
          height: 48px;
          background: url("~@/assets/end-screen/glow-vilolet-small.png");
        }
      }
      /*
      background: linear-gradient(
          312.11deg,
          rgba(255, 255, 255, 0.054) 40.01%,
          rgba(255, 255, 255, 0) 40.03%,
          rgba(255, 255, 255, 0) 128.29%
        ),
        linear-gradient(0deg, #9f156e, #9f156e), #d91378;
      mix-blend-mode: normal;

      &:hover {
        cursor: inherit;
        background: linear-gradient(
            312.11deg,
            rgba(255, 255, 255, 0.054) 40.01%,
            rgba(255, 255, 255, 0) 40.03%,
            rgba(255, 255, 255, 0) 128.29%
          ),
          linear-gradient(0deg, #951467, #951467), #d91378;
      }*/
    }

    .left-text {
      position: absolute;
      letter-spacing: -0.015em;
      white-space: pre-wrap;
      text-align: center;
      color: #ffffff;
      left: 0;

      @media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
        top: calc(100% + 48px);
      }
      @media @breakpoint-s {
        top: calc(100% + 19px);
      }
      @media @breakpoint-xs {
        top: calc(100% + 11px);
      }
    }

    @media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
      font-size: 24px;
      line-height: 29px;
    }
    @media @breakpoint-s {
      font-size: 19px;
      line-height: 24px;
    }
    @media @breakpoint-xs {
      font-size: 11px;
      line-height: 14px;
    }
  }

  .column-right {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    position: relative;
    background-repeat: no-repeat;

    @media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
      //top: 205px;
      width: 674px;
      height: 444px;
      padding: 50px 52px 56px 52px;
      background: url("~@/assets/end-screen/frame-large.png");
    }
    @media @breakpoint-s {
      //top: 158px;
      width: 461px;
      height: 303px;
      padding: 27px 28px 27px 31px;
      background: url("~@/assets/end-screen/frame-medium.png");
    }
    @media @breakpoint-xs {
      //top: 75px;
      width: 257px;
      height: 181px;
      padding: 12px 14px 14px 14px;
      background: url("~@/assets/end-screen/frame-small.png");
    }

    // &:before {
    //   pointer-events: none;
    //   position: absolute;
    //   content: "";
    //   width: 559px;
    //   height: 476px;
    //   top: -25px;
    //   right: -39px;
    //   background: url("~@/assets/end-screen/glow-yellow-large.png");
    // }

    .right-header {
      font-weight: 900;
      letter-spacing: -0.015em;
      color: #ffffff;

      @media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
        font-size: 48px;
        line-height: 60px;
        margin-bottom: 16px;
      }
      @media @breakpoint-s {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 8px;
      }
      @media @breakpoint-xs {
        font-family: Gilroy;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 4px;
      }
    }

    .right-subheader {
      letter-spacing: -0.015em;
      color: #ffffff;

      @media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 32px;
      }
      @media @breakpoint-s {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }
      @media @breakpoint-xs {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 8px;
      }
    }
  }
  .buttons-grid {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);

    .new-level-btn + .new-level-btn {
      @media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
        margin-left: 20px;
      }

      @media @breakpoint-s {
        margin-left: 20px;
      }
      @media @breakpoint-xs {
        margin-left: 8px;
      }
    }
  }
  .buttons-flex {
    display: flex;
    flex-direction: column;

    button:first-child {
      @media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
        margin-bottom: 32px;
      }
      @media @breakpoint-s {
        margin-bottom: 20px;
      }
      @media @breakpoint-xs {
        margin-bottom: 8px;
      }
    }
  }

  .button-one {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .new-level-btn {
    font-weight: 600;
    letter-spacing: 0em;
    background: linear-gradient(
        312.11deg,
        rgba(255, 255, 255, 0.162) 40.01%,
        rgba(255, 255, 255, 0) 40.03%,
        rgba(255, 255, 255, 0) 128.29%
      ),
      rgba(255, 230, 0, 0.9);
    mix-blend-mode: normal;
    border: 1px solid #ffe713;
    box-shadow: 0px 0px 32px rgba(255, 231, 19, 0.6);
    border-radius: 4px;
    flex-grow: 1;

    @media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
      font-size: 24px;
      line-height: 29px;
      padding: 24px 32px;
      max-height: 79px;
    }
    @media @breakpoint-s {
      font-size: 20px;
      line-height: 25px;
      padding: 17px 22px;
      max-height: 61px;
    }
    @media @breakpoint-xs {
      font-size: 16px;
      line-height: 20px;
      padding: 12px 12px;
      max-height: 46px;
    }

    &:hover {
      cursor: inherit;
      background: linear-gradient(
          312.11deg,
          rgba(255, 255, 255, 0.162) 40.01%,
          rgba(255, 255, 255, 0) 40.03%,
          rgba(255, 255, 255, 0) 128.29%
        ),
        rgba(255, 199, 0, 0.9);
    }
  }

  .same-level-btn {
    grid-column: ~"1 / 3";
    font-weight: 600;

    letter-spacing: 0em;
    color: #ffffff;
    background: linear-gradient(
        312.11deg,
        rgba(255, 255, 255, 0.018) 40.01%,
        rgba(255, 255, 255, 0) 40.03%,
        rgba(255, 255, 255, 0) 128.29%
      ),
      rgba(78, 217, 208, 0.5);
    mix-blend-mode: normal;
    border: 1px solid #9dcaff;
    box-shadow: 0px 0px 32px rgba(19, 241, 255, 0.36);
    border-radius: 4px;
    flex-grow: 1;

    @media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
      font-size: 24px;
      line-height: 29px;
      padding: 24px 32px;
      max-height: 79px;
      margin-top: 10px;
    }
    @media @breakpoint-s {
      font-size: 20px;
      line-height: 25px;
      padding: 17px 22px;
      max-height: 61px;
      margin-top: 10px;
    }
    @media @breakpoint-xs {
      font-family: Gilroy;
      font-size: 16px;
      line-height: 20px;
      padding: 12px 15px;
      max-height: 46px;
      margin-top: 4px;
    }

    &:hover {
      cursor: inherit;
      background: linear-gradient(
          312.11deg,
          rgba(255, 255, 255, 0.036) 40.01%,
          rgba(255, 255, 255, 0) 40.03%,
          rgba(255, 255, 255, 0) 128.29%
        ),
        #40828c;
    }
  }
}
</style>


<script>
import { markFullGameComplete } from "@/api";

import OrientationPlug from "@/components/orientation-plug.vue";
import NavigationController from "@/components/partials/navigation-controller";
export default {
  props: {
    diff: String,
  },
  data() {
    return {
      difficulties: ["1-4", "5-8", "9-11"],
      classRange: { "1-4": "1-4", "5-8": "5-7", "9-11": "8-11" },
    };
  },
  components: { OrientationPlug, NavigationController },
  methods: {
    restartGame(diff) {
      const url = window.location.origin + "?diff=" + diff;
      window.location.replace(url);
    },
    getCert() {
      /* first create a new tab to bypass popup blockers... */
      const newTab = window.open("", "_blank");
      markFullGameComplete(this.trainerName).then(function ({ link }) {
        /* ...and only then point it to a right location */
        newTab.location.href = link;
      });
    },
  },
  computed: {
    difficulty: function () {
      return this.diff;
    },
    harderDifficulties: function () {
      if (this.difficulty === "1-4") {
        return ["5-8", "9-11"];
      }
      if (this.difficulty === "5-8") {
        return ["9-11"];
      }
      return [];
    },
    trainerName() {
      switch (this.diff) {
        case "1-4":
          return "junior";
        case "5-8":
          return "middle";
        case "9-11":
          return "senior";
        default:
          throw new Error("Difficulty not recognised");
      }
    },
  },
};
</script>
