<template>
	<div class="blocks">
		<div 
			class="block numbers"
			:class="{ animated: numbersVisible }"
			ref="numbers"
		>
			<img src="~@/assets/scanner/block-numbers.png" />
			<div class="inner">
				<template v-for="score in scores">
					<template v-if="score.style === 'addition'">
						<img :key="`image-${score.style}`" src="~@/assets/minigame-5/add.png" />
						<span :key="`label-${score.style}`" class="label">Сложение</span>
					</template>
					<template v-if="score.style === 'subtraction'">
						<img :key="`image-${score.style}`" src="~@/assets/minigame-5/subtract.png" />
						<span :key="`label-${score.style}`" class="label">Вычитание</span>
					</template>
					<template v-if="score.style === 'division'">
						<img :key="`image-${score.style}`" src="~@/assets/minigame-5/divide.png" />
						<span :key="`label-${score.style}`" class="label">Деление</span>
					</template>
					<template v-if="score.style === 'multiplication'">
						<img :key="`image-${score.style}`" src="~@/assets/minigame-5/multiply.png" />
						<span :key="`label-${score.style}`" class="label">Умножение</span>
					</template>
					<template v-if="score.style === 'comparison'">
						<img :key="`image-${score.style}`" src="~@/assets/minigame-5/compare.png" />
						<span :key="`label-${score.style}`" class="label">Сравнение</span>
					</template>
					<template v-if="score.style === 'equations'">
						<img :key="`image-${score.style}`" src="~@/assets/minigame-5/equations.png" />
						<span :key="`label-${score.style}`" class="label">Уравнения</span>
					</template>
					<template v-if="score.style === 'fractions'">
						<img :key="`image-${score.style}`" src="~@/assets/minigame-5/fractions.png" />
						<span :key="`label-${score.style}`" class="label">Дроби</span>
					</template>
					<template v-if="score.style === 'functions'">
						<img :key="`image-${score.style}`" src="~@/assets/minigame-5/functions.png" />
						<span :key="`label-${score.style}`" class="label">Функции</span>
					</template>
					<template v-if="score.style === 'equations-square'">
						<img :key="`image-${score.style}`" src="~@/assets/minigame-5/equations-square.png" />
						<span :key="`label-${score.style}`" class="label">Кв. уравнения</span>
					</template>
					<template v-if="score.style === 'systems'">
						<img :key="`image-${score.style}`" src="~@/assets/minigame-5/systems.png" />
						<span :key="`label-${score.style}`" class="label">Системы</span>
					</template>
					<span :key="`value-${score.style}`" class="value">{{ score.value }}</span>
				</template>
			</div>
		</div>
		<div
			class="block graphs"
			:class="{ animated: graphsVisible }"
			ref="graphs"
		>
			<img src="~@/assets/scanner/block-graphs.png" />
			<div class="inner">
				<template v-for="score in graphs">
					<div :key="`block-${score.style}`" class="bar">
						<div
							class="bar-block"
							v-for="n in score.value"
							:key="`bar-${score.style}-${n}`"
						></div>
					</div>
					<template v-if="score.style === 'addition'">
						<span :key="`label-${score.style}`" class="label">Сложение</span>
					</template>
					<template v-if="score.style === 'subtraction'">
						<span :key="`label-${score.style}`" class="label">Вычитание</span>
					</template>
					<template v-if="score.style === 'division'">
						<span :key="`label-${score.style}`" class="label">Деление</span>
					</template>
					<template v-if="score.style === 'multiplication'">
						<span :key="`label-${score.style}`" class="label">Умножение</span>
					</template>
					<template v-if="score.style === 'comparison'">
						<span :key="`label-${score.style}`" class="label">Сравнение</span>
					</template>
					<template v-if="score.style === 'equations'">
						<span :key="`label-${score.style}`" class="label">Уравнения</span>
					</template>
					<template v-if="score.style === 'fractions'">
						<span :key="`label-${score.style}`" class="label">Дроби</span>
					</template>
					<template v-if="score.style === 'functions'">
						<span :key="`label-${score.style}`" class="label">Функции</span>
					</template>
					<template v-if="score.style === 'equations-square'">
						<span :key="`label-${score.style}`" class="label">Кв. уравнения</span>
					</template>
					<template v-if="score.style === 'systems'">
						<span :key="`label-${score.style}`" class="label">Системы</span>
					</template>
				</template>
			</div>
		</div>
		<div
			class="block result"
			:class="{ animated: resultVisible }"
			ref="result"
		>
			<img src="~@/assets/scanner/block-result.png" />
			<div class="inner">
				{{ conclusion.text }}
			</div>
		</div>
	</div>
</template>

<style scoped lang="less">
	/* autoprefixer grid: autoplace */
	@import "scanner/breakpoints";

	.blocks {
		margin-left: auto;
		margin-right: auto;
		
		@media @breakpoint-xxl, @breakpoint-xl {
			width: 574px;
		}

		@media @breakpoint-l {
			width: 506px;
		}

		@media @breakpoint-m {
			width: 343px;
		}

		@media @breakpoint-s, @breakpoint-xs {
			width: 180px;
		}

		/*display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;*/

		.block {
			width: 100%;
			position: relative;

			transform: scale(0.4);
			opacity: 0;
			transition: transform .2s, opacity .2s;

			margin-right: auto;
			margin-top: 5%;

			img {
				display: block;
				width: 100%;
			}

			.inner {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;

				color: #fff;

				& > * {
					display: block;
				}

				.label {
					text-transform: uppercase;
					line-height: 170%;
					font-weight: bold;

					@media @breakpoint-xxl, @breakpoint-xl {
						font-size: 12px;
					}

					@media @breakpoint-l {
						font-size: 11px;
					}

					@media @breakpoint-m {
						font-size: 7px;
					}

					@media @breakpoint-s, @breakpoint-xs {
						font-size: 4px;
					}
				}
			}

			&.animated {
				transform: scale(1);
				opacity: 1;
			}
		}

		.block.numbers {
			.inner {
				padding: 12% 15% 3.5% 10.5%;

				display: grid;
				grid-template-columns: repeat(4, 1fr);
				grid-template-rows: repeat(3, auto);
				grid-auto-flow: column;

				& > * {
					justify-self: center;
				}

				img {
					display: block;

					@media @breakpoint-xxl, @breakpoint-xl {
						width: 83px;
					}

					@media @breakpoint-l {
						width: 73px;
					}

					@media @breakpoint-m {
						width: 50px;
					}

					@media @breakpoint-s, @breakpoint-xs {
						width: 26px;
					}
				}

				.value {
					font-weight: 900;
					line-height: 140%;

					@media @breakpoint-xxl, @breakpoint-xl {
						font-size: 48px;
					}

					@media @breakpoint-l {
						font-size: 42px;
					}

					@media @breakpoint-m {
						font-size: 29px;
					}

					@media @breakpoint-s, @breakpoint-xs {
						font-size: 15px;
					}
				}
			}
		}

		.block.graphs {
			.inner {
				padding: 11.5% 16.4% 3% 15.3%;

				display: grid;
				grid-template-columns: repeat(4, 1fr);
				grid-template-rows: repeat(2, auto);
				grid-auto-flow: column;

				& > * {
					justify-self: center;
				}

				.bar {
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: center;
					position: relative;

					margin: 0 auto;
					@media @breakpoint-xxl, @breakpoint-xl {
						width: 66px;
						height: 146px;
						padding: 12px;
					}
					@media @breakpoint-l {
						width: 46px;
						height: 101px;
						padding: 8px;
					}
					@media @breakpoint-m {
						width: 35px;
						height: 77px;
						padding: 6px;
					}
					@media @breakpoint-s {
						width: 21px;
						height: 43px;
						padding: 4px;
					}

					&:before {
						content: "";
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;

						@media @breakpoint-large {
							border-radius: 5px;
							filter: blur(4px);
						}
						@media @breakpoint-medium {
							border-radius: 3.8px;
							filter: blur(3px);
						}
						@media @breakpoint-small {
							border-radius: 2px;
							filter: blur(1.7px);
						}
					}

					&:nth-child(1):before {
						background: linear-gradient(
							194.83deg,
							rgba(255, 107, 214, 0) 21.03%,
							rgba(255, 107, 214, 0.3) 94.32%
						);
					}

					&:nth-child(3):before {
						background: linear-gradient(
							194.83deg,
							rgba(232, 249, 100, 0) 21.03%,
							rgba(232, 249, 100, 0.37) 94.32%
						);
					}

					&:nth-child(5):before {
						background: linear-gradient(
							194.83deg,
							rgba(59, 222, 154, 0) 21.03%,
							rgba(59, 222, 154, 0.4) 94.32%
						);
					}

					&:nth-child(7):before {
						background: linear-gradient(
							194.83deg,
							rgba(255, 107, 169, 0) 21.03%,
							rgba(255, 107, 160, 0.3) 94.32%
						);
					}

					.bar-block {
						border-style: solid;

						@media @breakpoint-large {
							width: 29px;
							height: 15px;
							border-radius: 4px;
							border-width: 1px;
							margin-top: 2px;
						}
						@media @breakpoint-medium {
							width: 22px;
							height: 11px;
							border-radius: 3px;
							border-width: 0.8px;
							margin-top: 1.5px;
						}
						@media @breakpoint-small {
							width: 12px;
							height: 6px;
							border-radius: 1.7px;
							border-width: 0.4px;
							margin-top: 0.85px;
						}
					}

					&:nth-child(1) .bar-block {
						background: linear-gradient(
							195.41deg,
							rgba(255, 107, 214, 0.81) -2.31%,
							rgba(251, 103, 227, 0.81) 18.8%,
							rgba(241, 91, 235, 0.81) 45.05%,
							rgba(224, 71, 209, 0.81) 74.02%,
							rgba(201, 44, 185, 0.81) 104.96%,
							rgba(171, 9, 155, 0.81) 137.14%,
							rgba(163, 0, 137, 0.81) 144.82%
						);
						border-color: #f762e7;

						@media @breakpoint-large {
							box-shadow: -3px -3px 4px 1px rgba(184, 14, 169, 0.13);
						}
						@media @breakpoint-medium {
							box-shadow: -2.26244px -2.26244px 3.01659px 0.754148px rgba(184, 14, 169, 0.13);
						}
						@media @breakpoint-small {
							box-shadow: -1.27289px -1.27289px 1.69719px 0.424298px rgba(184, 14, 169, 0.13);
						}
					}

					&:nth-child(3) .bar-block {
						background: linear-gradient(
							195.39deg,
							rgba(208, 255, 107, 0.8) -88.16%,
							rgba(230, 251, 103, 0.8) -44.92%,
							rgba(238, 241, 91, 0.8) 8.84%,
							rgba(224, 190, 71, 0.8) 68.17%,
							rgba(201, 185, 44, 0.8) 131.54%,
							rgba(171, 135, 9, 0.8) 197.43%,
							rgba(163, 127, 0, 0.8) 213.16%
						);
						border-color: #e8f964;

						@media @breakpoint-large {
							box-shadow: -3px -3px 4px 1px rgba(211, 193, 23, 0.13);
						}
						@media @breakpoint-medium {
							box-shadow: -2.26244px -2.26244px 3.01659px 0.754148px rgba(211, 193, 23, 0.13);
						}
						@media @breakpoint-small {
							box-shadow: -1.27289px -1.27289px 1.69719px 0.424298px rgba(211, 193, 23, 0.13);
						}
					}

					&:nth-child(5) .bar-block {
						background: linear-gradient(
							195.75deg,
							rgba(160, 255, 107, 0.9) -45.82%,
							rgba(103, 251, 144, 0.9) -20.06%,
							rgba(91, 241, 178, 0.9) 11.96%,
							rgba(71, 224, 224, 0.9) 47.31%,
							rgba(44, 173, 201, 0.9) 85.06%,
							rgba(9, 152, 171, 0.9) 124.32%,
							rgba(0, 163, 153, 0.9) 133.69%
						);
						border-color: #3dd7a8;

						@media @breakpoint-large {
							box-shadow: 0px 1.86451px 1.86451px rgba(20, 104, 69, 0.3);
						}
						@media @breakpoint-medium {
							box-shadow: 0px 1.40611px 1.40611px rgba(20, 104, 69, 0.3);
						}
						@media @breakpoint-small {
							box-shadow: 0px 0.791107px 0.791107px rgba(20, 104, 69, 0.3);
						}
					}

					&:nth-child(7) .bar-block {
						background: linear-gradient(
							197.99deg,
							rgba(252, 107, 255, 0.9) -103.51%,
							rgba(195, 103, 251, 0.9) -67.87%,
							rgba(241, 91, 181, 0.9) -23.57%,
							rgba(224, 71, 218, 0.9) 25.33%,
							rgba(201, 44, 110, 0.9) 77.55%,
							rgba(171, 9, 87, 0.9) 131.87%,
							rgba(163, 0, 10, 0.9) 144.83%
						);
						border-color: #d655a2;

						@media @breakpoint-large {
							box-shadow: 0px 1.39838px 4.19514px rgba(102, 17, 24, 0.3);
						}
						@media @breakpoint-medium {
							box-shadow: 0px 1.06398px 3.19195px rgba(102, 17, 24, 0.3);
						}
						@media @breakpoint-small {
							box-shadow: 0px 0.59333px 1.77999px rgba(102, 17, 24, 0.3);
						}
					}
				}
			}
		}

		.block.result {
			width: 96%;

			.inner {
				padding: 16.3% 11.3% 10% 11.7%;

				display: flex;
				align-items: center;
				justify-content: center;

				text-align: center;
				line-height: 132.5%;

				@media @breakpoint-xxl, @breakpoint-xl {
					font-size: 29px;
				}

				@media @breakpoint-l {
					font-size: 25px;
				}

				@media @breakpoint-m {
					font-size: 17px;
				}

				@media @breakpoint-s, @breakpoint-xs {
					font-size: 9px;
				}
			}
		}
	}
</style>

<script>
	import sync from "./sync";

	export default {
		props: {
			stage: Object,
			gameResult: String
		},
		mixins: [sync],
		data: () => ({
			numbersVisible: false,
			graphsVisible: false,
			resultVisible: false
		}),
		computed: {
			scores(){
				return this.stage.scores.filter(sc => sc.gameResults.includes(this.gameResult));
			},
			graphs(){
				return this.stage.graphs.filter(gr => gr.gameResults.includes(this.gameResult));
			},
			conclusion(){
				return this.stage.conclusions.find(cc => cc.gameResults.includes(this.gameResult));
			}
		},
		methods: {
			getTriggeredMessages(beamYPosition){
				const numbers = this.$refs.numbers;
				const graphs = this.$refs.graphs;
				const result = this.$refs.result;
				if(!numbers || !graphs || !result){
					return [];
				}
				const nbbx = numbers.getBoundingClientRect();
				const gbbx = graphs.getBoundingClientRect();
				const rbbx = result.getBoundingClientRect();

				if(nbbx.top === 0 && gbbx.top === 0 && rbbx.top === 0){
					return [];
				}

				let messages = [];

				/* adjusted for scale */
				if(beamYPosition >= nbbx.top - 0.3 * nbbx.height){
					this.numbersVisible = true;
					messages = [...messages, ...this.messages.filter(msg => msg.trigger && msg.trigger.block === 'numbers')];
				}
				if(beamYPosition >= gbbx.top - 0.3 * gbbx.height){
					this.graphsVisible = true;
					messages = [...messages, ...this.messages.filter(msg => msg.trigger && msg.trigger.block === 'graphs')];
				}
				if(beamYPosition >= rbbx.top - 0.3 * rbbx.height){
					this.resultVisible = true;
					messages = [...messages, ...this.messages.filter(msg => msg.trigger && msg.trigger.block === 'result')];
				}
				return messages;
			}
		}
	}
</script>