import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
	totalStars: 0,
	recentStars: 0,
	nextBtnValue: "pythagoras",
	sessionId: 0
};

const getters = {
	gameResult(state){
		if(state.totalStars >= 14){
			return 'good';
		}
		if(state.totalStars <= 9){
			return 'bad';
		}
		return 'ok';
	}	
};

const mutations = {
	addToTotal(state, num){
		state.totalStars += num;
	},
	setRecent(state, num){
		state.recentStars = num;
	},
	setNextBtnValue (state, val){
		state.nextBtnValue = val;
	},
	setSessionId(state, val){
		state.sessionId = val;
	}
};

const actions = {
	gameFinished({ commit }, { stars }){
		commit('addToTotal', stars);
		commit('setRecent', stars);
	},
	gameReset({ state, commit }){
		commit('addToTotal', -state.recentStars);
	},
	newNextBtnValue({ commit }, { value }){
		commit('setNextBtnValue', value);
	},
	setSessionId({ commit }, { sessionId }){
		commit('setSessionId', sessionId);
	}
};

export default new Vuex.Store({
	state,
	getters,
	mutations,
	actions
});