<template>
	<div class="navigation-controller">
		<button class="button" @click="$parent.$emit('prev')">Назад</button>
		<button class="button" @click="$parent.$emit('next')">Далее</button>

		<div class="score-controller">
			<button class="button" @click="setScore(5)">1</button>
			<button class="button" @click="setScore(10)">2</button>
			<button class="button" @click="setScore(15)">3</button>
		</div>
	</div>
</template>

<style scoped lang="less">
	@import (reference) "buttons";
	.button {
		.button-small();
		padding: 4px 8px;
	}

	.button + .button {
		margin-left: 10px;
	}

	.navigation-controller {
		display:none;
		position: fixed;
		left: 0;
		top: 0;
		padding: 10px;
		border: 1px solid #fff;
		z-index: 999;
	}

	.score-controller {
		position: fixed;
		right: 0;
		bottom: 0;
		padding: 10px;
		border: 1px solid #fff;
		z-index: 999;
	}
</style>

<script>
	export default {
		methods: {
			setScore(val){
				this.$store.commit('addToTotal', -this.$store.state.totalStars);
				this.$store.commit('addToTotal', val);
				this.$store.commit('setRecent', val / 5);
			}
		}
	}
</script>
