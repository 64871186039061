<template>
  <div class="stage-loader">
    <div class="frame">
      <img class="spinner" src="~@/assets/stage-loader/spinner.svg" />
      <h1 class="title">Загружаю данные...</h1>
    </div>
    <orientation-plug />
    <navigation-controller />
  </div>
</template>

<style scoped lang="less">
@breakpoint-l: (min-width: 1260px);
@breakpoint-m: (max-width: 1259px) and (min-width: 960px);
@breakpoint-s: (max-width: 959px);
@breakpoint-mob: (max-height: 370px) and (min-height: 270px);

.stage-loader {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  background-position: top;

  @media @breakpoint-l {
    background-size: 2564px 100%;
    background-image: url("~@/assets/stage-loader/bg-large.svg");
  }

  @media @breakpoint-m {
    background-size: 1260px 100%;
    background-image: url("~@/assets/stage-loader/bg-medium.svg");
  }

  @media @breakpoint-s {
    background-size: 999px 100%;
    background-image: url("~@/assets/stage-loader/bg-small.svg");
  }

  @media @breakpoint-mob {
    background-size: 999px 100%;
    background-image: url("~@/assets/stage-loader/bg-small.svg");
    height: 100vh;
  }
}

.frame {
  position: relative;

  @media @breakpoint-l {
    width: 693px;
    height: 513px;
  }

  @media @breakpoint-m {
    width: 589px;
    height: 402px;
  }

  @media @breakpoint-s {
    width: 407px;
    height: 276px;
  }
}

@keyframes spinner-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: block;

  animation-name: spinner-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(16, jump-start);

  @media @breakpoint-l {
    width: 227px;
    margin: 132px auto 0;
  }

  @media @breakpoint-m {
    width: 160px;
    margin: 122px auto 0;
  }

  @media @breakpoint-s {
    width: 100px;
    margin: 87px auto 0;
  }
}

.title {
  width: 100%;
  text-align: center;
  color: #fff;
  line-height: 100%;
  font-weight: 800;

  margin-top: 24px;

  @media @breakpoint-l {
    font-weight: 900;
    font-size: 58px;
  }

  @media @breakpoint-m {
    font-size: 40px;
  }

  @media @breakpoint-s {
    font-size: 30px;
    margin-top: 16px;
  }
}
</style>

<script>
import OrientationPlug from "@/components/orientation-plug.vue";
import NavigationController from "@/components/partials/navigation-controller";

export default {
  components: {
    OrientationPlug,
    NavigationController,
  },
  mounted() {
    setTimeout(() => this.$emit("next"), 5000);
  },
};
</script>