<template>
	<div class="dialog">
		<div
			class="line"
			v-for="(line, i) in lines"
			:key="i"
			:class="{ left: line.align === 'left', right: line.align === 'right' }"
		>
			<div class="avatar">
				<img :src="require(`@/assets/dialog/${line.avatar}`)" />
			</div>
			<p class="speaker" v-if="line.speaker">{{ line.speaker }}</p>
			<p class="text" v-html="line.text"></p>
		</div>
		<div
			class="buttons"
			:class="{
				left: nextBtn.align === 'left',
				right: nextBtn.align === 'right',
			}"
		>
			<button
				class="button"
				v-for="(btn, i) in nextBtn.buttons"
				:key="`btn-${i}`"
				@click="click(btn.value)"
			>
				{{ btn.text }}
			</button>
			<img class="avatar" :src="require(`@/assets/dialog/${nextBtn.avatar}`)" />
		</div>
		<orientation-plug />
		<navigation-controller />
	</div>
</template>

<style scoped lang="less">
@import "buttons";

@breakpoint-large: (min-width: 920px);
@breakpoint-l: (max-width: 1319px) and (min-width: 920px);
@breakpoint-xl: (min-width: 1320px);

@breakpoint-small: (max-width: 919px);
@breakpoint-s: @breakpoint-small;
@breakpoint-mob: (max-height: 370px) and (min-height: 270px);

.dialog {
	position: relative;

	overflow-x: hidden;

	background-position: top;
	background-repeat: no-repeat;

	@media @breakpoint-large {
		background-image: url("~@/assets/dialog/bg-large.svg");
		background-size: 2560px 100%;
	}
	@media @breakpoint-small {
		background-image: url("~@/assets/dialog/bg-small.svg");
		background-size: 1024px 100%;
	}

	@media (min-width: 852px) {
		padding: 36px calc((100% - 820px) / 2) 28px;
	}
	@media (max-width: 851px) {
		padding: 36px 16px 28px;
	}

	@media @breakpoint-mob {
		min-height: 100vh;
	}

	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;

		background-repeat: no-repeat;
		@media @breakpoint-large {
			width: 465px;
		}
		@media @breakpoint-small {
			width: 303px;
		}
	}

	&:before {
		@media @breakpoint-large {
			background-image: url("~@/assets/dialog/grid-left-large.svg");
		}
		@media @breakpoint-small {
			background-image: url("~@/assets/dialog/grid-left-small.svg");
		}

		background-position: left;

		@media @breakpoint-xl {
			left: calc(50% - 960px);
		}

		@media @breakpoint-l {
			left: -300px;
		}

		@media @breakpoint-s {
			left: -195px;
		}
	}

	&:after {
		@media @breakpoint-large {
			background-image: url("~@/assets/dialog/grid-right-large.svg");
		}
		@media @breakpoint-small {
			background-image: url("~@/assets/dialog/grid-right-small.svg");
		}

		background-position: right;

		@media @breakpoint-xl {
			right: calc(50% - 960px);
		}

		@media @breakpoint-l {
			right: -300px;
		}

		@media @breakpoint-s {
			right: -195px;
		}
	}
}

.line,
.buttons {
	margin-left: auto;
	margin-right: auto;
}

.line {
	color: #fff;
	position: relative;
	max-width: 100%;

	.avatar {
		position: absolute;
		top: 0;

		@media @breakpoint-large {
			width: 88px;
			height: 88px;
		}
		@media @breakpoint-small {
			width: 80px;
			height: 80px;
		}

		img {
			width: 100%;
		}
	}

	.text {
		margin: 0;
		border: 3px solid #ffffff;
		border-radius: 5px;

		white-space: pre-wrap;

		@media @breakpoint-large {
			padding: 20px 30px;
			font-size: 20px;
			line-height: 170%;
		}

		@media @breakpoint-small {
			padding: 15px 20px;
			font-size: 16px;
			line-height: 160%;
		}
	}

	.speaker {
		margin: 0;
		height: 42px;
		background-repeat: no-repeat;
		margin-bottom: 10px;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 0.04em;
		text-align: center;

		line-height: 170%;

		@media @breakpoint-large {
			font-size: 16px;
		}
		@media @breakpoint-small {
			font-size: 14px;
		}
	}

	&.left {
		@media @breakpoint-large {
			padding-left: 104px;
			margin-right: 264px;
		}
		@media @breakpoint-small {
			padding-left: 96px;
		}

		.avatar {
			left: 0;
		}

		.text {
			background: linear-gradient(
					31.92deg,
					rgba(255, 255, 255, 0.0126) 25.95%,
					rgba(255, 255, 255, 0) 25.95%,
					rgba(255, 255, 255, 0) 50.15%
				),
				rgba(149, 249, 255, 0.35);
			box-shadow: 0px 7px 51px rgba(82, 175, 175, 0.58);
		}

		.speaker {
			width: 280px;
			padding: 8px 3px 7px 12px;
			background-image: url("~@/assets/dialog/speaker-left-bg.png");
			box-shadow: 0px 7px 51px #6f52af;
		}
	}

	&.right {
		@media @breakpoint-large {
			padding-right: 104px;
			margin-left: 264px;
		}
		@media @breakpoint-small {
			padding-right: 96px;
		}

		.avatar {
			right: 0;
		}

		.text {
			background: linear-gradient(
					31.92deg,
					rgba(255, 255, 255, 0.0126) 25.95%,
					rgba(255, 255, 255, 0) 25.95%,
					rgba(255, 255, 255, 0) 50.15%
				),
				rgba(210, 178, 246, 0.35);
			box-shadow: 0px 7px 51px #6f52af;
		}

		.speaker {
			width: 124px;
			padding: 7px 12px 8px 12px;
			margin-left: auto;
			background-image: url("~@/assets/dialog/speaker-right-bg.png");
			box-shadow: 0px 7px 51px #6f52af;
		}
	}
}

.line + .line {
	margin-top: 40px;
}

.buttons {
	display: flex;
	align-items: center;
	margin-top: 40px;
	width: 100%;

	@media @breakpoint-large {
		height: 88px;
	}
	@media @breakpoint-small {
		height: 80px;
	}

	.button {
		flex-grow: 1;

		@media @breakpoint-large {
			.button-large();
		}

		@media @breakpoint-small {
			.button-medium();
		}
	}

	.button + .button {
		margin-left: 20px;
	}

	.avatar {
		height: 100%;
		display: block;
	}

	&.right {
		.avatar {
			margin-left: 16px;
			order: 1;
		}
	}

	&.left {
		.avatar {
			margin-right: 16px;
			order: -1;
		}
	}
}
</style>

<script>
import OrientationPlug from "@/components/orientation-plug.vue";
import NavigationController from "@/components/partials/navigation-controller";
export default {
	props: {
		lines: Array,
		nextBtn: Object,
	},
	components: {
		OrientationPlug,
		NavigationController,
	},
	methods: {
		click: function (value) {
			if (value) {
				this.$store.dispatch("newNextBtnValue", { value });
			}
			this.$emit("next", value);
		},
	},
};
</script>