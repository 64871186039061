<template>
	<div
		class="game"
		:class="{ 'is-drawing': isDrawing }"
		@mouseup="cancelLine"
		@mousemove="updateLine($event)"
		@touchmove.prevent="touchUpdateLine($event)"
	>
		<svg
			class="lines"
			:viewBox="`0 0 ${viewportWidth} ${viewportHeight}`"
			preserveAspectRatio="none"
		>
			<line
				v-for="line in lines"
				:key="line.id"
				:x1="line.x1"
				:y1="line.y1"
				:x2="line.x2"
				:y2="line.y2"
				stroke="#3BDE9A"
				stroke-dasharray="10,10"
			/>
			<polygon
				class="hover-overlay"
				v-for="line in lines"
				:key="`overlay-${line.id}`"
				:class="{ unbreakable: line.isUnbreakable }"
				:points="getOverlayPoints(line)"
				@click="removeLine(line.id)"
			/>
		</svg>
		<div class="columns">
			<div class="left">
				<div
					class="block"
					v-for="item in leftItems"
					:key="item.id"
					:id="item.id"
					:class="{ connected: item.connected }"
					@mousedown="addLine($event, item)"
					@mouseup.stop="lockLine(item)"
					@touchstart="addTouchLine($event, item)"
					@touchend="touchLockOrCancelLine($event)"
				>
					<img
						@dragstart.prevent
						:src="require(`@/assets/minigame-5/${config.icons.subtract}`)"
					/>
					<img
						@dragstart.prevent
						:src="require(`@/assets/minigame-5/${config.icons.add}`)"
					/>
					<img
						@dragstart.prevent
						:src="require(`@/assets/minigame-5/${config.icons.multiply}`)"
					/>
					<img
						@dragstart.prevent
						:src="require(`@/assets/minigame-5/${config.icons.divide}`)"
					/>
					<span class="label">{{ config.labels.subtract }}</span>
					<span class="label">{{ config.labels.add }}</span>
					<span class="label">{{ config.labels.multiply }}</span>
					<span class="label">{{ config.labels.divide }}</span>
					<span class="value">{{ item.subtract }}</span>
					<span class="value">{{ item.add }}</span>
					<span class="value">{{ item.multiply }}</span>
					<span class="value">{{ item.divide }}</span>
				</div>
			</div>
			<div class="center">
				<div
					class="block"
					v-for="item in centerItems"
					:key="item.id"
					:id="item.id"
					:class="{ connected: item.connectedLeft || item.connectedRight }"
					@mousedown="addLine($event, item)"
					@mouseup.stop="lockLine(item)"
					@touchstart="addTouchLine($event, item)"
					@touchend="touchLockOrCancelLine($event)"
				>
					<div class="bar subtract">
						<div
							class="bar-block"
							v-for="n in item.subtract"
							:key="`bar-subtract-${item.itemId}-${n}`"
						></div>
					</div>
					<div class="bar add">
						<div
							class="bar-block"
							v-for="n in item.add"
							:key="`bar-add-${item.itemId}-${n}`"
						></div>
					</div>
					<div class="bar multiply">
						<div
							class="bar-block"
							v-for="n in item.multiply"
							:key="`bar-multiply-${item.itemId}-${n}`"
						></div>
					</div>
					<div class="bar divide">
						<div
							class="bar-block"
							v-for="n in item.divide"
							:key="`bar-divide-${item.itemId}-${n}`"
						></div>
					</div>
					<span class="label">{{ config.labels.subtract }}</span>
					<span class="label">{{ config.labels.add }}</span>
					<span class="label">{{ config.labels.multiply }}</span>
					<span class="label">{{ config.labels.divide }}</span>
				</div>
			</div>
			<div class="right">
				<div
					class="block"
					v-for="item in rightItems"
					:key="item.id"
					:id="item.id"
					:class="{ connected: item.connected }"
					@mousedown="addLine($event, item)"
					@mouseup.stop="lockLine(item)"
					@touchstart="addTouchLine($event, item)"
					@touchend="touchLockOrCancelLine($event)"
				>
					{{ item.conclusion }}
				</div>
			</div>
		</div>
		<div class="assistant-btn-container">
			<assistant ref="assistant" horizontal :message="assistantMessage" />
			<button
				class="button responsive end-game"
				:class="{ hidden: !complete }"
				@click="endGame"
			>
				Запустить алгоритм
			</button>
		</div>
		<orientation-plug />
		<navigation-controller />
	</div>
</template>

<style scoped lang="less">
/* autoprefixer grid: autoplace */
@breakpoint-large: (min-width: 1440px);
@breakpoint-xl: (min-width: 1921px);
@breakpoint-l: (max-width: 1920px) and (min-width: 1440px);

@breakpoint-medium: (max-width: 1439px) and (min-width: 1024px);
@breakpoint-m: @breakpoint-medium;

@breakpoint-small: (max-width: 1023px);
@breakpoint-s: (max-width: 1023px) and (min-width: 721px);
@breakpoint-xs: (max-width: 720px);
@breakpoint-mob-370: (max-height: 370px) and (min-height: 318px);
@breakpoint-mob-318: (max-height: 318px) and (min-height: 270px);

.game {
	height: 100%;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	justify-content: stretch;
	position: relative;

	font-family: Gilroy;

	background-repeat: no-repeat;
	background-position: top;
	user-select: none;

	@media @breakpoint-large {
		background-image: url("~@/assets/minigame-5/bg-large.svg");
		background-size: 2560px 100%;
	}

	@media @breakpoint-medium {
		background-image: url("~@/assets/minigame-5/bg-medium.svg");
		background-size: 1439px 100%;
	}

	@media @breakpoint-small {
		background-image: url("~@/assets/minigame-5/bg-small.svg");
		background-size: 1023px 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}
	@media @breakpoint-mob-370, @breakpoint-mob-318 {
		background-image: url("~@/assets/minigame-5/bg-small.svg");
		background-size: 1023px 100%;
		overflow-x: hidden;
		overflow-y: auto;
		height: 100vh;
	}
}

.game.is-drawing {
	cursor: url("~@/assets/minigame-5/cursor-drag-node.png") 25 25, pointer;
}

.lines {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 0;

	.hover-overlay {
		fill: transparent;
		stroke: transparent;

		&:not(.unbreakable):hover {
			cursor: url("~@/assets/cursors/cursor-scissors.png") 49 42, pointer;
		}
	}
}

.game.is-drawing .lines {
	pointer-events: none;
}

.columns {
	display: flex;
	justify-content: space-between;
	flex-shrink: 0;

	@media @breakpoint-xl {
		padding: 34px calc((100% - 1800px) / 2) 0px calc((100% - 1800px) / 2);
	}

	@media @breakpoint-l {
		padding: 34px 60px 0px 60px;
	}

	@media @breakpoint-m {
		padding: 25px 25px 0px 25px;
	}

	@media @breakpoint-s {
		padding: 8px calc((100% - 704px) / 2) 0px calc((100% - 704px) / 2);
	}

	@media @breakpoint-xs {
		padding: 8px 8px 0px 8px;
	}

	.block + .block {
		margin-top: 10px;
	}
}

.left,
.center,
.right {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&:before,
	&:after {
		content: "";
		position: absolute;
		background-repeat: no-repeat;
		background-size: 100%;
		pointer-events: none;

		@media @breakpoint-large {
			width: 361px;
			height: 313px;
		}
		@media @breakpoint-medium {
			width: 248px;
			height: 215px;
		}
		@media @breakpoint-small {
			width: 142px;
			height: 123px;
		}
	}
}

.left:before {
	@media @breakpoint-large {
		left: 186px;
		top: 2px;
	}
	@media @breakpoint-medium {
		left: 150px;
		bottom: 280px;
	}
	@media @breakpoint-small {
		left: 41px;
		bottom: 167px;
	}
	background-image: url("~@/assets/minigame-5/glitter-green.png");
}

.center:before {
	@media @breakpoint-large {
		left: 188px;
		top: 500px;
	}
	@media @breakpoint-medium {
		left: 122px;
		top: 373px;
	}
	@media @breakpoint-small {
		left: -113px;
		bottom: -67px;
	}
	background-image: url("~@/assets/minigame-5/glitter-green.png");
}

.right:before {
	@media @breakpoint-large {
		top: 115px;
		right: 232px;
	}
	@media @breakpoint-medium {
		top: 88px;
		right: 162px;
	}
	@media @breakpoint-small {
		bottom: -50px;
		right: 78px;
	}
	background-image: url("~@/assets/minigame-5/glitter-yellow.png");
}

.right:after {
	@media @breakpoint-large {
		top: 627px;
		left: 191px;
	}
	@media @breakpoint-medium {
		top: 440px;
		left: 109px;
	}
	@media @breakpoint-small {
		display: none;
	}
	background-image: url("~@/assets/minigame-5/glitter-yellow.png");
}

.block {
	color: #fff;
	position: relative;
	flex-shrink: 0;

	background-repeat: no-repeat;
	background-size: 400% 100%;
	background-position: 100%;

	&.connected {
		background-position: 33.3333%;
	}

	span {
		text-align: center;
		text-transform: uppercase;
	}

	.label {
		font-weight: 700;
		line-height: 170%;

		@media @breakpoint-large {
			font-size: 9px;
		}

		@media @breakpoint-medium {
			font-size: 8px;
		}

		@media @breakpoint-small {
			font-size: 4px;
		}
	}
}

.block:before {
	position: absolute;
	content: "";
	background-position: center;
	background-size: 100% 100%;
	transition: opacity 0.25s ease-out;
	opacity: 0;

	@media @breakpoint-large {
		top: -60px;
		left: -20px;
		width: 440px;
		height: 315px;
	}
	@media @breakpoint-medium {
		top: -20px;
		left: -5px;
		width: 325px;
		height: 180px;
	}
	@media @breakpoint-small {
		top: -15px;
		left: 0px;
		width: 172px;
		height: 110px;
	}
}

.block:hover:before {
	opacity: 1;
}

.left .block:before,
.center .block:before {
	background-image: url("~@/assets/minigame-5/hovered-green.svg");
}

.right .block:before {
	background-image: url("~@/assets/minigame-5/hovered-yellow.svg");
}

.is-drawing .block:hover {
	background-position: 66.6666%;
}

.is-drawing .block.connected:hover {
	background-position: 0%;
}

.left .block {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: auto auto auto;

	@media @breakpoint-large {
		background-image: url("~@/assets/minigame-5/block-left-large.png");
		width: 381px;
		height: 179px;
		padding: 47px 57px 12px 40px;
	}
	@media @breakpoint-medium {
		background-image: url("~@/assets/minigame-5/block-left-medium.png");
		width: 287px;
		height: 135px;
		padding: 35px 41px 10px 29px;
	}
	@media @breakpoint-small {
		background-image: url("~@/assets/minigame-5/block-left-small.png");
		width: 162px;
		height: 72px;
		padding: 18px 20px 5px 14px;
	}

	& > * {
		display: block;
	}

	img {
		display: block;
		margin: 0 auto;

		@media @breakpoint-large {
			width: 55px;
			height: 55px;
		}

		@media @breakpoint-medium {
			width: 40px;
			height: 40px;
		}

		@media @breakpoint-small {
			width: 23px;
			height: 23px;
		}
	}

	.value {
		font-weight: 900;
		line-height: 140%;

		@media @breakpoint-large {
			font-size: 32px;
		}

		@media @breakpoint-medium {
			font-size: 24px;
		}

		@media @breakpoint-small {
			font-size: 13px;
		}
	}
}

.center .block {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: auto auto;

	@media @breakpoint-large {
		background-image: url("~@/assets/minigame-5/block-center-large.png");
		width: 392px;
		height: 181px;
		padding: 46px 65px 12px 65px;
	}
	@media @breakpoint-medium {
		background-image: url("~@/assets/minigame-5/block-center-medium.png");
		width: 305px;
		height: 135px;
		padding: 32px 37px 9px 35px;
	}
	@media @breakpoint-small {
		background-image: url("~@/assets/minigame-5/block-center-small.png");
		width: 180px;
		height: 72px;
		padding: 17px 16px 2px 18px;
	}

	& > * {
		display: block;
	}

	.bar {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		position: relative;

		margin: 0 auto;

		@media @breakpoint-large {
			width: 46px;
			height: 101px;
			padding: 8px;
		}
		@media @breakpoint-medium {
			width: 35px;
			height: 77px;
			padding: 6px;
		}
		@media @breakpoint-small {
			width: 21px;
			height: 43px;
			padding: 4px;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			@media @breakpoint-large {
				border-radius: 5px;
				filter: blur(4px);
			}
			@media @breakpoint-medium {
				border-radius: 3.8px;
				filter: blur(3px);
			}
			@media @breakpoint-small {
				border-radius: 2px;
				filter: blur(1.7px);
			}
		}

		&.subtract:before {
			background: linear-gradient(
				194.83deg,
				rgba(255, 107, 214, 0) 21.03%,
				rgba(255, 107, 214, 0.3) 94.32%
			);
		}

		&.add:before {
			background: linear-gradient(
				194.83deg,
				rgba(232, 249, 100, 0) 21.03%,
				rgba(232, 249, 100, 0.37) 94.32%
			);
		}

		&.multiply:before {
			background: linear-gradient(
				194.83deg,
				rgba(59, 222, 154, 0) 21.03%,
				rgba(59, 222, 154, 0.4) 94.32%
			);
		}

		&.divide:before {
			background: linear-gradient(
				194.83deg,
				rgba(255, 107, 169, 0) 21.03%,
				rgba(255, 107, 160, 0.3) 94.32%
			);
		}

		.bar-block {
			border-style: solid;

			@media @breakpoint-large {
				width: 29px;
				height: 15px;
				border-radius: 4px;
				border-width: 1px;
				margin-top: 2px;
			}
			@media @breakpoint-medium {
				width: 22px;
				height: 11px;
				border-radius: 3px;
				border-width: 0.8px;
				margin-top: 1.5px;
			}
			@media @breakpoint-small {
				width: 12px;
				height: 6px;
				border-radius: 1.7px;
				border-width: 0.4px;
				margin-top: 0.85px;
			}
		}

		&.subtract .bar-block {
			background: linear-gradient(
				195.41deg,
				rgba(255, 107, 214, 0.81) -2.31%,
				rgba(251, 103, 227, 0.81) 18.8%,
				rgba(241, 91, 235, 0.81) 45.05%,
				rgba(224, 71, 209, 0.81) 74.02%,
				rgba(201, 44, 185, 0.81) 104.96%,
				rgba(171, 9, 155, 0.81) 137.14%,
				rgba(163, 0, 137, 0.81) 144.82%
			);
			border-color: #f762e7;

			@media @breakpoint-large {
				box-shadow: -3px -3px 4px 1px rgba(184, 14, 169, 0.13);
			}
			@media @breakpoint-medium {
				box-shadow: -2.26244px -2.26244px 3.01659px 0.754148px rgba(184, 14, 169, 0.13);
			}
			@media @breakpoint-small {
				box-shadow: -1.27289px -1.27289px 1.69719px 0.424298px rgba(184, 14, 169, 0.13);
			}
		}

		&.add .bar-block {
			background: linear-gradient(
				195.39deg,
				rgba(208, 255, 107, 0.8) -88.16%,
				rgba(230, 251, 103, 0.8) -44.92%,
				rgba(238, 241, 91, 0.8) 8.84%,
				rgba(224, 190, 71, 0.8) 68.17%,
				rgba(201, 185, 44, 0.8) 131.54%,
				rgba(171, 135, 9, 0.8) 197.43%,
				rgba(163, 127, 0, 0.8) 213.16%
			);
			border-color: #e8f964;

			@media @breakpoint-large {
				box-shadow: -3px -3px 4px 1px rgba(211, 193, 23, 0.13);
			}
			@media @breakpoint-medium {
				box-shadow: -2.26244px -2.26244px 3.01659px 0.754148px rgba(211, 193, 23, 0.13);
			}
			@media @breakpoint-small {
				box-shadow: -1.27289px -1.27289px 1.69719px 0.424298px rgba(211, 193, 23, 0.13);
			}
		}

		&.multiply .bar-block {
			background: linear-gradient(
				195.75deg,
				rgba(160, 255, 107, 0.9) -45.82%,
				rgba(103, 251, 144, 0.9) -20.06%,
				rgba(91, 241, 178, 0.9) 11.96%,
				rgba(71, 224, 224, 0.9) 47.31%,
				rgba(44, 173, 201, 0.9) 85.06%,
				rgba(9, 152, 171, 0.9) 124.32%,
				rgba(0, 163, 153, 0.9) 133.69%
			);
			border-color: #3dd7a8;

			@media @breakpoint-large {
				box-shadow: 0px 1.86451px 1.86451px rgba(20, 104, 69, 0.3);
			}
			@media @breakpoint-medium {
				box-shadow: 0px 1.40611px 1.40611px rgba(20, 104, 69, 0.3);
			}
			@media @breakpoint-small {
				box-shadow: 0px 0.791107px 0.791107px rgba(20, 104, 69, 0.3);
			}
		}

		&.divide .bar-block {
			background: linear-gradient(
				197.99deg,
				rgba(252, 107, 255, 0.9) -103.51%,
				rgba(195, 103, 251, 0.9) -67.87%,
				rgba(241, 91, 181, 0.9) -23.57%,
				rgba(224, 71, 218, 0.9) 25.33%,
				rgba(201, 44, 110, 0.9) 77.55%,
				rgba(171, 9, 87, 0.9) 131.87%,
				rgba(163, 0, 10, 0.9) 144.83%
			);
			border-color: #d655a2;

			@media @breakpoint-large {
				box-shadow: 0px 1.39838px 4.19514px rgba(102, 17, 24, 0.3);
			}
			@media @breakpoint-medium {
				box-shadow: 0px 1.06398px 3.19195px rgba(102, 17, 24, 0.3);
			}
			@media @breakpoint-small {
				box-shadow: 0px 0.59333px 1.77999px rgba(102, 17, 24, 0.3);
			}
		}
	}
}

.right .block {
	text-align: center;
	line-height: 132.5%;

	@media @breakpoint-large {
		background-image: url("~@/assets/minigame-5/block-right-large.png");
		width: 381px;
		height: 179px;
		padding: 62px 43px 36px 45px;
		font-size: 20px;
	}
	@media @breakpoint-medium {
		background-image: url("~@/assets/minigame-5/block-right-medium.png");
		width: 287px;
		height: 135px;
		padding: 46px 32px 29px 34px;
		font-size: 15px;
	}
	@media @breakpoint-small {
		background-image: url("~@/assets/minigame-5/block-right-small.png");
		width: 151px;
		height: 72px;
		padding: 25px 13px 11px 15px;
		font-size: 9px;
	}
}

.assistant-btn-container {
	flex-shrink: 0;
	margin: auto 0 10px 0;
	display: flex;
	flex-direction: column;

	@media @breakpoint-mob-370, @breakpoint-mob-318 {
		flex-direction: row;
		padding: 0px calc((100% - 704px) / 2) 0px calc((100% - 704px) / 2);
	}
	@media @breakpoint-mob-370 {
		height: 63px;
	}
	@media @breakpoint-mob-318 {
		margin-top: 10px;
		height: 37px;
	}
}

.assistant.horizontal {
	margin: auto auto 10px;
	padding-left: 0;

	@media @breakpoint-large, @breakpoint-medium {
		width: 650px;
	}

	@media @breakpoint-small {
		width: 400px;
	}

	/deep/ .assistant-avatar {
		right: calc(100% + 8px);
		left: auto;
	}

	/deep/ .assistant-name {
		@media @breakpoint-mob-318 {
			position: absolute;
			left: 3.5px;
			top: 3.5px;
		}
	}

	/deep/ .assistant-message {
		@media @breakpoint-mob-370, @breakpoint-mob-318 {
			line-height: 120%;
		}

		@media @breakpoint-mob-318 {
			padding-left: 61px;
		}
	}
}

.end-game {
	margin: 0 auto 10px;
	width: 650px;
	z-index: 2;

	@media @breakpoint-mob-370, @breakpoint-mob-318 {
		margin: 0;
		margin-left: 10px;
		width: auto;
		height: 32px;
	}

	&.hidden {
		visibility: hidden;
	}
}
.button.responsive.end-game {
	@media @breakpoint-mob-370, @breakpoint-mob-318 {
		font-size: 12px;
		padding: 6px 8px;
	}
	@media @breakpoint-mob-370 {
		margin-top: 26px;
	}
	@media @breakpoint-mob-318 {
		margin-top: 0;
	}
}
</style>

<script>
import Assistant from "@/components/partials/assistant";
import OrientationPlug from "@/components/orientation-plug";
import NavigationController from "@/components/partials/navigation-controller";

export default {
	props: {
		config: Object,
	},
	components: {
		Assistant,
		OrientationPlug,
		NavigationController,
	},
	data: function () {
		const items = this.config.items;

		return {
			leftItems: items
				.map((item, i) => ({
					...item,
					connected: false,
					itemId: i.toString(),
					id: `left-${i}`,
					position: "left",
				}))
				.sort((a, b) => a.indexLeft - b.indexLeft),
			centerItems: items
				.map((item, i) => ({
					...item,
					connectedLeft: false,
					connectedRight: false,
					itemId: i.toString(),
					id: `center-${i}`,
					position: "center",
				}))
				.sort((a, b) => a.indexCenter - b.indexCenter),
			rightItems: items
				.map((item, i) => ({
					...item,
					connected: false,
					itemId: i.toString(),
					id: `right-${i}`,
					position: "right",
				}))
				.sort((a, b) => a.indexRight - b.indexRight),
			lines: [],
			viewportWidth: 0,
			viewportHeight: 0,
			isDrawing: false,
			assistantMessage: this.config.messageOnShow,
			successMessageIndex: 0,
		};
	},
	computed: {
		items() {
			return [...this.leftItems, ...this.rightItems, ...this.centerItems];
		},
		complete() {
			const self = this;
			return !this.items.some(function (item) {
				return self.items.some((other) => self.canConnect(item, other));
			});
		},
	},
	methods: {
		setViewportDimensions() {
			const bb = this.$el.getBoundingClientRect();
			this.viewportWidth = bb.width;
			this.viewportHeight = bb.height;
		},
		itemToAnchorPosition(item, refX) {
			const el = document.getElementById(item.id);
			const bb = el.getBoundingClientRect();

			const rightAnchorPoint = [
				bb.left + bb.width * 0.96,
				bb.top + bb.height * 0.65,
			];
			const leftAnchorPoint = [
				bb.left + bb.width * 0.04,
				bb.top + bb.height * 0.4,
			];

			if (item.position === "left") {
				return rightAnchorPoint;
			}
			if (item.position === "right") {
				return leftAnchorPoint;
			}
			if (item.position === "center") {
				if (!item.connectedLeft && !item.connectedRight) {
					return refX > this.viewportWidth / 2
						? rightAnchorPoint
						: leftAnchorPoint;
				}
				if (item.connectedLeft && !item.connectedRight) {
					return rightAnchorPoint;
				}
				if (!item.connectedLeft && item.connectedRight) {
					return leftAnchorPoint;
				}
			}
		},
		addLine(event, item) {
			if (this.finished) {
				return;
			}
			if (item.connected || (item.connectedLeft && item.connectedRight)) {
				return;
			}
			this.setViewportDimensions();
			this.isDrawing = true;

			const [x1, y1] = this.itemToAnchorPosition(item, event.pageX);

			const id = this.lines.length
				? this.lines[this.lines.length - 1].id + 1
				: 1;

			this.lines.push({
				id,
				x1,
				y1,
				x2: event.pageX,
				y2: event.pageY,
				from: item,
			});
		},
		addTouchLine(event, item) {
			if (event.touches.length > 1) {
				return;
			}
			this.addLine(event.touches[0], item);
		},
		touchLockOrCancelLine(event) {
			if (event.changedTouches.length > 1) {
				return;
			}
			let currentTarget = document.elementFromPoint(
				event.changedTouches[0].pageX,
				event.changedTouches[0].pageY
			);
			while (
				!currentTarget.classList.contains("block") &&
				currentTarget.parentElement
			) {
				currentTarget = currentTarget.parentElement;
			}
			if (currentTarget.classList.contains("block")) {
				const item = this.items.find((i) => i.id === currentTarget.id);
				this.lockLine(item);
			} else {
				this.cancelLine();
			}
		},
		cancelLine() {
			if (this.isDrawing) {
				this.isDrawing = false;
				this.lines.pop();
			}
		},
		lockLine(item) {
			if (!this.isDrawing) {
				return;
			}

			this.isDrawing = false;
			const line = this.lines[this.lines.length - 1];
			if (this.canConnect(line.from, item)) {
				const [x2, y2] = this.itemToAnchorPosition(item, line.x1);
				line.x2 = x2;
				line.y2 = y2;

				const center = item.position === "center" ? item : line.from;
				const side = item.position === "center" ? line.from : item;
				side.connected = line.id;
				if (side.position === "left") {
					center.connectedLeft = line.id;
				}
				if (side.position === "right") {
					center.connectedRight = line.id;
				}
				this.setSuccessMessage();
			} else {
				this.setErrorMessage(line.from, item);
				this.lines.pop();
			}
		},
		touchUpdateLine(event) {
			if (event.touches.length > 1) {
				return;
			}
			this.updateLine(event.touches[0]);
		},
		updateLine(event) {
			if (!this.isDrawing) {
				return;
			}
			const line = this.lines[this.lines.length - 1];
			if (!line) {
				return;
			}
			line.x2 = event.pageX;
			line.y2 = event.pageY;
			if (line.from.position === "center") {
				const [x1, y1] = this.itemToAnchorPosition(line.from, event.pageX);
				line.x1 = x1;
				line.y1 = y1;
			}
		},
		canConnect(itemA, itemB) {
			const positions = [itemA.position, itemB.position];
			if (!positions.includes("center") || itemA.position === itemB.position) {
				return false;
			}

			const center = itemA.position === "center" ? itemA : itemB;
			const side = itemA.position === "center" ? itemB : itemA;
			if (side.connected) {
				return false;
			}
			if (side.position === "left" && center.connectedLeft) {
				return false;
			}
			if (side.position === "right" && center.connectedRight) {
				return false;
			}

			if (this.config.instantCheck && itemA.itemId !== itemB.itemId) {
				return false;
			}

			return true;
		},
		setSuccessMessage() {
			if (this.complete) {
				this.assistantMessage = this.config.messageOnComplete;
				return;
			}
			if (!this.config.instantCheck) {
				/* 1 anchor per left and right, 2 anchors per center = 4 anchors per 3 items */
				const count = (this.items.length * 4) / 3;
				const done = this.items.reduce(function (acc, item) {
					/* separation is intentional: we _want_ them to be processed and counted one-by-one */
					if (item.connected) {
						acc += 1;
					}
					if (item.connectedLeft) {
						acc += 1;
					}
					if (item.connectedRight) {
						acc += 1;
					}
					return acc;
				}, 0);
				if (done >= count / 2 && this.config.messageOnHalfway) {
					this.assistantMessage = this.config.messageOnHalfway;
				}
				return;
			}
			const i = this.successMessageIndex;
			this.assistantMessage = this.config.messageOnSuccess[i];
			this.successMessageIndex =
				i === this.config.messageOnSuccess.length - 1 ? 0 : i + 1;
		},
		setErrorMessage(from, to) {
			if (!this.config.instantCheck) {
				return;
			}
			if (
				from.position === "center" &&
				to.position === "center" &&
				from.itemId !== to.itemId
			) {
				this.assistantMessage = this.config.centerToCenterError;
			}
			if (
				from.position === "left" &&
				to.position === "left" &&
				from.itemId !== to.itemId
			) {
				this.assistantMessage = this.config.leftToLeftError;
			}
			if (
				from.position === "right" &&
				to.position === "right" &&
				from.itemId !== to.itemId
			) {
				this.assistantMessage = this.config.rightToRightError;
			}

			if (from.position === "left" && to.position === "right") {
				this.assistantMessage = this.config.leftToRightError;
			}
			if (from.position === "right" && to.position === "left") {
				this.assistantMessage = this.config.leftToRightError;
			}

			if (
				from.position === "center" &&
				to.position === "right" &&
				from.itemId !== to.itemId
			) {
				this.assistantMessage = from.centerToRightError;
			}
			if (
				from.position === "right" &&
				to.position === "center" &&
				from.itemId !== to.itemId
			) {
				this.assistantMessage = to.centerToRightError;
			}
		},
		endGame() {
			const self = this;
			const errorCount =
				this.items.reduce(function (acc, item) {
					if (item.position !== "center") {
						const connected = self.centerItems.find(
							(i) =>
								i !== item &&
								(i.connectedLeft === item.connected ||
									i.connectedRight === item.connected)
						);
						if (item.itemId !== connected.itemId) {
							acc += 1;
						}
					}
					if (item.position === "center") {
						const connectedLeft = self.items.find(
							(i) => i !== item && i.connected === item.connectedLeft
						);
						const connectedRight = self.items.find(
							(i) => i !== item && i.connected === item.connectedRight
						);
						if (item.itemId !== connectedLeft.itemId) {
							acc += 1;
						}
						if (item.itemId !== connectedRight.itemId) {
							acc += 1;
						}
					}
					return acc;
				}, 0) / 2;
			let stars = 3;
			if (errorCount > 2) {
				stars = 2;
			}
			if (errorCount > 4) {
				stars = 1;
			}
			this.$store.dispatch("gameFinished", { stars });
			this.$emit("done");
		},
		getOverlayPoints(line) {
			const a = (line.y1 - line.y2) / (line.x1 - line.x2);
			const h = 10;
			const L = 20;

			const d = Math.sqrt(a * a + 1);

			const x0 = (line.x1 + line.x2) / 2;
			const y0 = (line.y1 + line.y2) / 2;

			const l =
				Math.sqrt((line.x1 - line.x2) ** 2 + (line.y1 - line.y2) ** 2) / 2 + L;

			const m1 = l / d;
			const m2 = (l * a) / d;
			const m3 = (h * a) / d;
			const m4 = h / d;

			const x1 = -m1 - m3 + x0;
			const y1 = -m2 + m4 + y0;

			const x2 = m1 - m3 + x0;
			const y2 = m2 + m4 + y0;

			const x3 = m1 + m3 + x0;
			const y3 = m2 - m4 + y0;

			const x4 = -m1 + m3 + x0;
			const y4 = -m2 - m4 + y0;

			return `${x1},${y1} ${x2},${y2} ${x3},${y3} ${x4},${y4}`;
		},
		removeLine(id) {
			if (this.isDrawing) {
				return;
			}
			const line = this.lines.find((l) => l.id === id);
			if (!line.isUnbreakable) {
				this.lines = this.lines.filter((l) => l !== line);
				const centerItem = this.centerItems.find(
					(item) => item.connectedLeft === id || item.connectedRight === id
				);
				const sideItem = this.items.find((item) => item.connected === id);
				sideItem.connected = null;
				if (centerItem.connectedLeft === id) {
					centerItem.connectedLeft = null;
				}
				if (centerItem.connectedRight === id) {
					centerItem.connectedRight = null;
				}
			}
		},
	},
	mounted() {
		this.setViewportDimensions();
		if (this.config.connectLeftAtStart) {
			this.leftItems.forEach(function (item) {
				const other = this.centerItems.find((i) => i.itemId === item.itemId);
				const [x1, y1] = this.itemToAnchorPosition(item);
				const [x2, y2] = this.itemToAnchorPosition(other, x1);
				const id = this.lines.length
					? this.lines[this.lines.length - 1].id + 1
					: 1;
				this.lines.push({ id, x1, y1, x2, y2, isUnbreakable: true });
				item.connected = id;
				other.connectedLeft = id;
			}, this);
		}
	},
};
</script>