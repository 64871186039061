<template>
	<div
		class="item"
		:class="{
			'tutorial-shown': tutorialStep === currentTutorialStep,
			dragged
		}"
		:id="`item-${id}`"
		@dragstart.prevent
		@mousedown.prevent="$emit('mousedown', $event)"
		@touchstart.prevent="$emit('touchstart', $event)"
		@touchmove.prevent="$emit('touchmove', $event)"
		@touchend.prevent="$emit('touchend', $event)"
	>
		<div class="inner" :class="[type]">
			<div class="image" :style="{ 'background-image': 'url(' + require(`@/assets/minigame-2/content/${image}`) + ')' }"></div>
		</div>
	</div>
</template>

<style scoped lang="less">
	.item {
		transition: opacity 0.45s;
		transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

		.inner {
			display: flex;
			justify-content: center;
			align-items: center;

			width: 100%;
			height: 100%;

			transition: transform 0.45s;
			transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

			background-size: 100% 100%;

			&.green {
				background-image: url("~@/assets/minigame-2/green_item.png");
			}

			&.green:hover {
				background-image: url("~@/assets/minigame-2/green_item_hovered.png");
			}

			&.red {
				background-image: url("~@/assets/minigame-2/red_item.png");
			}

			&.red:hover {
				background-image: url("~@/assets/minigame-2/red_item_hovered.png");
			}

			.image {
				width: 85%;
				height: 85%;

				background-size: 100% 100%;
			}
		}

		&.tutoral-shown {
			z-index: 20;
		}

		&.dragged {
			z-index: 999;
			pointer-events: none;

			* {
				pointer-events: none;
			}
		}

		&.draggable-enter,
		&.draggable-leave-to {
			opacity: 0;

			.inner {
				transform: scale(0);
			}
		}

		&.draggable-enter,
		&.draggable-enter .inner {
			transition-delay: 0.45s;
		}
	}
</style>

<script>
	export default {
		props: {
			type: String,
			image: String,
			tutorialStep: Number,
			id: String,
			dragged: Boolean,
			currentTutorialStep: { type: Number, default: -1 }
		}
	}
</script>