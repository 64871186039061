<template>
	<div class="content">
		<span class="header">{{ ai.header }}</span>
		<div class="text-container">
			<span class="text">{{ ai.text1 }}</span>
			<span class="text">{{ ai.text2 }}</span>
		</div>
		<div class="btn-container">
			<button @click="$emit('prev')" class="btn">Выбрать другое имя</button>
			<button @click="$emit('next')" class="btn">Завершить</button>
		</div>
		<orientation-plug />
		<navigation-controller />
	</div>
</template>

<style scoped lang="less">
@breakpoint-xl: (min-width: 1920px);
@breakpoint-l: (max-width: 1919px) and (min-width: 1440px);
@breakpoint-m: (max-width: 1439px) and (min-width: 1025px);
@breakpoint-s: (max-width: 1024px);
@breakpoint-xs: (max-height: 650px) and (min-height: 270px);

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: auto;
	width: 100%;
	min-height: 100vh;
	background-position: top;
	cursor: url("~@/assets/cursors/cursor-normal.png") 14 18, pointer;
	background-repeat: no-repeat;

	@media @breakpoint-xl, @breakpoint-l {
		background-size: 2560px 100%;
		background-image: url("~@/assets/choice-ai/bg-large.png");
	}

	@media @breakpoint-m {
		background-size: 1439px 100%;
		background-image: url("~@/assets/choice-ai/bg-medium.png");
	}

	@media @breakpoint-s {
		background-size: 1024px 100%;
		background-image: url("~@/assets/choice-ai/bg-small.png");
	}

	@media @breakpoint-xs {
		background-image: url("~@/assets/choice-ai/bg-small.png");
		background-size: 100% 100%;
		
		display: block;
	}

	.header,
	.text-container,
	.btn-container {
		@media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
			max-width: 805px;
		}
		@media @breakpoint-s {
			max-width: 450px;
		}
		@media @breakpoint-xs {
			margin-left: auto;
			margin-right: auto;
		}
	}

	/*.column {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
			width: 805px;
		}
		@media @breakpoint-s {
			width: 450px;
		}
		@media @breakpoint-xs {
			margin-top: auto;
		}
	}*/

	.header {
		display: block;
		font-weight: 900;
		letter-spacing: -0.015em;
		color: #fff;
		text-align: center;

		@media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
			font-size: 62px;
			line-height: 77px;
			margin-bottom: 48px;
		}
		@media @breakpoint-s {
			font-size: 34px;
			line-height: 34px;
			margin-bottom: 30px;
		}
		@media @breakpoint-xs {
			margin-top: 34px;
		}
	}

	.text-container {
		width: 100%;

		background: linear-gradient(
				31.92deg,
				rgba(255, 255, 255, 0.0126) 25.95%,
				rgba(255, 255, 255, 0) 25.95%,
				rgba(255, 255, 255, 0) 50.15%
			),
			rgba(210, 178, 246, 0.35);

		box-shadow: 0px 7px 51px #6f52af;
		border-radius: 5px;

		@media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
			padding: 20px 30px;
			border: 3px solid #fff;
			margin-bottom: 75px;
		}
		@media @breakpoint-s {
			padding: 15px 20px;
			border: 1px solid #fff;
			margin-bottom: 35px;
		}

		p {
			margin: 0;
		}

		.text {
			display: inline-block;
			letter-spacing: 0.04em;
			color: #fff;
		}

		.text:first-child {
			margin-bottom: 1rem;
		}

		@media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
			font-size: 20px;
			line-height: 29px;
		}
		@media @breakpoint-s {
			font-size: 16px;
			line-height: 24px;
		}
	}
	.btn-container {
		width: 100%;
		display: flex;

		@media @breakpoint-xl, @breakpoint-l, @breakpoint-m, @breakpoint-s {
			flex-direction: column;
		}
		@media @breakpoint-xs {
			flex-direction: row;
			margin-bottom: 35px;
		}

		.btn {
			width: 100%;
			padding: 24px 32px;
			background: linear-gradient(
					312.11deg,
					rgba(255, 255, 255, 0.036) 40.01%,
					rgba(255, 255, 255, 0) 40.03%,
					rgba(255, 255, 255, 0) 128.29%
				),
				rgba(217, 19, 120, 0.6);
			mix-blend-mode: normal;
			border: 1px solid #d91378;
			border-radius: 4px;
			font-weight: 600;
			line-height: 29px;
			color: #fff;

			@media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
				font-size: 24px;
				letter-spacing: 0em;
			}
			@media @breakpoint-s {
				font-size: 16px;
				line-height: 20px;
			}

			&:hover {
				cursor: inherit;
				background: linear-gradient(
						312.11deg,
						rgba(255, 255, 255, 0.036) 40.01%,
						rgba(255, 255, 255, 0) 40.03%,
						rgba(255, 255, 255, 0) 128.29%
					),
					rgba(217, 19, 120, 0.8);
				mix-blend-mode: normal;
				border: 1px solid #d91378;
				border-radius: 4px;
			}
		}

		.btn + .btn {
			@media @breakpoint-xl, @breakpoint-l, @breakpoint-m {
				margin-top: 32px;
			}
			@media @breakpoint-s {
				margin-top: 20px;
			}
			@media @breakpoint-xs {
				margin-top: 0;
				margin-left: 10px;
			}
		}
	}
}
</style>


<script>
import OrientationPlug from "@/components/orientation-plug.vue";
import NavigationController from "@/components/partials/navigation-controller";

export default {
	components: {
		OrientationPlug,
		NavigationController,
	},

	data: function () {
		return {
			aiList: [
				{
					name: "pythagoras",
					header: "Пифагор",
					text1:
						"Благодаря теореме Пифагора многие школьники знакомы с этим именем. Его работы повлияли на великие умы человечества — Евклида и Платона. Многие считают Пифагора одним из первых математиков. Он основал пифагорейский культ, чтобы активно изучать эту науку.",
					text2:
						"Теперь Пифагор будет помогать еще и Яне Викторовне — учительнице математики из самой обычной школы.",
				},
				{
					name: "archimedes",
					header: "Архимед",
					text1:
						"Историю про погружение в ванну и возглас «Эврика!» знает, пожалуй, каждый. Но Архимед славится не только этим. Его исследования повлияли на развитие алгебры, геометрии, оптики, астрономии и стали опорой для трудов Галилео Галилея и Рене Декарта.",
					text2:
						"Теперь Архимед будет помогать еще и Яне Викторовне — учительнице математики из самой обычной школы.",
				},
				{
					name: "newton",
					header: "Ньютон",
					text1:
						"Хотя Исаак Ньютон больше известен как физик, он внес огромный вклад и в развитие математики. Дифференциалы, интегралы, экстремумы... Многие из этих терминов тебе еще рано знать, их изучают в университете. А вот яблоко вовсе не падало ему на голову, это всего лишь миф.",
					text2:
						"Теперь Ньютон будет помогать еще и Яне Викторовне — учительнице математики из самой обычной школы.",
				},
			],
		};
	},
	computed: {
		ai() {
			return this.aiList.find(
				(item) => item.name === this.$store.state.nextBtnValue
			);
		},
	},
};
</script>