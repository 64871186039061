<template>
  <div class="game">
    <div class="columns">
      <div class="column-left">
        <div class="frame-data" :class="{ 'frame-tutor': isTutorialState }">
          <div class="bars">
            <div v-for="(item, index) in bars" :key="index" class="bar-wrapper">
              <div class="bar" :class="`bar-` + positions[index]">
                <div class="bar-block" v-for="n in bars[index]" :key="n"></div>
              </div>
              <span class="bar-name" v-html="choiceLabels[index]"></span>
            </div>
          </div>
          <div class="conclusion">
            <span class="conclusion-header">ВЫВОД</span>
            <span class="conclusion-text">{{ conclusion }}</span>
          </div>
        </div>
        <div
          class="assistant-update-container"
          :class="{ 'assistant-tutor': isTutorialState }"
        >
          <assistant class="assistant" horizontal :message="assistantMessage" />
          <button
            class="button responsive update-ai"
            @click="onButtonClick"
            v-show="isTutorialState"
          >
            {{ buttontext }}
          </button>
        </div>
      </div>
      <div class="column-right">
        <div class="frame-advice">
          <span class="frame-header">Выбери сложность задания</span>
          <div class="choices">
            <div
              v-for="(item, index) in states"
              :key="index"
              class="choice-wrapper"
              :class="{ error: item.error && config.instantCheck }"
            >
              <span class="choice-header" v-html="choiceLabels[index]"></span>
              <div class="choice">
                <template v-for="(answer, index) in possibleAnswers">
                  <input
                    :id="answer + '-' + item.position"
                    :name="item.position"
                    :value="answer"
                    :key="'input-' + answer"
                    v-model="item.value"
                    type="radio"
                    :disabled="config.instanceCheck ? stageComplete : false"
                  />
                  <label
                    :key="'label-' + answer"
                    :for="answer + '-' + item.position"
                    :data-title="dataTitle(index)"
                    :class="'label-' + answer"
                  ></label>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="frame-test">
          <span class="frame-header">Получившийся тест</span>
          <div class="tests">
            <div
              v-for="(item, index) in tests"
              :key="index"
              class="test"
              :class="`test-` + positions[index]"
            >
              <span
                class="test-header"
                :class="`test-` + positions[index] + `-header`"
                v-html="choiceLabels[index]"
              ></span>
              <img
                v-for="num in getExamplesNum(positions[index])"
                :key="num"
                :src="require('@/' + item.replace('%1', `${num}`))"
                :class="{ 'test-img': !stageComplete }"
              />
            </div>
          </div>
          <button
            class="test-btn"
            :class="activeBtn"
            :disabled="!stageComplete"
            @click="send()"
          >
            Обновить ИИ
          </button>
        </div>
      </div>
      <orientation-plug />
      <navigation-controller />
      <div class="overlay" v-show="isTutorialState"></div>
    </div>
  </div>
</template>


<script>
import Assistant from "../components/partials/assistant.vue";
import OrientationPlug from "@/components/orientation-plug.vue";
import NavigationController from "@/components/partials/navigation-controller";

export default {
  props: {
    config: Object,
  },
  components: { Assistant, OrientationPlug, NavigationController },
  data: function () {
    return {
      // assistantMessage: "",
      tutorialStepIndex: 0,
      buttontext: "Дальше",
      currentStage: 1,
      errorCount: 0,
      states: [
        { position: "first", value: undefined, error: undefined },
        { position: "second", value: undefined, error: undefined },
        { position: "third", value: undefined, error: undefined },
        { position: "fourth", value: undefined, error: undefined },
      ],
      complete: false,
    };
  },

  methods: {
    onButtonClick: function () {
      if (this.isTutorialState) {
        this.tutorialStepIndex += 1;
      }
    },
    dataTitle: function (index) {
      return this.config.dataTitles[index];
    },
    choiceLabel: function (val) {
      return this.config.choiceLabels[val];
    },
    endGame() {
      let stars = 3;
      if (this.errorCount > 3) {
        stars = 2;
      }
      if (this.errorCount > 6) {
        stars = 1;
      }
      this.$store.dispatch("gameFinished", { stars });
      this.$emit("done");
    },
    send: function () {
      if (!this.complete) {
        this.states.forEach((el) => {
          if (el.error) {
            this.errorCount += 1;
          }
        });
      }
      if (this.currentStage === this.lastStage) {
        this.complete = true;
        this.endGame();
        return;
      }
      this.currentStage += 1;
      this.states.forEach((el) => {
        el.value = undefined;
        el.error = undefined;
      });
    },
    getExamplesNum: function (position) {
      let value = this.states.find((item) => item.position === position).value;
      if (value === "simple" && this.config.instantCheck) {
        return 4;
      }
      if (value === "complex" && this.config.instantCheck) {
        return 2;
      }
      if (value === "simple") {
        return 1;
      }
      if (value === "common") {
        return 2;
      }
      return this.config.possibleAnswers.length;
    },
  },
  computed: {
    tutorial() {
      return this.config.tutorial;
    },
    isTutorialState() {
      return this.tutorialStepIndex < this.tutorial.length;
    },
    positions: function () {
      return this.config.positions;
    },
    choiceLabels: function () {
      return this.config.choiceLabels;
    },
    bars: function () {
      return this.config.stagesData[this.currentStage - 1].bars;
    },
    conclusion: function () {
      return this.config.stagesData[this.currentStage - 1].conclusion;
    },
    answers: function () {
      return this.config.stagesData[this.currentStage - 1].answers;
    },
    tests: function () {
      return this.config.stagesData[this.currentStage - 1].tests;
    },
    lastStage: function () {
      return this.config.stagesData.length;
    },
    stageComplete: function () {
      return this.config.instantCheck
        ? this.states.every((el) => el.value && !el.error)
        : this.states.every((el) => el.value !== undefined);
    },

    assistantMessage: function () {
      if (this.isTutorialState) {
        return this.tutorial[this.tutorialStepIndex];
      }
      if (this.stageComplete) {
        return this.config.stagesData[this.currentStage - 1].messageOnComplete;
      }
      let errorState = this.states.find((el) => el.error);
      if (errorState && this.config.instantCheck) {
        return this.config.messagesOnError[errorState.position];
      }
      if (
        this.states.some((el, index) => el.value === this.answers[index]) &&
        this.config.instantCheck
      ) {
        return this.config.messageOnProceed;
      }
      return this.config.messageOnShow;
    },
    possibleAnswers: function () {
      return this.config.possibleAnswers;
    },
    activeBtn: function () {
      return this.stageComplete ? "active-btn" : "";
    },
  },
  watch: {
    states: {
      deep: true,
      handler: function (newStates) {
        let undefinedAnswer = newStates.some((el) => el.value === undefined);
        if (undefinedAnswer) {
          return;
        }
        newStates.forEach((el, index) => {
          if (el.value !== this.answers[index]) {
            el.error = true;
          } else {
            el.error = false;
          }
        });
      },
    },
  },
};
</script>


<style scoped lang="less">
/* autoprefixer grid: autoplace */
@breakpoint-large: (min-width: 1600px);
@breakpoint-medium: (max-width: 1599px) and (min-width: 1440px);
@breakpoint-tablet: (max-width: 1439px) and (min-width: 1060px);
@breakpoint-mobile: (max-width: 1059px);
@breakpoint-mob: (max-height: 329px) and (min-height: 270px);

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.8;
  z-index: 10;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.game {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: url("~@/assets/cursors/cursor-normal.png") 14 18, pointer;
  font-family: Gilroy;
  background-repeat: no-repeat;
  background-position: top;

  @media @breakpoint-large {
    background-size: 100% 100%;
    background-image: url("~@/assets/minigame-6/bg-large.png");
  }
  @media @breakpoint-medium {
    background-size: 100% 100%;
    background-image: url("~@/assets/minigame-6/bg-large.png");
  }
  @media @breakpoint-tablet {
    background-size: 100% 100%;
    background-image: url("~@/assets/minigame-6/bg-medium.png");
  }
  @media @breakpoint-mobile {
    background-size: 100% 100%;
    background-image: url("~@/assets/minigame-6/bg-small.png");
  }

  .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  @media @breakpoint-mob {
    height: 100vh;
  }
}

.column-left,
.column-right {
  display: flex;
  flex-direction: column;
}

.column-left {
  margin-right: 4%;

  .assistant-update-container {
    margin-top: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .update-ai {
      align-self: flex-end;
      width: 50%;
      margin-top: 20px;
    }
  }

  .assistant-tutor {
    position: fixed;
    z-index: 20;
    bottom: 10px;
  }

  .frame-tutor {
    margin-bottom: auto;
  }
}

.assistant.horizontal {
  max-width: 636px;

  @media @breakpoint-medium {
    max-width: 568px;
  }
  @media @breakpoint-tablet {
    max-width: 410px;
  }
  @media @breakpoint-mobile {
    max-width: 225px;
  }
}

.frame-header {
  position: relative;
  font-weight: 900;
  line-height: 120%;
  color: #ffffff;
  @media @breakpoint-large {
    font-size: 36px;
  }
  @media @breakpoint-medium {
    font-size: 36px;
  }
  @media @breakpoint-tablet {
    font-size: 28px;
  }
  @media @breakpoint-mobile {
    font-size: 16px;
  }
}

.frame-data {
  position: relative;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  margin-bottom: 10px;
  @media @breakpoint-large {
    background-image: url("~@/assets/minigame-6/frame-data-xl.png");
    width: 636px;
    height: 616px;
    padding: 64px 50px 32px 70px;
  }
  @media @breakpoint-medium {
    background-image: url("~@/assets/minigame-6/frame-data-l.png");
    width: 568px;
    height: 572px;
    padding: 44px 35px 24px 37px;
    //margin-bottom: 40px;
  }
  @media @breakpoint-tablet {
    background-image: url("~@/assets/minigame-6/frame-data-m.png");
    width: 410px;
    height: 414px;
    padding: 44px 35px 12px 37px;
    //margin-bottom: 40px;
  }
  @media @breakpoint-mobile {
    background-image: url("~@/assets/minigame-6/frame-data-s.png");
    width: 225px;
    height: 226px;
    padding: 18px 14px 8px 14px;
    // margin-bottom: 11px;
  }

  &:before {
    pointer-events: none;
    content: "";
    position: absolute;
    top: -50px;
    bottom: 0;
    left: -40px;
    right: 0;
    width: 614px;
    height: 523px;
    background-repeat: no-repeat;
    background-image: url("~@/assets/minigame-6/glow-green-large.png");
    background-size: 100% 100%;

    @media @breakpoint-medium {
      left: -80px;
    }
    @media @breakpoint-tablet {
      top: -40px;
      left: -45px;
      width: 445px;
      height: 380px;
      background-image: url("~@/assets/minigame-6/glow-green-medium.png");
    }
    @media @breakpoint-mobile {
      top: -20px;
      left: -25px;
      width: 236px;
      height: 202px;
      background-image: url("~@/assets/minigame-6/glow-green-small.png");
    }
  }

  &:after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 75px;
    bottom: 0;
    left: -175px;
    right: 0;
    width: 369px;
    height: 490px;
    background-repeat: no-repeat;
    background-image: url("~@/assets/minigame-6/sparks-green-large.png");
    background-size: 100% 100%;

    @media @breakpoint-tablet {
      width: 273px;
      height: 362px;
      background-image: url("~@/assets/minigame-6/sparks-green-medium.png");
    }
    @media @breakpoint-mobile {
      top: 90px;
      left: -125px;
      width: 183px;
      height: 242px;
      background-image: url("~@/assets/minigame-6/sparks-green-small.png");
    }
  }
}

.frame-advice {
  position: relative;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  margin-bottom: 10px;
  @media @breakpoint-large {
    background-image: url("~@/assets/minigame-6/frame-advice-xl.png");
    width: 809px;
    height: 251px;
    padding: 40px 45px 0px 56px;
  }
  @media @breakpoint-medium {
    background-image: url("~@/assets/minigame-6/frame-advice-l.png");
    width: 773px;
    height: 251px;
    padding: 40px 45px 0px 32px;
  }
  @media @breakpoint-tablet {
    background-image: url("~@/assets/minigame-6/frame-advice-m.png");
    width: 561px;
    height: 191px;
    padding: 24px 10px 0px 16px;
  }
  @media @breakpoint-mobile {
    background-image: url("~@/assets/minigame-6/frame-advice-s.png");
    width: 306px;
    height: 108px;
    padding: 16px 5px 0px 12px;
  }
  @media @breakpoint-mob {
    margin-bottom: 0;
  }
  &:before {
    pointer-events: none;
    content: "";
    position: absolute;

    background-repeat: no-repeat;

    background-size: 100% 100%;

    @media @breakpoint-large {
      top: -140px;
      left: 125px;
      width: 884px;
      height: 745px;
      background-image: url("~@/assets/minigame-6/glow-yellow1-large.png");
    }
    @media @breakpoint-medium {
      top: -70px;
      left: 125px;
      width: 884px;
      height: 745px;
      background-image: url("~@/assets/minigame-6/glow-yellow1-large.png");
    }
    @media @breakpoint-tablet {
      top: -70px;
      left: 50px;
      width: 724px;
      height: 594px;
      background-image: url("~@/assets/minigame-6/glow-yellow1-medium.png");
    }
    @media @breakpoint-mobile {
      top: -70px;
      left: 0px;
      width: 387px;
      height: 323px;
      background-image: url("~@/assets/minigame-6/glow-yellow1-small.png");
    }
  }

  .choices {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    @media @breakpoint-mobile {
      margin-top: 10px;
      line-height: normal;
    }
    .choice-wrapper {
      position: relative;
      width: 160px;
      height: 116px;

      @media @breakpoint-tablet {
        width: 142px;
        height: 100px;
      }
      @media @breakpoint-mobile {
        width: 69px;
        height: 47px;
      }

      @media @breakpoint-mob {
        margin-top: 0;
      }

      .choice-header {
        font-weight: bold;
        font-size: 20px;
        line-height: 34px;
        letter-spacing: 0.04em;
        color: #ffffff;

        @media @breakpoint-tablet {
          font-size: 16px;
          line-height: 27px;
        }
        @media @breakpoint-mobile {
          font-size: 8px;
          line-height: normal;
        }
      }
      .choice {
        position: relative;
        margin-top: 12px;

        @media @breakpoint-tablet {
          margin-top: 8px;
        }

        @media @breakpoint-mobile {
          margin-top: 5px;
        }

        label {
          position: relative;
          cursor: inherit;
          display: inline-block;
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          box-shadow: 0px 3.25432px 9.76295px rgba(102, 17, 24, 0.3);
          border-radius: 8px;

          @media @breakpoint-tablet {
            width: 34px;
            height: 34px;
            box-shadow: 0px 2.76617px 8.29851px rgba(102, 17, 24, 0.3);
            border-radius: 6.8px;
          }
          @media @breakpoint-mobile {
            width: 15.81px;
            height: 15.81px;
            box-shadow: 0px 1.28597px 3.85791px rgba(102, 17, 24, 0.3);
            border-radius: 3.16126px;
          }
        }

        label.label-simple {
          background: linear-gradient(
            195.75deg,
            rgba(160, 255, 107, 0.3) -45.82%,
            rgba(103, 251, 144, 0.3) -20.06%,
            rgba(91, 241, 178, 0.3) 11.96%,
            rgba(71, 224, 224, 0.3) 47.31%,
            rgba(44, 173, 201, 0.3) 85.06%,
            rgba(9, 152, 171, 0.3) 124.32%,
            rgba(0, 163, 153, 0.3) 133.69%
          );
          border: 2.32721px solid #3dd7a8;

          @media @breakpoint-mobile {
            border: 1px solid #3dd7a8;
          }
        }

        label.label-common {
          background: linear-gradient(
            194.83deg,
            rgba(232, 249, 100, 0.18) 21.03%,
            rgba(232, 249, 100, 0.37) 94.32%
          );
          border: 2.32721px solid #ced3a6;

          @media @breakpoint-mobile {
            border: 1px solid solid #ced3a6;
          }
        }

        label.label-complex {
          background: linear-gradient(
            197.99deg,
            rgba(252, 107, 255, 0.3) -103.51%,
            rgba(195, 103, 251, 0.3) -67.87%,
            rgba(241, 91, 181, 0.3) -23.57%,
            rgba(224, 71, 218, 0.3) 25.33%,
            rgba(201, 44, 110, 0.3) 77.55%,
            rgba(171, 9, 87, 0.3) 131.87%,
            rgba(163, 0, 10, 0.3) 144.83%
          );
          border: 2.32721px solid #d655a2;

          @media @breakpoint-mobile {
            border: 1px solid #d655a2;
          }
        }

        input {
          position: absolute;
          left: -9999px;
        }
      }
      label:after {
        pointer-events: none;
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        background-image: url("~@/assets/minigame-6/check.svg");
        width: 61.25%;
        height: 40%;
        left: 20%;
        bottom: 27.5%;
        background-size: 100% 100%;
      }

      input:not(:checked) + label:after {
        opacity: 0;
      }

      input:checked + label:after {
        opacity: 1;
      }

      label:not(:nth-child(2)) {
        margin-left: 8px;

        @media @breakpoint-tablet {
          margin-left: 8px;
        }
        @media @breakpoint-mobile {
          margin-left: 3.16px;
        }
      }

      label:hover:before {
        pointer-events: none;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        content: attr(data-title);
        height: 43px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0.04em;
        color: #ffffff;
        background: rgba(118, 107, 236, 0.81);
        backdrop-filter: blur(9px);
        top: 60px;
        right: 0;
        bottom: 0;
        left: -70px;

        @media @breakpoint-tablet, @breakpoint-mobile {
          width: 0px;
          height: 0px;
          content: "";
        }
      }

      .label-simple:hover:before,
      .label-common:hover:before,
      .label-complex:hover:before {
        width: 255px;
      }

      &.error:before {
        display: block;
        position: absolute;
        content: "";
        pointer-events: none;
        top: -15px;
        right: 0;
        bottom: 0;
        left: -11px;
        width: 160px;
        height: 116px;
        background-repeat: no-repeat;
        background-image: url("~@/assets/minigame-6/error-large.png");

        @media @breakpoint-tablet {
          width: 142px;
          height: 100px;
          top: -8px;
          left: -7px;
          background-image: url("~@/assets/minigame-6/error-medium.png");
        }
        @media @breakpoint-mobile {
          top: -1px;
          left: -5px;
          width: 69px;
          height: 47px;
          background-image: url("~@/assets/minigame-6/error-small.png");
        }
      }
    }
  }
}

.frame-test {
  position: relative;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  //margin-top: auto;
  @media @breakpoint-large {
    background-image: url("~@/assets/minigame-6/frame-test-xl.png");
    width: 795px;
    height: 531px;
    padding: 40px 40px 0px 56px;
  }
  @media @breakpoint-medium {
    background-image: url("~@/assets/minigame-6/frame-test-l.png");
    width: 767px;
    height: 509px;
    padding: 17px 34px 0px 32px;
  }
  @media @breakpoint-tablet {
    background-image: url("~@/assets/minigame-6/frame-test-m.png");
    width: 555px;
    height: 384px;
    padding: 24px 24px 0px 24px;
  }
  @media @breakpoint-mobile {
    background-image: url("~@/assets/minigame-6/frame-test-s.png");
    width: 304px;
    height: 211px;
    padding: 16px 16px 0px 11px;
  }

  &:before {
    pointer-events: none;
    position: absolute;
    content: "";
    top: 68px;
    right: 0;
    bottom: 0;
    left: 235px;
    width: 614px;
    height: 523px;
    background-image: url("~@/assets/minigame-6/glow-yellow2-large.png");
    background-size: 100% 100%;

    @media @breakpoint-tablet {
      top: 40px;
      left: 135px;
      width: 445px;
      height: 380px;
      background-image: url("~@/assets/minigame-6/glow-yellow2-medium.png");
    }
    @media @breakpoint-mobile {
      top: 25px;
      left: 85px;
      width: 236px;
      height: 202px;
      background-image: url("~@/assets/minigame-6/glow-yellow2-small.png");
    }
  }

  &:after {
    pointer-events: none;
    position: absolute;
    content: "";
    top: 150px;
    right: 0;
    bottom: 0;
    left: 575px;
    width: 369px;
    height: 490px;
    background-repeat: no-repeat;
    background-image: url("~@/assets/minigame-6/sparks-yellow-large.png");
    background-size: 100% 100%;

    @media @breakpoint-tablet {
      top: 50px;
      left: 455px;
      width: 273px;
      height: 362px;
      background-image: url("~@/assets/minigame-6/sparks-yellow-medium.png");
    }
    @media @breakpoint-mobile {
      top: 30px;
      left: 215px;
      width: 173px;
      height: 237px;
      background-image: url("~@/assets/minigame-6/sparks-yellow-small.png");
    }
  }
}

.bars > * {
  z-index: 1;
}

.bars {
  display: flex;
  justify-content: space-between;

  .bar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .bar {
      position: relative;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin: 0 auto;
      width: 110px;
      height: 310px;
      padding-bottom: 28px;

      @media @breakpoint-tablet {
        width: 60px;
        height: 188px;
        padding-bottom: 12px;
      }

      @media @breakpoint-mobile {
        width: 38px;
        height: 113px;
        padding-bottom: 7px;
      }

      &:before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        filter: blur(9px);
        border-radius: 12px;

        @media @breakpoint-mobile {
          filter: blur(7px);
          border-radius: 8px;
        }
      }

      &.bar-first:before {
        background: linear-gradient(
          194.83deg,
          rgba(255, 107, 214, 0) 21.03%,
          rgba(255, 107, 214, 0.3) 94.32%
        );
      }
      &.bar-second:before {
        background: linear-gradient(
          194.83deg,
          rgba(232, 249, 100, 0) 21.03%,
          rgba(232, 249, 100, 0.37) 94.32%
        );
      }
      &.bar-third:before {
        background: linear-gradient(
          194.83deg,
          rgba(59, 222, 154, 0) 21.03%,
          rgba(59, 222, 154, 0.4) 94.32%
        );
      }
      &.bar-fourth:before {
        background: linear-gradient(
          194.83deg,
          rgba(83, 183, 232, 0) 21.03%,
          rgba(83, 183, 232, 0.71) 94.32%
        );
      }

      .bar-block:not(:last-child) {
        margin-top: 8px;

        @media @breakpoint-tablet {
          margin-top: 4px;
        }
        @media @breakpoint-mobile {
          margin-top: 2px;
        }
      }

      .bar-block {
        border-style: solid;
        width: 43px;
        height: 43px;
        border-radius: 9px;
        border: 2.32721px solid #f762e7;
        box-sizing: border-box;
        box-shadow: -6.98162px -6.98162px 9.30882px 2.32721px rgba(184, 14, 169, 0.13);

        @media @breakpoint-tablet {
          height: 28px;
          width: 28px;
          border-radius: 6px;
        }
        @media @breakpoint-mobile {
          height: 18px;
          width: 18px;
          border-radius: 3px;
        }
      }

      &.bar-first .bar-block {
        background: linear-gradient(
          195.41deg,
          rgba(255, 107, 214, 0.81) -2.31%,
          rgba(251, 103, 227, 0.81) 18.8%,
          rgba(241, 91, 235, 0.81) 45.05%,
          rgba(224, 71, 209, 0.81) 74.02%,
          rgba(201, 44, 185, 0.81) 104.96%,
          rgba(171, 9, 155, 0.81) 137.14%,
          rgba(163, 0, 137, 0.81) 144.82%
        );
        border: 2.32721px solid #f762e7;
        box-sizing: border-box;
        box-shadow: -6.98162px -6.98162px 9.30882px 2.32721px rgba(184, 14, 169, 0.13);

        @media @breakpoint-large {
          box-shadow: -3px -3px 4px 1px rgba(184, 14, 169, 0.13);
        }
        @media @breakpoint-medium {
          box-shadow: -2.26244px -2.26244px 3.01659px 0.754148px rgba(184, 14, 169, 0.13);
        }
        @media @breakpoint-tablet {
          box-shadow: -1.27289px -1.27289px 1.69719px 0.424298px rgba(184, 14, 169, 0.13);
        }
      }

      &.bar-second .bar-block {
        background: linear-gradient(
          195.39deg,
          rgba(208, 255, 107, 0.8) -88.16%,
          rgba(230, 251, 103, 0.8) -44.92%,
          rgba(238, 241, 91, 0.8) 8.84%,
          rgba(224, 190, 71, 0.8) 68.17%,
          rgba(201, 185, 44, 0.8) 131.54%,
          rgba(171, 135, 9, 0.8) 197.43%,
          rgba(163, 127, 0, 0.8) 213.16%
        );
        border: 2.32721px solid #e8f964;
        box-sizing: border-box;
        box-shadow: -6.98162px -6.98162px 9.30882px 2.32721px rgba(211, 193, 23, 0.13);

        @media @breakpoint-large {
          box-shadow: -3px -3px 4px 1px rgba(211, 193, 23, 0.13);
        }
        @media @breakpoint-medium {
          box-shadow: -2.26244px -2.26244px 3.01659px 0.754148px rgba(211, 193, 23, 0.13);
        }
        @media @breakpoint-tablet {
          box-shadow: -1.27289px -1.27289px 1.69719px 0.424298px rgba(211, 193, 23, 0.13);
        }
      }

      &.bar-third .bar-block {
        background: linear-gradient(
          195.75deg,
          rgba(160, 255, 107, 0.9) -45.82%,
          rgba(103, 251, 144, 0.9) -20.06%,
          rgba(91, 241, 178, 0.9) 11.96%,
          rgba(72, 242, 171, 0.9) 47.31%,
          rgba(44, 201, 79, 0.9) 85.06%,
          rgba(9, 171, 54, 0.9) 124.32%,
          rgba(0, 163, 55, 0.9) 133.69%
        );
        border: 2.32721px solid #3dd7a8;
        box-sizing: border-box;
        box-shadow: 0px 4.33909px 4.33909px rgba(20, 104, 69, 0.3);

        @media @breakpoint-large {
          box-shadow: 0px 1.86451px 1.86451px rgba(20, 104, 69, 0.3);
        }
        @media @breakpoint-medium {
          box-shadow: 0px 1.40611px 1.40611px rgba(20, 104, 69, 0.3);
        }
        @media @breakpoint-tablet {
          box-shadow: 0px 0.791107px 0.791107px rgba(20, 104, 69, 0.3);
        }
      }

      &.bar-fourth .bar-block {
        background: linear-gradient(
          195.41deg,
          rgba(107, 255, 255, 0.81) -2.31%,
          rgba(103, 242, 251, 0.81) 18.8%,
          rgba(91, 232, 241, 0.81) 45.05%,
          rgba(71, 206, 224, 0.81) 74.02%,
          rgba(44, 173, 201, 0.81) 104.96%,
          rgba(20, 142, 180, 0.81) 127.1%,
          rgba(9, 132, 171, 0.81) 137.14%,
          rgba(83, 183, 232, 0.81) 144.82%
        );
        /* Main pallet/primary/300 */

        border: 2.32721px solid #53b7e8;
        box-sizing: border-box;
        box-shadow: -6.98162px -6.98162px 9.30882px 2.32721px rgba(83, 183, 232, 0.07);

        @media @breakpoint-large {
          box-shadow: 0px 1.39838px 4.19514px rgba(102, 17, 24, 0.3);
        }
        @media @breakpoint-medium {
          box-shadow: 0px 1.06398px 3.19195px rgba(102, 17, 24, 0.3);
        }
        @media @breakpoint-tablet {
          box-shadow: 0px 0.59333px 1.77999px rgba(102, 17, 24, 0.3);
        }
      }
    }
    .bar-name {
      font-weight: bold;
      font-size: 18px;
      line-height: 170%;
      letter-spacing: 0.04em;
      margin-top: 16px;
      color: #ffffff;

      @media @breakpoint-tablet {
        font-size: 11px;
        margin-top: 12px;
      }
      @media @breakpoint-mobile {
        font-size: 8px;
        margin-top: 6px;
      }
    }
  }
}

.conclusion {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 142px;
  margin-top: 13px;

  @media @breakpoint-tablet {
    height: 88px;
    margin-top: 26px;
  }

  @media @breakpoint-mobile {
    height: 52px;
    margin-top: 8px;
  }

  &:before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: blur(9px);
    border-radius: 12px;
    background: linear-gradient(
      193.7deg,
      rgba(59, 222, 154, 0) 19.71%,
      rgba(59, 222, 154, 0.21) 94.22%
    );
    filter: blur(9.30882px);
    border-radius: 11.636px;

    @media @breakpoint-mobile {
      filter: blur(7px);
      border-radius: 8px;
    }
  }

  .conclusion-header {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #ffe713;

    @media @breakpoint-tablet {
      font-size: 14px;
    }
    @media @breakpoint-mobile {
      font-size: 9px;
      line-height: normal;
    }
  }

  .conclusion-text {
    display: block;
    font-family: Gilroy;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.04em;

    margin-top: 6px;
    text-align: center;
    color: #ffffff;

    @media @breakpoint-tablet {
      font-family: Gilroy;
      font-size: 16px;
      line-height: 21px;
      margin-top: 2px;
    }
    @media @breakpoint-mobile {
      font-size: 9px;
      line-height: 12px;
      margin-top: 2px;
      line-height: normal;
    }
  }
}

.tests {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media @breakpoint-mobile {
    margin-top: 10px;
  }
  @media @breakpoint-mob {
    margin-top: 0;
  }
  img {
    @media @breakpoint-large, @breakpoint-medium {
      margin-top: 15px;
    }

    @media @breakpoint-tablet {
      margin-top: 10px;
      width: 65px;
    }
    @media @breakpoint-mobile {
      margin-top: 4px;
      width: 33px;
    }
  }
  .test-img {
    visibility: hidden;
  }
}

.test {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 234px;
  padding: 30px 20px 22px 18px;

  @media @breakpoint-tablet {
    height: 169px;
    padding: 41px 10px 10px 12px;
  }
  @media @breakpoint-mobile {
    height: 92px;
    padding: 12px 12px 19px 8px;
  }

  &:before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: blur(9px);
    border-radius: 12px;

    @media @breakpoint-mobile {
      filter: blur(7px);
      border-radius: 8px;
    }
  }

  &.test-first:before {
    background: linear-gradient(
      194.83deg,
      rgba(255, 107, 214, 0) 21.03%,
      rgba(255, 107, 214, 0.3) 94.32%
    );
  }

  &.test-second:before {
    background: linear-gradient(
      194.83deg,
      rgba(232, 249, 100, 0) 21.03%,
      rgba(232, 249, 100, 0.37) 94.32%
    );
  }

  &.test-third:before {
    background: linear-gradient(
      194.83deg,
      rgba(59, 222, 154, 0) 21.03%,
      rgba(59, 222, 154, 0.4) 94.32%
    );
  }

  &.test-fourth:before {
    background: linear-gradient(
      194.83deg,
      rgba(83, 183, 232, 0) 21.03%,
      rgba(83, 183, 232, 0.28) 94.32%
    );
  }
}

.test-header {
  font-weight: bold;
  line-height: 170%;
  font-size: 20px;

  @media @breakpoint-tablet {
    font-size: 16px;
  }
  @media @breakpoint-mobile {
    font-size: 7px;
  }
}

.test-first-header {
  color: #f82cea;
}

.test-second-header {
  color: #e4ff5c;
}

.test-third-header {
  color: #3ddfa6;
}

.test-fourth-header {
  color: #6aceff;
}

.test-btn {
  margin-top: 40px;
  width: 100%;
  height: 77px;
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;

  @media @breakpoint-tablet {
    margin-top: 24px;
    height: 61px;
    font-size: 20px;
  }
  @media @breakpoint-mobile {
    margin-top: 13px;
    height: 31px;
    font-size: 9px;
  }

  background: linear-gradient(
      312.11deg,
      rgba(255, 255, 255, 0.036) 40.01%,
      rgba(255, 255, 255, 0) 40.03%,
      rgba(255, 255, 255, 0) 128.29%
    ),
    rgba(229, 229, 229, 0.4);
  mix-blend-mode: normal;
  /* Main pallet/white */

  border: 1px solid #ffffff;
  box-shadow: 0px 0px 32px rgba(255, 255, 255, 0.27);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 4px;
}

.test-btn:hover {
  cursor: inherit;
}
.assistant /deep/ .assistant-avatar {
  @media @breakpoint-mobile {
    display: none;
  }
}

.active-btn {
  background: linear-gradient(
      312.11deg,
      rgba(255, 255, 255, 0.036) 40.01%,
      rgba(255, 255, 255, 0) 40.03%,
      rgba(255, 255, 255, 0) 128.29%
    ),
    rgba(217, 19, 120, 0.6);
}

.assistant.horizontal {
  @media @breakpoint-mobile {
    padding-left: 0px;
  }
}

.assistant /deep/ .assistant-name {
  @media @breakpoint-mobile {
    height: 18px;
    width: 53px;
    font-size: 6.5px;
  }
}

.assistant.horizontal /deep/ .assistant-message {
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.04em;

  @media @breakpoint-mobile {
    font-size: 10px;
    line-height: 15px;
    padding: 8px;
  }
  @media @breakpoint-mob {
    line-height: 12px;
    padding: 4px;
  }
}
</style>