<template>
  <div
    class="diagram-element"
    :class="{ 'tutorial-visible': tutorialStep === item.tutorialStep }"
    :style="getItemPosition(item, source)"
  >
    <div
      class="background"
      :style="{
        transform: `rotate(${source ? item.source.r : item.target.r}deg)`,
      }"
    >
      <div class="item placeholder" :class="[item.shape, item.size]"></div>
    </div>
    <draggable
      class="dropzone"
      v-model="items"
      forceFallback="true"
      :group="{
        name: source ? item.id : 'target',
        pull: items.length ? [items[0].id, 'target'] : [],
        put: (to) => to.el.children.length === 0,
      }"
      @choose="$emit('choose')"
      @unchoose="$emit('unchoose')"
    >
      <div class="item-wrapper" v-for="i in items" :key="i.id">
        <div
          :style="{
            transform: `rotate(${source ? i.source.r : i.target.r}deg)`,
          }"
        >
          <div
            class="item"
            :class="[i.shape, i.color, i.size]"
            @mousedown="raise = true"
            @mouseup="raise = false"
          >
            <span class="item-text">{{ i.text }}</span>
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<style scoped lang="less">
@breakpoint-xxl: (min-width: 1920px) and (min-height: 850px);
@breakpoint-xl: (min-width: 1640px) and (max-width: 1919px) and (min-height: 850px);
@breakpoint-l: (min-width: 1440px) and (max-width: 1639px) and (min-height: 850px);
@breakpoint-m: (min-width: 1024px) and (max-width: 1439px), (min-width: 1024px) and (max-height: 849px);
@breakpoint-s: (min-width: 720px) and (max-width: 1023px);
@breakpoint-xs: (max-width: 719px);

.diagram-element {
  position: absolute;

  .dropzone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.tutorial-visible {
    z-index: 20;
  }
}

.sortable-ghost {
  visibility: hidden;
}

.item-wrapper.sortable-chosen {
  transform: translateY(-4px);
}

.item-wrapper:not(.sortable-chosen) {
  cursor: url("~@/assets/cursors/cursor-hand-opened.png"), grab;
}

.item {
  border-style: solid;

  @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
    border-width: 2px;
  }
  @media @breakpoint-m {
    border-width: 1.32px;
  }
  @media @breakpoint-s, @breakpoint-xs {
    border-width: 0.55px;
  }

  &.square {
    border-radius: 4px;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;

    @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
      width: 170px;
      height: 56px;
    }
    @media @breakpoint-m {
      width: 112px;
      height: 37px;
    }
    @media @breakpoint-s, @breakpoint-xs {
      border-radius: 2px;
      width: 47px;
      height: 16px;
    }

    &.small {
      @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
        width: 160px;
        height: 56px;
      }
      @media @breakpoint-m {
        width: 104px;
        height: 36px;
      }
      @media @breakpoint-s, @breakpoint-xs {
        width: 44px;
        height: 16px;
      }
    }

    &.big {
      @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
        width: 215px;
        height: 78px;
      }
      @media @breakpoint-m {
        width: 142px;
        height: 52px;
      }
      @media @breakpoint-s, @breakpoint-xs {
        width: 59px;
        height: 22px;
      }
    }

    .item-text {
      font-weight: 400;
    }
  }

  &.circle {
    border-radius: 50%;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;

    @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
      width: 122px;
      height: 122px;
    }
    @media @breakpoint-m {
      width: 80px;
      height: 80px;
    }
    @media @breakpoint-s, @breakpoint-xs {
      width: 32px;
      height: 32px;
    }

    .item-text {
      font-weight: 600;
    }
  }

  &.diamond {
    transform: rotate(45deg);
    border-radius: 4px;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;

    @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
      width: 92px;
      height: 92px;
    }
    @media @breakpoint-m {
      width: 60px;
      height: 60px;
    }
    @media @breakpoint-s, @breakpoint-xs {
      width: 24px;
      height: 24px;
    }

    .item-text {
      font-weight: 600;
      transform: rotate(-45deg);
    }
  }

  &.green {
    background: #37be85;
    border-color: #3bde9a;

    .item-text {
      color: #fff;
    }
  }

  &.red {
    background: #e21f4e;
    border-color: #f64e77;

    .item-text {
      color: #fff;
    }
  }

  &.yellow {
    background: #ffe713;
    border-color: #edc147;

    .item-text {
      color: #000;
    }
  }

  &.placeholder {
    border-style: dotted;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: #fff;
    transition: background-color 0.2s ease-out;
  }
}

.diagram-element:hover .item.placeholder {
  background-color: rgba(255, 255, 255, 0.2);
}

.raise-item {
  transform: translateY(-4px);
}

.lower-item {
  transform: translateY(4px);
}

.item-text {
  vertical-align: middle;
  text-align: center;

  @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
    font-size: 20px;

    .yellow & {
      font-size: 18px;
    }
  }
  @media @breakpoint-m {
    font-size: 14px;

    .yellow & {
      font-size: 12px;
    }
  }
  @media @breakpoint-s, @breakpoint-xs {
    font-size: 6px;

    .yellow & {
      font-size: 5px;
    }
  }
}
</style>

<script>
import draggable from "vuedraggable";

export default {
  props: {
    item: Object,
    value: Object,
    source: Boolean,
    tutorialStep: { type: Number, default: -1 },
  },
  components: {
    draggable,
  },
  data: function () {
    return {
      items: this.value ? [this.value] : [],
      raise: undefined,
    };
  },
  methods: {
    getItemPosition(item, isSource) {
      const baseWidth = isSource ? 572 : 796;
      const baseHeight = 644;
      const padLeft =
        (isSource ? 108 : 0) + (item.shape === "diamond" ? -18.4 : 0);
      const padTop =
        (isSource ? 28 : 0) + (item.shape === "diamond" ? -18.4 : 0);
      const pos = isSource ? item.source : item.target;
      return {
        top: `${((pos.y - padTop) / baseHeight) * 100}%`,
        left: `${((pos.x - padLeft) / baseWidth) * 100}%`,
      };
    },
  },
  computed: {
    translateVal() {
      return this.raise ? -4 : 0;
    },
  },
  watch: {
    items: {
      deep: true,
      handler(val) {
        if (val.length > 0) {
          this.$emit("added", this.item.id, val[0].id);
          this.$emit("input", val[0]);
        } else {
          this.$emit("input", null);
        }
      },
    },
    value(val) {
      this.items = val ? [val] : [];
    },
  },
};
</script>