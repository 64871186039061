import { render, staticRenderFns } from "./panel-heading.vue?vue&type=template&id=75b9f408&scoped=true&"
import script from "./panel-heading.vue?vue&type=script&lang=js&"
export * from "./panel-heading.vue?vue&type=script&lang=js&"
import style0 from "./panel-heading.vue?vue&type=style&index=0&id=75b9f408&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75b9f408",
  null
  
)

export default component.exports