<template>
  <div class="tutor">
    <h3 class="subtitle">{{ subtitle }}</h3>
    <h1 class="title">{{ title }}</h1>
    <div class="stages">
      <div class="spacer-edge"></div>
      <template v-for="(stage, i) in stages">
        <div class="spacer-inner" v-if="i > 0" :key="`sil-${i}`"></div>
        <div class="arrow" v-if="i > 0" :key="`arrow-${i}`"></div>
        <div class="spacer-inner" v-if="i > 0" :key="`sir-${i}`"></div>
        <div
          class="stage"
          :class="{ first: i === 0, last: i === stages.length - 1 }"
          :key="i"
        >
          <p class="stage-title">{{ stage.title }}</p>
          <div class="media">
            <img :src="require(`@/assets/${stage.media}`)" />
          </div>
          <p class="stage-text">{{ stage.text }}</p>
        </div>
      </template>
      <div class="spacer-edge"></div>
    </div>
    <button class="button responsive" @click="$emit('next')">
      {{ btn }}
    </button>
    <orientation-plug />
    <navigation-controller />
  </div>
</template>

<style scoped lang="less">
@breakpoint-l: (min-width: 1440px);
@breakpoint-m: (max-width: 1439px) and (min-width: 1024px);
@breakpoint-s: (max-width: 1023px);

.tutor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  overflow: hidden;

  font-family: Gilroy;

  background-position: top;

  @media @breakpoint-l {
    background-size: 2560px 100%;
    background-image: url("~@/assets/tutor/bg-large.svg");
  }

  @media @breakpoint-m {
    background-size: 1439px 100%;
    background-image: url("~@/assets/tutor/bg-medium.svg");
  }

  @media @breakpoint-s {
    background-size: 1024px 100%;
    background-image: url("~@/assets/tutor/bg-small.svg");
  }
}

.subtitle {
  line-height: 120%;
  color: #fff;
  text-align: center;

  @media @breakpoint-l {
    font-weight: 900;
    font-size: 36px;
    margin-top: 66px;
  }

  @media @breakpoint-m {
    font-weight: 800;
    font-size: 28px;
    margin-top: 40px;
  }

  @media @breakpoint-s {
    font-weight: 800;
    font-size: 22px;
    margin-top: 12px;
  }
}

.title {
  white-space: pre-wrap;

  font-weight: 900;
  line-height: 100%;
  color: #fff;
  text-align: center;

  @media @breakpoint-l {
    font-size: 80px;
    margin-top: 20px;
  }

  @media @breakpoint-m {
    font-size: 59px;
    margin-top: 24px;
  }

  @media @breakpoint-s {
    font-size: 34px;
    margin-top: 8px;
  }
}

.stages {
  width: 100%;

  display: flex;
  align-items: center;

  margin-bottom: 20px;
}

.spacer-edge {
  flex-grow: 2;
}

.spacer-inner {
  flex-grow: 1;
}

.stage {
  background-image: url("~@/assets/tutor/stage-bg.png");
  background-size: 100% 100%;
  flex-shrink: 0;

  position: relative;

  filter: drop-shadow(0px 6.85837px 73.4826px rgba(255, 231, 19, 0.24));

  @media @breakpoint-l {
    width: 363px;
    height: 380px;
    padding: 5px 24px 24px 41px;
  }

  @media @breakpoint-m {
    width: 252px;
    height: 264px;
    padding: 3px 17px 13px 29px;
  }

  @media @breakpoint-s {
    width: 155px;
    height: 162px;
    padding: 2px 10px 8px 18px;
  }

  .stage-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 170%;

    @media @breakpoint-l {
      margin-right: 16px;
      height: 49px;
      font-size: 14px;
    }

    @media @breakpoint-m {
      margin-right: 11px;
      height: 35px;
      font-size: 10px;
    }

    @media @breakpoint-s {
      margin-right: 7px;
      height: 21px;
      font-size: 6px;
    }
  }

  .media {
    @media @breakpoint-l {
      margin-top: 16px;
      height: 154px;
    }

    @media @breakpoint-m {
      margin-top: 11px;
      height: 108px;
    }

    @media @breakpoint-s {
      margin-top: 7px;
      height: 66px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .stage-text {
    color: #fff;
    text-align: center;

    @media @breakpoint-l {
      margin-top: 16px;
      font-size: 16px;
      line-height: 150%;
    }

    @media @breakpoint-m {
      margin-top: 11px;
      font-size: 13px;
      line-height: 130%;
    }

    @media @breakpoint-s {
      margin-top: 7px;
      font-size: 9px;
      line-height: 130%;
    }
  }
}

.stage.first,
.stage.last {
  &:before,
  &:after {
    content: "";
    pointer-events: none;
    position: absolute;
    background-size: 100% 100%;
    z-index: -1;
  }

  &:before {
    background-image: url("~@/assets/tutor/stage-glitter.png");

    @media @breakpoint-l {
      width: 383px;
      height: 516px;
    }

    @media @breakpoint-m {
      width: 244px;
      height: 330px;
    }

    @media @breakpoint-s {
      width: 133px;
      height: 180px;
    }
  }

  &:after {
    @media @breakpoint-l {
      width: 483px;
      height: 574px;
    }

    @media @breakpoint-m {
      width: 226px;
      height: 269px;
    }

    @media @breakpoint-s {
      width: 154px;
      height: 183px;
    }
  }
}

.stage.first:before {
  @media @breakpoint-l {
    top: 182px;
    right: 250px;
  }

  @media @breakpoint-m {
    top: 121px;
    right: 165px;
  }

  @media @breakpoint-s {
    top: 50px;
    right: 109px;
  }
}

.stage.last:before {
  @media @breakpoint-l {
    left: 194px;
    bottom: 50px;
  }

  @media @breakpoint-m {
    left: 37px;
    bottom: 6px;
  }

  @media @breakpoint-s {
    left: 51px;
    bottom: -100px;
  }
}

.stage.first:after {
  background-image: url("~@/assets/tutor/stage-glow-sw.png");
  @media @breakpoint-l {
    bottom: -49px;
    left: -74px;
  }

  @media @breakpoint-m {
    bottom: -28px;
    left: -41px;
  }

  @media @breakpoint-s {
    bottom: -25px;
    left: -28px;
  }
}

.stage.last:after {
  background-image: url("~@/assets/tutor/stage-glow-ne.png");
  @media @breakpoint-l {
    top: -45px;
    right: -64px;
  }

  @media @breakpoint-m {
    top: -26px;
    right: -36px;
  }

  @media @breakpoint-s {
    top: -20px;
    right: -19px;
  }
}

.arrow {
  background-image: url("~@/assets/tutor/arrow-double.png");
  background-size: 100% 100%;
  flex-shrink: 0;

  @media @breakpoint-l {
    width: 139px;
    height: 123px;
  }

  @media @breakpoint-m {
    width: 97px;
    height: 86px;
  }

  @media @breakpoint-s {
    background-image: url("~@/assets/tutor/arrow-single.png");
    width: 40px;
    height: 54px;
  }
}

.button {
  margin-top: auto;
  position: relative;

  @media @breakpoint-l {
    margin-bottom: 60px;
  }

  @media @breakpoint-m {
    margin-bottom: 56px;
  }

  @media @breakpoint-s {
    margin-bottom: 24px;
  }

  &:before {
    content: "";
    position: absolute;

    background-image: url("~@/assets/tutor/button-glow.png");
    background-size: 100% 100%;

    @media @breakpoint-l {
      right: -45px;
      bottom: -29px;
      width: 708px;
      height: 58px;
    }

    @media @breakpoint-m {
      right: -39px;
      bottom: -22px;
      width: 557px;
      height: 45px;
    }

    @media @breakpoint-s {
      right: -23px;
      bottom: -12px;
      width: 457px;
      height: 37px;
    }
  }
}
</style>

<script>
import OrientationPlug from "@/components/orientation-plug.vue";
import NavigationController from "@/components/partials/navigation-controller";

export default {
  props: {
    subtitle: String,
    title: String,
    btn: String,
    stages: Array,
  },
  components: {
    OrientationPlug,
    NavigationController,
  },
};
</script>
