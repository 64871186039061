<template>
	<div class="assistant" :class="{ horizontal }">
		<div class="assistant-name">{{ name }}</div>
		<div class="assistant-message" :class="{ error }">{{ message }}</div>
		<div class="assistant-avatar">
			<img :src="avatar" />
		</div>
	</div>
</template>

<style scoped lang="less">
	.assistant {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		.assistant-name {
			@media (min-width: 1440px){
				height: 42px;
				width: 124px;
				font-size: 16px;
			}

			@media (max-width: 1439px) and (min-width: 1024px){
				height: 32px;
				width: 98px;
				font-size: 14px;
			}

			@media (max-width: 1023px){
				height: 18px;
				width: 53px;
				font-size: 6.5px;
			}
			
			text-transform: uppercase;
			border-radius: 5px;
			background-color: #B67CF8;
			color: #fff;
			font-weight: 700;
			margin-right: auto;
			margin-bottom: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.assistant-message {
			background: linear-gradient(31.92deg, rgba(255, 255, 255, 0.0126) 25.95%, rgba(255, 255, 255, 0) 25.95%, rgba(255, 255, 255, 0) 50.15%), rgba(210, 178, 246, 0.35);
			border: 2px solid #fff;
			border-radius: 4px;
			color: #fff;
			font-style: normal;
			font-weight: normal;
			line-height: 170%;
			width: 100%;
			transition: background .5s;

			@media (min-width: 1440px){
				font-size: 16px;
				padding: 16px 32px 32px;
			}

			@media (max-width: 1439px) and (min-width: 1024px){
				font-size: 14px;
				padding: 12px 16px 20px;
			}

			@media (max-width: 1023px){
				font-size: 10px;
				padding: 8px;
			}

			&.error {
				background: linear-gradient(31.92deg, rgba(255, 255, 255, 0.0126) 25.95%, rgba(255, 255, 255, 0) 25.95%, rgba(255, 255, 255, 0) 50.15%), rgba(226, 31, 78, 0.35);
			}
		}

		.assistant-avatar {
			position: relative;
			border-radius: 50%;
			backdrop-filter: blur(34px);

			@media (min-width: 1640px){
				margin-top: -32px;
				width: 88px;
				height: 88px;
			}
			@media (max-width: 1639px) and (min-width: 1440px){
				margin-top: -24px;
				width: 88px;
				height: 88px;
			}
			@media (max-width: 1439px) and (min-width: 1024px){
				margin-top: -16px;
				width: 72px;
				height: 72px;
			}
			@media (max-width: 1023px){
				display: none;
			}

			img {
				width: 100%;
			}
		}
	}

	.assistant.horizontal {
		@media (min-width: 1440px){
			padding-left: 104px;
		}
		@media (max-width: 1439px) and (min-width: 1024px){
			padding-left: 88px;
		}

		.assistant-avatar {
			position: absolute;
			top: 0;
			left: 8px;
			margin-top: 0;
		}
		
		.assistant-message {
			@media (min-width: 1440px){
				padding: 16px 32px 16px;
			}

			@media (max-width: 1439px) and (min-width: 1024px){
				padding: 12px 16px 12px;
			}
		}
	}
</style>

<script>
	export default {
		props: {
			name: {
				type: String,
				default: 'Запятыня'
			},
			avatar: {
				type: String,
				default: require('@/assets/assistant/assistant-avatar.png')
			},
			message: String,
			horizontal: Boolean
		},
		data: () => ({
			error: false
		}),
		methods: {
			flashRed(){
				if(this.timeout){
					clearTimeout(this.timeout);
				}
				this.error = true;
				this.timeout = setTimeout(() => this.error = false, 2000);
			},
			cancelFlash(){
				if(this.timeout){
					clearTimeout(this.timeout);
				}
				this.error = false;
			}
		}
	}
</script>