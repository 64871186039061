<template>
  <div class="container" :class="{ 'is-chosen': isChosen }">
    <orientation-plug />
    <navigation-controller />
    <div class="content">
      <div class="source-wrapper">
        <div class="items-container" v-show="!isGameFinished">
          <diagram-element
            v-for="slot in sourceItemSlots"
            :key="`source-${slot.item.id}`"
            :item="slot.item"
            v-model="slot.insertedItem"
            source
            @choose="isChosen = true"
            @unchoose="isChosen = false"
          />
        </div>
        <div class="assistant-update-container" :class="{ detached: isTutorialState, centered: isGameFinished }">
          <assistant
            :message="assistantMessage"
          />
          <button
            class="button responsive update-ai"
            @click="onButtonClick"
            v-show="isTutorialState || isGameFinished"
          >
            {{ buttonText }}
          </button>
          <!-- <div
						class="update-ai"
						@click="updateAI"
						v-show="isGameFinished"
					></div> -->
        </div>
      </div>

      <div class="destination-wrapper">
        <div class="destination-container">
          <div class="block-diagram-container">
            <div v-if="config.instantCheck" class="header">Блок-схема</div>

            <div class="block-diagram">
              <diagram-element
                v-for="slot in targetItemSlots"
                :key="`target-${slot.item.id}`"
                :item="slot.item"
                :tutorial-step="tutorialStepIndex"
                v-model="slot.insertedItem"
                @choose="isChosen = true"
                @unchoose="isChosen = false"
                @added="handleDrop"
              />
            </div>

            <div class="block-diagram-connections">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 796 644"
                xmlns="http://www.w3.org/2000/svg"
              >
                <template v-for="(line, i) in config.lines">
                  <path
                    class="line"
                    :key="`line-${i}`"
                    :d="pointsToPathSpec(line.points, !!line.middle)"
                  />
                  <image
                    :key="`start-image-${i}`"
                    v-bind="imagePositionFromPoints(line.points)"
                    :href="getImageHref(line.start)"
                    width="12"
                    height="12"
                  />
                  <image
                    :key="`end-image-${i}`"
                    v-bind="imagePositionFromPoints(line.points, true)"
                    :href="getImageHref(line.end)"
                    width="12"
                    height="12"
                  />
                  <circle
                    class="label"
                    :key="`middle-circle-${i}`"
                    v-if="line.middle"
                    v-bind="circlePositionFromPoints(line.points)"
                    r="29"
                  />
                  <text
                    class="label-text"
                    :key="`middle-text-${i}`"
                    v-if="line.middle"
                    :x="circlePositionFromPoints(line.points).cx"
                    :y="circlePositionFromPoints(line.points).cy"
                    text-anchor="middle"
                    dy="0.27em"
                  >
                    {{ line.middle }}
                  </text>
                </template>
              </svg>
            </div>
          </div>
          <div class="result-container">
            <div class="header">Сколько ошибок нашёл ИИ</div>

            <div class="result-inner-container">
              <div
                class="result-item"
                v-for="result in config.results"
                :key="result.label"
              >
                <img :src="require(`@/assets/minigame-4/${result.icon}`)" />

                <div class="result-text">{{ result.label }}</div>

                <!-- <div
                  class="result-count"
                  :class="{ invisible: !isGameFinished }"
                >
                  {{ result.count }}
                </div> -->
                <div class="result-window">
                  <div
                    class="result-column"
                    :style="`transform: translateY(${
                      !isGameFinished ? 16.66 : -16.66 * result.count
                    }%)`"
                    :class="{ 'result-column-shifted': isGameFinished }"
                  >
                    <div
                      v-for="item in resultsArr"
                      :key="item"
                      class="result-count"
                    >
                      {{ item }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay" v-show="isTutorialState"></div>
  </div>
</template>

<style scoped lang="less">
/* autoprefixer grid: autoplace */
@breakpoint-xxl: ~"(min-width: 1920px) and (min-height: 850px)";
@breakpoint-xl: ~"(min-width: 1640px) and (max-width: 1919px) and (min-height: 850px)";
@breakpoint-l: ~"(min-width: 1440px) and (max-width: 1639px) and (min-height: 850px)";
@breakpoint-m: ~"(min-width: 1024px) and (max-width: 1439px), (min-width: 1024px) and (max-height: 849px)";
@breakpoint-s: ~"(min-width: 720px) and (max-width: 1023px)";
@breakpoint-xs: ~"(max-width: 719px)";
@breakpoint-mob: ~"(max-height: 370px) and (min-height: 270px)";

@background-green: ~"#37BE85";
@border-green: ~"#3BDE9A";

@background-yellow: ~"#FFE713";
@border-yellow: ~"#EDC147";

@background-red: ~"#E21F4E";
@border-red: ~"#F64E77";

.container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-size: cover;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  @media @breakpoint-xxl, @breakpoint-xl {
    background-image: url("~@/assets/minigame-4/bg-xl.png");
  }
  @media @breakpoint-l {
    background-image: url("~@/assets/minigame-4/bg-l.png");
  }
  @media @breakpoint-m {
    background-image: url("~@/assets/minigame-4/bg-m.png");
  }
  @media @breakpoint-s {
    background-image: url("~@/assets/minigame-4/bg-s.png");
  }
  @media @breakpoint-xs {
    background-image: url("~@/assets/minigame-4/bg-xs.png");
  }
  @media @breakpoint-mob {
    background-image: url("~@/assets/minigame-4/bg-xs.png");
    height: 100vh;
  }

  &.is-chosen {
    cursor: url("~@/assets/cursors/cursor-hand-closed.png"), grabbing;
  }

  .content {
    position: relative;
    display: flex;
    justify-content: space-around;
    /* this forces content children to fit inside its box */
    margin: 0 -2.5%;
    padding: 0 2.5%;

    .item-container {
      position: relative;
      width: 100%;
      height: 100%;

      .item-draggable {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .item-wrapper {
          width: 100%;
          height: 100%;
        }

        .item-wrapper:not(.sortable-chosen) {
          cursor: url("~@/assets/cursors/cursor-hand-opened.png"), grab;
        }
      }
    }

    .source-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-right: 5%;

      @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
        width: 572px;
      }
      @media @breakpoint-m {
        width: 454px;
      }
      @media @breakpoint-s, @breakpoint-xs {
        width: 232px;
      }

      &:before {
        pointer-events: none;
        position: absolute;
        content: "";
        top: -30px;
        right: 0;
        bottom: 0;
        left: -200px;
        width: 943px;
        height: 626px;
        background-repeat: no-repeat;
        background-image: url("~@/assets/minigame-4/glow-blue-large.png");

        @media @breakpoint-m {
          top: -60px;
          right: 0;
          bottom: 0;
          left: -150px;
          width: 766px;
          height: 508px;
          background-repeat: no-repeat;
          background-image: url("~@/assets/minigame-4/glow-blue-medium.png");
        }

        @media @breakpoint-s, @breakpoint-xs {
          top: -10px;
          left: -130px;
          width: 374px;
          height: 288px;
          background-repeat: no-repeat;
          background-image: url("~@/assets/minigame-4/glow-blue-small.png");
        }
      }
      .items-container {
        position: relative;

        @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
          width: 572px;
          height: 644px;
        }
        @media @breakpoint-m {
          width: 386px;
          height: 423px;
        }
        @media @breakpoint-s, @breakpoint-xs {
          width: 232px;
          height: 184px;
        }

        .source-item {
          position: absolute;
        }
      }

      .assistant-update-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        flex-shrink: 0;
        margin-top: auto;

        &.detached {
          position: absolute;
          bottom: 0;
          left: 0;
        }

        &.centered {
          margin: auto 0;
        }
      }
    }

    .destination-wrapper {
      margin-left: auto;

      .destination-container {
        /*display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        height: 100%;*/

        .block-diagram-container {
          position: relative;
          background: url("~@/assets/minigame-4/block-diagram-container.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          flex-shrink: 0;

          @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
            width: 796px;
            height: 644px;
            padding-top: 56px;
            padding-left: 60px;
            padding-bottom: 50px;
            padding-right: 32px;
          }
          @media @breakpoint-m {
            width: 523px;
            height: 423px;
            padding-top: 34px;
            padding-left: 37px;
            padding-bottom: 32px;
            padding-right: 22px;
          }
          @media @breakpoint-s, @breakpoint-xs {
            width: 228px;
            height: 184px;
            padding-top: 12px;
            padding-left: 16px;
            padding-bottom: 16px;
            padding-right: 12px;
          }

          &:before {
            pointer-events: none;
            position: absolute;
            content: "";
            top: -125px;
            right: 0;
            bottom: 0;
            left: -120px;
            width: 1097px;
            height: 167px;
            background-repeat: no-repeat;
            background-image: url("~@/assets/minigame-4/glow-green-large.png");

            @media @breakpoint-m {
              top: -85px;
              left: -85px;
              width: 727px;
              height: 112px;
              background-repeat: no-repeat;
              background-image: url("~@/assets/minigame-4/glow-green-medium.png");
            }

            @media @breakpoint-s, @breakpoint-xs {
              top: -37px;
              left: -32px;
              width: 303px;
              height: 48px;
              background-repeat: no-repeat;
              background-image: url("~@/assets/minigame-4/glow-green-small.png");
            }
          }

          &:after {
            pointer-events: none;
            position: absolute;
            content: "";
            top: -75px;
            right: 0;
            bottom: 0;
            left: -200px;
            width: 369px;
            height: 490px;
            background-repeat: no-repeat;
            background-image: url("~@/assets/minigame-4/sparks-green-large.png");

            @media @breakpoint-m {
              top: 0px;
              right: 0;
              bottom: 0;
              left: -120px;
              width: 245px;
              height: 325px;
              background-repeat: no-repeat;
              background-image: url("~@/assets/minigame-4/sparks-green-medium.png");
            }

            @media @breakpoint-s, @breakpoint-xs {
              top: -5px;
              left: -50px;
              width: 103px;
              height: 137px;
              background-repeat: no-repeat;
              background-image: url("~@/assets/minigame-4/sparks-green-small.png");
            }
          }

          .header {
            color: white;
            font-weight: 900;

            @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
              font-size: 36px;
            }
            @media @breakpoint-m {
              font-size: 28px;
            }
            @media @breakpoint-s, @breakpoint-xs {
              font-size: 12px;
            }
          }

          .block-diagram {
            flex-grow: 1;

            @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
              margin-top: 20px;
            }
            @media @breakpoint-m {
              margin-top: 12px;
            }
            @media @breakpoint-s, @breakpoint-xs {
              margin-top: 4px;
            }
          }
        }

        .result-container {
          position: relative;
          background: url("~@/assets/minigame-4/result-container.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          margin-top: 5%;

          @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
            width: 804px;
            height: 242px;
            padding-top: 36px;
            padding-left: 88px;
            padding-bottom: 16px;
            padding-right: 60px;
          }
          @media @breakpoint-m {
            width: 523px;
            height: 157px;
            padding-top: 24px;
            padding-left: 48px;
            padding-bottom: 16px;
            padding-right: 60px;
          }
          @media @breakpoint-s, @breakpoint-xs {
            width: 228px;
            height: 69px;
            padding-top: 10px;
            padding-left: 28px;
            padding-bottom: 16px;
            padding-right: 36px;
          }

          .header {
            color: white;
            font-weight: 900;

            @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
              font-size: 32px;
            }
            @media @breakpoint-m {
              font-size: 25px;
            }
            @media @breakpoint-s, @breakpoint-xs {
              font-size: 11px;
            }
          }

          &:before {
            pointer-events: none;
            position: absolute;
            content: "";
            top: 270px;
            right: 0;
            bottom: 0;
            left: -150px;
            width: 1094px;
            height: 73px;
            background-repeat: no-repeat;
            background-image: url("~@/assets/minigame-4/glow-yellow-large.png");

            @media @breakpoint-m {
              top: 175px;
              left: -80px;
              width: 725px;
              height: 50px;
              background-repeat: no-repeat;
              background-image: url("~@/assets/minigame-4/glow-yellow-medium.png");
            }

            @media @breakpoint-s, @breakpoint-xs {
              top: 76px;
              left: -40px;
              width: 303px;
              height: 21px;
              background-repeat: no-repeat;
              background-image: url("~@/assets/minigame-4/glow-yellow-small.png");
            }
          }

          &:after {
            pointer-events: none;
            position: absolute;
            content: "";
            top: -130px;
            right: 0;
            bottom: 0;
            left: 550px;
            width: 369px;
            height: 490px;
            background-repeat: no-repeat;
            background-image: url("~@/assets/minigame-4/sparks-yellow-large.png");

            @media @breakpoint-m {
              top: -80px;
              right: 0;
              bottom: 0;
              left: 395px;
              width: 245px;
              height: 326px;
              background-repeat: no-repeat;
              background-image: url("~@/assets/minigame-4/sparks-yellow-medium.png");
            }

            @media @breakpoint-s, @breakpoint-xs {
              top: -30px;
              left: 170px;
              width: 103px;
              height: 136px;
              background-repeat: no-repeat;
              background-image: url("~@/assets/minigame-4/sparks-yellow-small.png");
            }
          }

          .result-inner-container {
            display: flex;
            justify-content: space-between;

            .result-item {
              display: flex;
              flex-direction: column;
              align-items: center;

              img {
                @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
                  width: 80px;
                  height: 80px;
                }
                @media @breakpoint-m {
                  width: 40px;
                  height: 40px;
                }
                @media @breakpoint-s, @breakpoint-xs {
                  width: 24px;
                  height: 24px;
                }
              }

              .result-text {
                font-weight: 700;
                color: white;
                text-transform: uppercase;

                @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
                  font-size: 16px;
                }
                @media @breakpoint-m {
                  font-size: 12px;
                }
                @media @breakpoint-s, @breakpoint-xs {
                  font-size: 4px;
                }
              }

              .result-window {
                display: flex;
                justify-content: center;
                position: relative;
                overflow: hidden;
                width: 100%;
                @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
                  height: 57.5px;
                }
                @media @breakpoint-m {
                  height: 41.5px;
                }
                @media @breakpoint-s, @breakpoint-xs {
                  height: 16.1px;
                }
              }

              .result-column {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
              }

              .result-column-shifted {
                transition: transform 2s ease-out;
              }

              .result-count {
                font-weight: 900;
                color: white;

                @media @breakpoint-xxl, @breakpoint-xl, @breakpoint-l {
                  font-size: 50px;
                }
                @media @breakpoint-m {
                  font-size: 36px;
                }
                @media @breakpoint-s, @breakpoint-xs {
                  font-size: 14px;
                }

                &.invisible {
                  visibility: hidden;
                }
              }
            }
          }
        }
      }
    }

    .assistant {
      z-index: 20;
      width: 100%;
    }

    .update-ai {
      z-index: 20;
      margin-top: 2%;
    }
  }
}

.sortable-ghost {
  visibility: hidden;
}

.sortable-chosen {
  transform: translateY(-4px);
  cursor: url("~@/assets/cursors/cursor-hand-closed.png"), grabbing;
}

.block-diagram-connections {
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;

    .line {
      stroke: #fff;
      stroke-dasharray: 3;
      fill: none;
      //filter: url("#line-blur");
    }

    .label {
      fill: rgba(186, 255, 226, 0.14);
      border: 1px solid rgba(255, 255, 255, 0.04);
      box-shadow: 0px 0px 9px 4px rgba(21, 60, 90, 0.06);
      backdrop-filter: blur(4px);
    }

    .label-text {
      fill: #fff;
      font-size: 20px;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.8;
  z-index: 10;
}
</style>

<script>
import assistant from "../components/partials/assistant.vue";
import orientationPlug from "@/components/orientation-plug.vue";
import DiagramElement from "./minigame-4/diagram-element.vue";
import NavigationController from "@/components/partials/navigation-controller";

export default {
  components: {
    assistant,
    orientationPlug,
    NavigationController,
    DiagramElement,
  },
  props: {
    config: Object,
  },
  data: function () {
    const items = this.config.items.map((item, i) => ({
      ...item,
      id: `${item.shape}-${item.color}-${i}`,
    }));
    const sourceItemSlots = items
      .filter((item) => !!item.source)
      .map(function (item) {
        return {
          item,
          insertedItem: item.source ? item : null,
        };
      });
    const targetItemSlots = items.map(function (item) {
      return {
        item,
        insertedItem: item.source ? null : item,
      };
    });
    return {
      resultsArr: [0, 1, 2, 3, 4, 5],
      itemsLast: 6,
      isChosen: false,
      assistantMessage: this.config.tutorial[0].message,
      sourceItemSlots,
      targetItemSlots,
      tutorialStepIndex: 0,
    };
  },
  computed: {
    tutorialLength() {
      return (
        this.config.items
          .filter((item) => item.tutorialStep > -1)
          .sort((a, b) => b.tutorialStep - a.tutorialStep)[0].tutorialStep + 1
      );
    },
    isTutorialState() {
      return this.tutorialStepIndex < this.tutorialLength;
    },
    tutorialStep() {
      return this.config.tutorial[this.tutorialStepIndex];
    },
    buttonText() {
      return this.isTutorialState ? this.tutorialStep.button : "Обновить ИИ";
    },
    remainingSlots() {
      return this.targetItemSlots.filter((slot) => !slot.insertedItem).length;
    },
    isGameFinished() {
      return this.remainingSlots === 0;
    },
    labelledLines() {
      return this.config.lines.filter((line) => !!line.middle);
    },
    transformStyle: {
      get: function () {
        return this.isGameFinished
          ? `transform: translateY(-${16.8}%)`
          : undefined;
      },
      set: function (count) {
        return this.isGameFinished
          ? `transform: translateY(-${16.8 * count}%)`
          : undefined;
      },
    },
  },
  methods: {
    handleDrop(slotId, itemId) {
      if (this.config.instantCheck) {
        if (slotId !== itemId) {
          const targetSlot = this.targetItemSlots.find(
            (slot) => slot.item.id === slotId
          );
          const sourceSlot = this.sourceItemSlots.find(
            (slot) => slot.item.id === itemId
          );
          this.assistantMessage = targetSlot.item.messageOnError;
          sourceSlot.insertedItem = targetSlot.insertedItem;
          this.$nextTick(() => (targetSlot.insertedItem = null));
        } else {
          this.assistantMessage = this.config.messageOnSuccess;
        }
      }
      if (
        this.remainingSlots < this.targetItemSlots.length / 2 &&
        this.config.messageOnHalfway
      ) {
        this.assistantMessage = this.config.messageOnHalfway;
      }
      if (this.isGameFinished) {
        this.assistantMessage = this.config.messageOnFinish;
      }
    },
    getSegmentPosition(pos1, pos2) {
      const baseWidth = 796;
      const baseHeight = 644;
      const t = pos1.y < pos2.y ? pos1.y : pos2.y;
      const b = pos1.y < pos2.y ? pos2.y : pos1.y;
      const l = pos1.x < pos2.x ? pos1.x : pos2.x;
      const r = pos1.x < pos2.x ? pos2.x : pos1.x;
      return {
        top: `${(t / baseHeight) * 100}%`,
        left: `${(l / baseWidth) * 100}%`,
        bottom: `${((baseHeight - b) / baseHeight) * 100}%`,
        right: `${((baseWidth - r) / baseWidth) * 100}%`,
      };
    },
    pointsToPathSpec(points, interrupted) {
      return points.reduce(function (acc, point, i) {
        if (i === 0) {
          return (acc += `M ${point.x} ${point.y}`);
        }

        const prevPoint = points[i - 1];
        if (!interrupted) {
          acc += `L ${point.x} ${point.y}`;
        } else {
          const cx = (point.x + prevPoint.x) / 2;
          const cy = (point.y + prevPoint.y) / 2;
          const r = 29;

          if (point.x > prevPoint.x) {
            acc += `L ${cx - r} ${cy} m ${2 * r} 0 L ${point.x} ${point.y}`;
          }
          if (point.x < prevPoint.x) {
            acc += `L ${cx + r} ${cy} m ${-2 * r} 0 L ${point.x} ${point.y}`;
          }
          if (point.y > prevPoint.y) {
            acc += `L ${cx} ${cy - r} m 0 ${2 * r} L ${point.x} ${point.y}`;
          }
          if (point.y < prevPoint.y) {
            acc += `L ${cx} ${cy + r} m 0 ${-2 * r} L ${point.x} ${point.y}`;
          }
        }

        if (i === points.length - 1) {
          /* I assume that all lines are either horizontal or vertical */
          if (point.x > prevPoint.x) {
            acc += "m -5 -5 l 5 5 m -5 5 l 5 -5";
          }
          if (point.x < prevPoint.x) {
            acc += "m 5 -5 l -5 5 m 5 5 l -5 -5";
          }
          if (point.y > prevPoint.y) {
            acc += "m -5 -5 l 5 5 m 5 -5 l -5 5";
          }
          if (point.y < prevPoint.y) {
            acc += "m -5 5 l 5 -5 m 5 5 l -5 -5";
          }
        }
        return acc;
      }, "");
    },
    imagePositionFromPoints(points, atEnd) {
      const anchor = atEnd ? points[points.length - 1] : points[0];
      const dirRef = atEnd ? points[points.length - 2] : points[1];
      const size = 12;
      if (anchor.x < dirRef.x) {
        return { x: anchor.x - size, y: anchor.y - size / 2 };
      }
      if (anchor.x > dirRef.x) {
        return { x: anchor.x, y: anchor.y - size / 2 };
      }
      if (anchor.y > dirRef.y) {
        return { x: anchor.x - size / 2, y: anchor.y };
      }
      if (anchor.y < dirRef.y) {
        return { x: anchor.x - size / 2, y: anchor.y - size };
      }
    },
    getImageHref(type) {
      switch (type) {
        case "green":
          return require("@/assets/minigame-4/dot-green.png");
        case "red":
          return require("@/assets/minigame-4/dot-red.png");
        case "yellow":
          return require("@/assets/minigame-4/dot-yellow.png");
        default:
          throw new Error("Point type not recognised");
      }
    },
    circlePositionFromPoints(points) {
      return {
        cx: (points[points.length - 1].x + points[0].x) / 2,
        cy: (points[points.length - 1].y + points[0].y) / 2,
      };
    },
    updateAI() {
      if (!this.isGameFinished) {
        return;
      }

      const errorCount = this.targetItemSlots.filter(
        (slot) => slot.item.id !== slot.insertedItem.id
      ).length;
      let stars = 3;
      if (errorCount > 3) {
        stars = 2;
      }
      if (errorCount > 6) {
        stars = 1;
      }

      this.$store.dispatch("gameFinished", { stars });
      this.$emit("done");
    },
    onButtonClick() {
      if (this.isTutorialState) {
        this.tutorialStepIndex += 1;
        this.assistantMessage =
          (this.tutorialStep && this.tutorialStep.message) ||
          this.config.messageOnStart;
      } else {
        this.updateAI();
      }
    },
  },
};
</script>
