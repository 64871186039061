<template>
	<div
		class="panel-image"
		:style="imageStyle"
	>
		<img :src="require('@/assets/cutscene/content/' + src)" />
	</div>
</template>

<style scoped lang="less">
	@breakpoint-l: (min-width: 1400px);
	@breakpoint-m: (max-width: 1399px) and (min-width: 1000px);
	@breakpoint-s: (max-width: 999px) and (min-width: 721px);
	@breakpoint-xs: (max-width: 720px);

	@keyframes fade-and-expand {
		from { opacity: 0; transform: scale(0); }
		to { opacity: 1; transform: scale(1); }
	}

	.panel-image {
		position: relative;

		min-width: 0;

		animation-name: fade-and-expand;
		animation-iteration-count: 1;

		transition: transform .1s linear, opacity .1s linear;

		& + & {
			margin-left: 3.2%;
		}

		img {
			display: block;
			width: 100%;
		}
	}
</style>

<script>
	export default {
		props: {
			src: String,
			offset: { type: Object, default: () => ({}) },
			isMobile: Boolean,
			scrollPosition: Number,
			duration: { type: Number, default: 0 },
			distance: { type: Number, default: 0 },
			delay: { type: Number, default: 0 }
		},
		data: () => ({
			fraction: 0
		}),
		computed: {
			baseWidth(){
				return this.isMobile ? 680 : 1280;
			},
			imageStyle(){ 
				return {
					marginLeft: this.offset.x && `${-this.offset.x / this.baseWidth * 100}%`,
					marginTop: this.offset.y && `${-this.offset.y / this.baseWidth * 100}%`,
					marginRight: this.offset.r && `${-this.offset.r / this.baseWidth * 100}%`,
					marginBottom: this.offset.b && `${-this.offset.b / this.baseWidth * 100}%`,
					zIndex: this.offset.z,
					animationDuration: `${this.duration}s`,
					opacity: this.duration ? void 0 : this.fraction,
					transform: this.duration ? void 0: `scale(${this.fraction})`
				};
			}
		},
		watch: {
			scrollPosition(){
				const parentBB = this.$el.parentElement.getBoundingClientRect();

				const scale = parentBB.width / this.baseWidth;

				let fraction = (window.innerHeight - this.delay * scale - parentBB.top) / (this.distance * scale);

				if(fraction < 0){
					this.fraction = 0;
				}
				if(fraction >=0 && fraction <= 1){
					this.fraction = fraction;
				}
				if(fraction > 1){
					this.fraction = 1;
				}
			}
		}
	}
</script>