<template>
	<story-view />
</template>

<style lang="less">
	@import "normalize";
	@import "globals";
	@import (less) "../assets/fonts/Gilroy/stylesheet.css";
	@import "buttons";

	html,
	body {
		height: 100%;
		margin: 0;

		font-family: Gilroy;

	}

	body > div {
		cursor: url("~@/assets/cursors/cursor-normal.png") 14 18, auto;
		min-height: 100%;
	}
</style>

<script>
	import StoryView from "@/views/story";

	export default {
		components: {
			StoryView
		},

		async beforeCreate() {
			if (process.env.NODE_ENV === 'production') {
				let files = await fetch('./img/images_list.txt')
				.then(response => response.text())
				.then(text => text.split('\n'))
				await Promise.all(files.map(file => {
					if (file == '') return Promise.resolve()
					return new Promise(res => {
						let img = new Image()
						img.src = `./img/${file}`
						img.onload = () => res()
						img.onerror = () => res()
					})
				}))
			}
		},

		created(){
			
			const qs = window.location.search;
			const match = qs.match(/session_id=(.+?)(?:&|$)/);
			if(match){
				this.$store.dispatch('setSessionId', { sessionId: match[1] });
			}
		}
	}
</script>