<template>
	<div class="select-lookup">
		<button type="button" class="toggle-select" @click="toggleList">{{ selected ? selected.name : 'Выбрать' }}</button>
		<div v-show="listOpen" class="list">
			<input class="filter" v-model="filter" type="text" />
			<ul>
				<li :class="{ selected: selected === null }" @click="selectItem(null)">Выбрать</li>
				<li :class="{ selected: selected === item }" v-for="item in filteredList" :key="item.id" @click="selectItem(item)">{{ item.name }}</li>
			</ul>
		</div>
	</div>
</template>

<style scoped lang="less">
	.select-lookup {
		position: relative;
	}

	.toggle-select {
		width: 100%;
		padding: .625rem 1.375rem;

		font-size: 1rem;
		line-height: 170%;
		text-align: left;
		color: #A7A8C1;

		background-color: transparent;
		border: 1px solid rgba(167, 168, 193, 0.3);
		border-radius: 3rem;

		background-image: url("~@/assets/questionnaire/select-arrow.svg");
		background-size: auto 0.5625rem;
		background-position: center right 1.5rem;
		background-repeat: no-repeat;

		cursor: pointer;
	}

	.list {
		position: absolute;
		left: 0;
		right: 0;
		top: 3rem;
		z-index: 10;

		background-color: #fff;
		border: 1px solid #aaa;

		input {
			width: calc(100% - 2px);
			border: 1px solid #aaa;
			margin: 1px;
		}

		ul {
			list-style: none;
			padding-left: 0;
			margin: 0;

			max-height: 12.25rem;
			overflow-y: auto;

			li {
				padding: .375rem;
				line-height: 170%;

				&.selected {
					background-color: #ddd;
				}

				&:hover {
					background-color: #5897fb;
				}
			}
		}
	}
</style>

<script>
	export default {
		props: {
			list: Array,
			value: [String, Number]
		},
		data: () => ({
			listOpen: false,
			selected: null,
			filter: ''
		}),
		computed: {
			filteredList(){
				if(this.filter){
					const match = new RegExp(this.filter, 'i')
					return this.list.filter(item => item.name.match(match));
				} else {
					return this.list;
				}
			}
		},
		methods: {
			selectItem(item){
				this.selected = item;
				this.closeList();
				this.$emit('input', item && item.id || null);
			},
			toggleList(){
				if(this.listOpen){
					this.closeList();
				} else {
					this.openList();
				}
			},
			closeList(){
				this.listOpen = false;
			},
			openList(){
				this.listOpen = true;
				this.filter = '';
			},
			_clickOutside(ev){
				if(!this.$el.contains(ev.target)){
					this.closeList();
				}
			}
		},
		created(){
			this._boundClickOutside = this._clickOutside.bind(this);
			window.addEventListener('click', this._boundClickOutside);
		},
		beforeDestroy(){
			window.removeEventListener('click', this._boundClickOutside);
		}
	}
</script>