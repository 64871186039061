<template>
	<component
		:is="currentGame"
		:config="config"
		@done="$emit('next')"
		@restart="$emit('reset')"
		@next="$emit('next')"
		@prev="$emit('prev')"
	/>
</template>

<script>
	import Placeholder from "@/games/placeholder";
	import Minigame1 from "@/games/minigame-1";
	import Minigame2 from "@/games/minigame-2";
	import Minigame3 from "@/games/minigame-3";
	import Minigame4 from "@/games/minigame-4";
	import Minigame5 from "@/games/minigame-5";
	import Minigame6 from "@/games/minigame-6";

	export default {
		props: {
			game: String,
			config: Object
		},
		computed: {
			currentGame(){
				switch(this.game){
					case 'placeholder': return Placeholder;
					case 'minigame-1': return Minigame1;
					case 'minigame-2': return Minigame2;
					case 'minigame-3': return Minigame3;
					case 'minigame-4': return Minigame4;
					case 'minigame-5': return Minigame5;
					case 'minigame-6': return Minigame6;
					default: throw new Error('Game not found');
				}
			}
		}
	}
</script>
