var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"game",class:{ 'is-dragged': _vm.isDragged },on:{"mousemove":function($event){return _vm.onDomMouseMove($event)},"mouseup":function($event){return _vm.onDomMouseUp($event)}}},[_c('div',{staticClass:"column left",on:{"mouseenter":function($event){return $event.target.classList.add('hover')},"mouseleave":function($event){return $event.target.classList.remove('hover')}}},[_c('div',{staticClass:"backdrop"}),_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.config.labels.left))]),_c('transition-group',{staticClass:"dropzone",attrs:{"tag":"div","name":"draggable"}},_vm._l((_vm.leftItems),function(item){return _c('div',{key:item.id,staticClass:"item",class:{
          dragged: _vm.dragId === item.id,
        },style:(_vm.getDraggedStyle(item)),attrs:{"id":("item-" + (item.id))},on:{"mousedown":function($event){$event.preventDefault();return _vm.onDomDragStart($event, item.id)},"touchstart":function($event){$event.preventDefault();return _vm.onDomTouchStart($event, item.id)},"touchmove":function($event){$event.preventDefault();return _vm.onDomTouchMove($event)},"touchend":function($event){$event.preventDefault();return _vm.onDomTouchEnd($event)}}},[_c('div',{staticClass:"inner"},[_c('img',{attrs:{"src":require('@/' + item.image.replace('%1', 'left'))}})])])}),0)],1),_c('div',{staticClass:"center"},[_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-label"},[_vm._v(" "+_vm._s(_vm.leftItems.length + _vm.rightItems.length)+" / "+_vm._s(_vm.itemCount)+" прогресс... ")]),_c('div',{staticClass:"progress-bars"},[_vm._l((_vm.leftItems.length + _vm.rightItems.length),function(n){return _c('div',{key:("full-" + n),staticClass:"progress-item"},[_c('img',{attrs:{"src":require("@/assets/minigame-1/progress-full.png")}})])}),_vm._l((_vm.centerItems.length),function(n){return _c('div',{key:("empty-" + n),staticClass:"progress-item"},[_c('img',{attrs:{"src":require("@/assets/minigame-1/progress-empty.png")}})])})],2)]),_c('transition-group',{staticClass:"item-source",attrs:{"tag":"div","name":"draggable"}},_vm._l((_vm.centerItems),function(item){return _c('div',{key:item.id,staticClass:"item",class:{
          dragged: _vm.dragId === item.id,
          raised: _vm.mouseDown && _vm.draggedItem.position === 'center',
        },style:(_vm.getDraggedStyle(item)),attrs:{"id":("item-" + (item.id))},on:{"mousedown":function($event){$event.preventDefault();return _vm.onDomDragStart($event, item.id)},"touchstart":function($event){$event.preventDefault();return _vm.onDomTouchStart($event, item.id)},"touchmove":function($event){$event.preventDefault();return _vm.onDomTouchMove($event)},"touchend":function($event){$event.preventDefault();return _vm.onDomTouchEnd($event)}}},[_c('div',{staticClass:"inner"},[_c('img',{attrs:{"src":require('@/' + item.image.replace('%1', 'center'))}})])])}),0),_c('assistant',{attrs:{"message":_vm.assistantMessage}}),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.gameFinished),expression:"gameFinished"}],staticClass:"button",on:{"click":_vm.finishGame}},[_vm._v(" Загрузить данные ")])],1),_c('div',{staticClass:"column right",on:{"mouseenter":function($event){return $event.target.classList.add('hover')},"mouseleave":function($event){return $event.target.classList.remove('hover')}}},[_c('div',{staticClass:"backdrop"}),_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.config.labels.right))]),_c('transition-group',{staticClass:"dropzone",attrs:{"tag":"div","name":"draggable"}},_vm._l((_vm.rightItems),function(item){return _c('div',{key:item.id,staticClass:"item",class:{
          dragged: _vm.dragId === item.id,
        },style:(_vm.getDraggedStyle(item)),attrs:{"id":("item-" + (item.id))},on:{"mousedown":function($event){$event.preventDefault();return _vm.onDomDragStart($event, item.id)},"touchstart":function($event){$event.preventDefault();return _vm.onDomTouchStart($event, item.id)},"touchmove":function($event){$event.preventDefault();return _vm.onDomTouchMove($event)},"touchend":function($event){$event.preventDefault();return _vm.onDomTouchEnd($event)}}},[_c('div',{staticClass:"inner"},[_c('img',{attrs:{"src":require('@/' + item.image.replace('%1', 'right'))}})])])}),0)],1),_c('orientation-plug'),_c('navigation-controller')],1)}
var staticRenderFns = []

export { render, staticRenderFns }